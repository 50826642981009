import React, { createContext } from "react";

const SocketContext = createContext();
const ServerAddress = "https://stream.capture.live:8443"
//const ServerAddress = "http://localhost:80"
const config = {
  iceServers: [
      { 
        "urls": "stun:stun.l.google.com:19302",
      },
  ]
};

const ContextProvider = ({ children }) => {
  return  (
    <SocketContext.Provider value={
      {
        config,
        ServerAddress,
      }
    }>
      {children}
    </SocketContext.Provider>
  );
}

export { ContextProvider, SocketContext }
