import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Row, Card, message } from 'antd'
import socketClient from 'socket.io-client'
import { SocketContext } from './SocketContext'
import { getDeviceId, getDeviceIdByPosition } from "../../util"

const WebRTCPlayer: React.FC<{ item: any }> = ({ item }) => {

  const { config, ServerAddress } = useContext(SocketContext)
  const myVideo = useRef<HTMLVideoElement>(null)
  const myConnection = useRef<RTCPeerConnection>()
  const mySocket = useRef<any>()

  useEffect(() => {
    var socket = socketClient(ServerAddress)
    const handler = (id: any, description: RTCSessionDescriptionInit) => {
      myConnection.current = new RTCPeerConnection(config);
      if(myConnection.current) {
        myConnection.current
        .setRemoteDescription(description)
        .then(() => myConnection.current?.createAnswer())
        //@ts-ignore
        .then(sdp => myConnection.current?.setLocalDescription(sdp))
        .then(() => {
          socket.emit("answer", id, myConnection.current?.localDescription);
        });
        myConnection.current.ontrack = event => {
          console.log("on track event: ", event)
          if(myVideo.current) {
            console.log("My video current")
            myVideo.current.srcObject = event.streams[0]
          }
        };
        myConnection.current.onicecandidate = event => {
          if (event.candidate) {
            socket.emit("candidate", id, event.candidate);
          }
        };
        // myConnection.current.oniceconnectionstatechange = () => {
        //   if(myConnection.current?.iceConnectionState === 'disconnected') {
        //       console.log("connection close")
        //       myConnection.current.close()
        //       socket.close()
        //       // setIsConnected(false)
        //   }
        // };
      }
    }
    socket.on("offer", handler)
    socket.on("candidate", (id, candidate) => {
      console.log("getting candidate: ", candidate)
      myConnection.current?.addIceCandidate(new RTCIceCandidate(candidate)).catch(e => console.error(e))
    });
    
    socket.on("connect", () => {
      console.log("I'm connecting with the webrtc server")
      socket.emit("watcher", getDeviceIdByPosition(item))
    });
    
    socket.on("broadcaster", () => {
      console.log("I'm getting a broadcast signal")
      socket.emit("watcher", getDeviceIdByPosition(item))
    });

    socket.on("disconnect", () => {
      console.log("Disconnect from broadcaster")
      // setIsConnected(false)
    })
    
    return () => {
      socket.off("offer")
      socket.off("candidate")
      socket.off("connect")
      socket.off("broadcaster")
      socket.off("disconnect")
      if(myConnection.current) {
        myConnection.current.removeEventListener("track", event => {
          console.log("on track event: ", event)
          if(myVideo.current) {
            console.log("My video current")
            myVideo.current.srcObject = event.streams[0]
          }
        })
        myConnection.current.removeEventListener("icecandidate", event => {
          if (event.candidate) {
            //socket.emit("candidate", id, event.candidate);
          }
        })
        myConnection.current.close()
      }
      if(mySocket.current) {
        mySocket.current.close()
      }
    }
  },[item])

  const createNewConnection = () => {
    var socket = socketClient(ServerAddress)
    mySocket.current = socket
    socket.on("offer", (id, description) => {
      myConnection.current = new RTCPeerConnection(config)
      if(myConnection.current) {
        myConnection.current
        .setRemoteDescription(description)
        .then(() => myConnection.current?.createAnswer())
        //@ts-ignore
        .then(sdp => myConnection.current?.setLocalDescription(sdp))
        .then(() => {
          socket.emit("answer", id, myConnection.current?.localDescription)
        })
        myConnection.current.ontrack = event => {
          console.log("on track event: ", event)
          if(myVideo.current) {
            myVideo.current.srcObject = event.streams[0]
          }
        }
        myConnection.current.onicecandidate = event => {
          if (event.candidate) {
            socket.emit("candidate", id, event.candidate)
          }
        }
        // myConnection.current.oniceconnectionstatechange = () => {
        //   if(myConnection.current?.iceConnectionState === 'disconnected') {
        //       console.log("connection close")
        //       myConnection.current.close()
        //       socket.close()
        //   }
        // }
      }
    })
    socket.on("candidate", (id, candidate) => {
      console.log("getting candidate: ", candidate)
      myConnection.current?.addIceCandidate(new RTCIceCandidate(candidate)).catch(e => console.error(e));
    })
    socket.on("connect", () => {
      console.log("I'm connecting with the webrtc server")
      socket.emit("watcher", getDeviceIdByPosition(item));
    })
    socket.on("broadcaster", () => {
      console.log("I'm getting a broadcast signal")
      socket.emit("watcher", getDeviceIdByPosition(item));
    })
    socket.on("disconnect", () => {
      console.log("Disconnect from broadcaster")
      // setIsConnected(false)
    })
  }

  const onRefresh = () => {
    if(myConnection.current) {
      myConnection.current.removeEventListener("track", event => {
        console.log("on track event: ", event)
        if(myVideo.current) {
          console.log("My video current")
          myVideo.current.srcObject = event.streams[0]
        }
      })
      myConnection.current.removeEventListener("icecandidate", event => {
        if (event.candidate) {
          //socket.emit("candidate", id, event.candidate);
        }
      })
      myConnection.current.close()
    }
    if(mySocket.current) {
      let socket = mySocket.current
      socket.off("offer")
      socket.off("candidate")
      socket.off("connect")
      socket.off("broadcaster")
      socket.off("disconnect")
      mySocket.current.close()
    }
    createNewConnection()
  }

  const VideoView = () => {
    return (
      <div style={{ display: "flex" }}>
        <Row style={{ width: "100%" }}>
          <video playsInline controls muted ref={myVideo} autoPlay style={{ width: "100%", maxHeight: 400, alignSelf: "center", backgroundColor: "black" }}/>
        </Row>
      </div>
    )
  }

  const DefaultVideoView = () => {
    return (
      <div
        style={{
          height: "100%",
          minHeight: 150,
          justifyContent: "center",
          textAlign: "center",
          verticalAlign: "center",
          display: "flex"
        }}
      >
        <span style={{ marginTop: "auto", marginBottom: "auto" }}>
          Video stream is not available
        </span>
      </div>
    )
  }

  return (
    <div>
      {item ? (
          <VideoView />
      ) : (
        <DefaultVideoView />
      )}
    </div>
  )
}

export default WebRTCPlayer
