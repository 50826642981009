import { Action } from "../../models/General"
import NotificationItem from "../../types/NotificationItem"
import fallActions from "../Falls/actions"
import actions from "./actions"

const initState: {
  notifications: NotificationItem[]
  nextToken: string | null
  total: number
} = {
  notifications: [],
  nextToken: null,
  total: 0
}

const mergeProcessedState = (
  lastNotif: NotificationItem[],
  newNotif: NotificationItem[]
) =>
  newNotif.map((item: NotificationItem) => {
    let processedState =
      lastNotif.find((_) => _?.id === item?.id)?.processed || false
    if (item?.handoverNurseId && item?.handoverNurseId !== "null") {
      processedState = true
    } else if (item?.isFalseAlarm) {
      processedState = true
    } else if (item?.status === "Processing") {
      processedState = true
    }
    return {
      ...item,
      processed: processedState || false
    }
  })

const notificationReducer = (
  state = initState,
  { type, ...action }: Action
) => {
  switch (type) {
    case fallActions.FALL_DETECTED:
      return {
        ...state,
        notifications: [...state.notifications, action.payload.fall]
      }
    case actions.NOTIFICATION_PROCESSED:
      return {
        ...state,
        notifications: state.notifications.map((_: any) =>
          _.id === action.payload.notificationId ? { ..._, processed: true } : _
        )
      }
    case actions.NOTIFICATION_UPDATED:
      return {
        ...state,
        notifications: state.notifications.map((_: any) =>
          _.id === action.payload.notificationId
            ? { ..._, ...action.payload.updated }
            : _
        )
      }
    case fallActions.FETCH_FALL_HISTORY:
      return {
        ...state,
        notifications: action.payload.isExtend
          ? [...state.notifications, ...action.payload.falls.items]
          : mergeProcessedState(
              state.notifications,
              action.payload.falls.items
            ),
        nextToken: action.payload.isExtend
          ? action.payload.falls.nextToken
          : state.nextToken
      }
    default:
      return state
  }
}

export default notificationReducer
