import { User } from "../models/User";

/**
 * Helper functions to determine the type of user
 * @param user current user get by the `getCurrentAuthenticatedUser` function
 * @returns 
 */
export function isAdmin(user: Partial<User>) {
  return user.type === "Admin";
}

export function isDoctor(user: Partial<User>) {
  return user.type === "Doctor";
}

export function isNurse(user: Partial<User>) {
  return user.type === "Nurse";
}

export function isFamily(user: Partial<User>) {
  return user.type === "Family";
}
