import React, { useEffect, useState } from "react"
import { List, Button, PageHeader, Popconfirm, Descriptions, Table, Typography } from "antd"
import { PlusCircleOutlined, ReloadOutlined } from "@ant-design/icons"
import useDevices from "../hooks/useDevices"
import DeviceForm from "../components/DeviceDetailForm"
import CanvasModal from "../components/CanvasModal"

// import testImage from "../assets/screenshot.png"
// const testImageObject = {
//   imageUrl: testImage
// }

const DeviceManagement: React.FC = () => {
  const {
    devices,
    singleDevice,
    getDevices,
    fetchSingleDevice,
    resetDevice,
    deleteSingleDevice,
    regions,
    images,
    fetchRestrictedRegions,
    fetchImageRequests,
    updateRegion,
    deleteRegion,
    setRegion,
    setImage,
    updateImage
  } = useDevices()
  const [loading, setLoading] = useState<boolean>(false)
  const [mode, setMode] = useState<"create" | "edit">("create")
  const [modalVisibility, setModalVisibility] = useState<boolean>(false)
  const [isCanvasModalVisible, setIsCanvasModalVisible] = useState<boolean>(false)
  const [canvasMode, setCanvasMode] = useState<"create" | "edit">("create")

  useEffect(() => {
    console.log("refresh device page")
    onLoadMore(false)
    // eslint-disable-next-line
  }, [])

  const onLoadMore = async (isExtend = false) => {
    setLoading(true)
    getDevices(isExtend, () => {
      fetchRestrictedRegions(() => {
        fetchImageRequests(() => {
          setLoading(false)
        })
      })
    })
  }

  const loadMore = !loading ? (
    <div
      style={{
        textAlign: "center",
        marginTop: 12,
        height: 32,
        lineHeight: "32px"
      }}
    >
      <Button onClick={() => onLoadMore(true)} disabled={!devices.nextToken}>
        Load More
      </Button>
    </div>
  ) : null

  const columns = [
    {
      title: 'Device ID',
      dataIndex: 'deviceId',
      render: (id: string) => (
        <Typography>
          {id}
        </Typography>
      )
    },
    {
      title: 'Doctor Name',
      dataIndex: 'doctor',
      render: (doctor: any) => (
        <Typography style={{ marginRight: 10 }}>
          {doctor?.name}
        </Typography>
      )
    },
    {
      title: 'Nurse Name',
      dataIndex: 'nurse',
      render: (nurse: any) => (
        <Typography style={{ marginRight: 10 }}>
          {nurse?.name}
        </Typography>
      )
    },
    {
      title: 'Patient Name',
      dataIndex: 'patient',
      render: (patient: any) => (
        <Typography>
          {patient?.name}
        </Typography>
      )
    },
    {
      title: 'Position',
      dataIndex: 'position',
      render: (position: string) => (
        <Typography>
          {position}
        </Typography>
      )
    },
    {
      title: 'Operations',
      dataIndex: 'id',
      render: (id: string, device: any) => (
        <>
          <Button
            key="edit"
            type="primary"
            onClick={() => {
              fetchSingleDevice(id)
              setMode("edit")
              setModalVisibility(true)
            }}
            style={{ marginRight: 10 }}
          >
            Edit
          </Button>
          <Button
            key="region"
            type="default"
            onClick={() => {
              fetchSingleDevice(id, () => {
                updateImage({ id: device.deviceId, requestedAt: (new Date()).toISOString() }, () => {
                  const currentImage = images.items.filter((i: any) => { return (i.id === device.deviceId) })[0]
                  setImage(currentImage)
                  setCanvasMode("create")
                  setIsCanvasModalVisible(true)
                })
              })
            }}
            style={{ marginRight: 10 }}
          >
            Add Restricted Region
          </Button>
          <Popconfirm
            key="delete"
            onConfirm={() => {
              deleteSingleDevice(id)
            }}
            title="Are you sure to delete this device?"
          >
            <Button danger>Delete</Button>
          </Popconfirm>
        </>
      )
    },
  ]

  const expandedRowRender = (device: any) => {
    return (
      <List
      loading={loading}
      itemLayout="horizontal"
      dataSource={regions.items.filter((i: any) => { return (i.deviceId === device.deviceId) })}
      renderItem={(item: any, index: any) => {
        return (
          <List.Item
            actions={[
              <Button
                key="edit"
                type="primary"
                onClick={() => {
                  updateImage({ id: device.deviceId, requestedAt: (new Date()).toISOString() }, () => {
                    setImage(images.items.filter((i: any) => { return (i.id === device.deviceId) })[0])
                    setRegion(regions.items.filter((i: any) => { return (i.id === item.id) })[0])
                    setCanvasMode("edit")
                    setIsCanvasModalVisible(true)
                  })
                }}
              >
                Edit Region
              </Button>,
              <Popconfirm
                key="delete"
                onConfirm={() => {
                  deleteRegion(item.id)
                  onLoadMore()
                }}
                title="Are you sure to delete this region?"
              >
                <Button danger>Delete</Button>
              </Popconfirm>
            ]}
          >
            <Typography>
              {item.name}
            </Typography>
            <Typography>
              {item.type === "0" ? "disabled" : "enabled"}
            </Typography>
          </List.Item>
        )
      }}
    />
    )
  }

  return (
    <div style={{ backgroundColor: "white", borderRadius: "10px" }}>
      <PageHeader
        ghost={false}
        style={{ borderRadius: "10px" }}
        title="Devices"
        extra={[
          <Button
            key="add"
            onClick={() => {
              setMode("create")
              setModalVisibility(true)
              resetDevice()
            }}
            shape="round"
          >
            <PlusCircleOutlined />
            Add New Device
          </Button>,
          <Button
            style={{ float: "right" }}
            key="refresh"
            type="primary"
            onClick={() => {
              onLoadMore(false)
            }}
            shape="round"
          >
            <ReloadOutlined />
            Refresh
          </Button>
        ]}
      />
      <DeviceForm
        visibility={modalVisibility}
        mode={mode}
        onCancel={() => {
          setModalVisibility(false)
          resetDevice()
          setMode("create")
        }}
      />
      <CanvasModal 
        mode={canvasMode}
        visibility={isCanvasModalVisible}
        onCancel={() => {
          setIsCanvasModalVisible(false)
          onLoadMore()
        }}
      />
      <Table 
        columns={columns} 
        dataSource={devices.items}
        rowKey={item => item.id}
        expandable={{ expandedRowRender }}
      />
    </div>
  )
}

export default DeviceManagement
