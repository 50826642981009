import actions from "./actions"
import { Action } from "../../models/General"

const initState = {
  familyMembers: {
    items: [],
    nextToken: null
  },
  currentMember: {
    type: "",
    data: {}
  }
}

export default function FamiliyMemberReducer(
  state = initState,
  { type, ...action }: Action
) {
  switch (type) {
    case actions.LIST_FAMILY_MEMBERS: {
      return {
        ...state,
        familyMembers: action.payload.props
        // familyMembers: action.payload.isExtend
        //   ? {
        //       ...state.familyMembers,
        //       items: [...state.familyMembers.items, ...action.payload.props.items],
        //       nextToken: action.payload.props.nextToken
        //     }
        //   : action.payload.props
      }
    }
    case actions.GET_FAMILY_MEMBER_DETAIL:
      return {
        ...state,
        currentMember: action.payload
      }

    case actions.SET_CURRENT_FAMILY_MEMBER:
      return {
        ...state,
        currentMember: {
          ...state.currentMember,
          data: action.payload
        }
      }
    
    case actions.CREATE_FAMILY_MEMBER:
      return {
        ...state,
        famiyMembers: {
          ...state.familyMembers,
          items: [...state.familyMembers.items, action.payload]
        }
      }
    
    case actions.UPDATE_CURRENT_FAMILY_MEMBER:
      const newDetail = { ...state.currentMember.data, ...action.payload }
      return {
        ...state,
        currentMember: { ...state.currentMember, data: newDetail },
        familyMembers: {
          ...state.familyMembers,
          items: state.familyMembers.items.map((user: any) =>
            user.id === newDetail.id ? newDetail : user
          )
        }
      }

    default:
      return state
  }
}
