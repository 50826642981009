import React, { useEffect, useState } from "react"
import { Route, Switch, Redirect, useRouteMatch,  } from "react-router-dom"
import useCurrentUser from "../hooks/useCurrentUser"
import useRecords from "../hooks/useRecords"
import Dashboard from "./Dashboard"
import { onCreateRecord } from "../graphql/subscriptions"
import { toast } from "react-toastify"
import { Auth, graphqlOperation, API } from "aws-amplify"
import AlertDrawer from "../components/AlertDrawer"
import { AmplifyAuthenticator, AmplifySignOut, AmplifySignUp } from "@aws-amplify/ui-react"
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components"
// @ts-ignore
import Sound from "../assets/beep.wav"

let audio = new Audio(Sound)

const IndexPage: React.FC = () => {
  let match = useRouteMatch()
  const { setCurrentUserData } = useCurrentUser()
  const { receiveAlert, processAlert } = useRecords()
  const [drawerVisibility, setDrawerVisibility] = useState<boolean>(false)
  const [drawerId, setDrawerId] = useState<string>("")
  const [authState, setAuthState] = useState<AuthState>()
  const [user, setUser] = useState<any>()

  useEffect(() => {
    if(user) {
      setCurrentUserData()
    }
    // eslint-disable-next-line
  }, [user])

  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState)
      setUser(authData)
    })
  }, [])

  useEffect(() => {
    let subscription: any = null
    if(user) {
      Auth.currentSession()
      .then((session) => {
        console.log("subscripting...")
        // const username = session.getIdToken().decodePayload().sub
        subscription = API.graphql(
          graphqlOperation(onCreateRecord)
          // @ts-ignore
        ).subscribe({
          next: ({ provider, value }: any) => {
            audio.play()
            // Todo: only accept current user's notification if user type is not Admin
            // if current user is Admin, accept all notification
            console.log('received record:', value)
            console.log('received record errors:')
            value?.error?.forEach((item: any )=> {
              console.error(item)
            })
            receiveAlert(value.data.onCreateRecord)
            toast.warning("An incident is detected!", {
              toastId: value.data.onCreateRecord.id,
              onClick: () => {
                setDrawerId(value.data.onCreateRecord.id)
                setDrawerVisibility(true)
              }
            })
            // Try to open a drawer if there is no current drawer opening (?)
            // if (!drawerVisibility) {
            //   setDrawerId(value.data.onCreateRecord.id);
            //   setDrawerVisibility(true)
            // }
          },
          error: (error: Error) => {
            console.warn(error)
          }
        })
      })
      .catch((error) => {
        console.log("Stop subscription, error: ", error)
      })
    }
    return () => {
      subscription?.unsubscribe()
    }
  // eslint-disable-next-line
  }, [user])

  return authState === AuthState.SignedIn && user ? (
    <>
      <AlertDrawer
        drawerId={drawerId}
        visibility={drawerVisibility}
        // visibility={true}
        onClose={(id: string) => {
          setDrawerVisibility(false)
          processAlert(id)
        }}
      />
      <Switch>
        <Route path={`${match.url}dashboard`}>
          <Dashboard />
        </Route>
        {/* <Route path={`${match.url}login`}>
          <>987654</>
        </Route> */}
        <Route
          exact
          path={`${match.url}`}
          render={() => <Redirect to={`${match.url}dashboard`} />}
        />
      </Switch>
    </>
  ) : (
    <AmplifyAuthenticator usernameAlias="email">
      <AmplifySignUp
        slot="sign-up"
        usernameAlias="email"
        formFields={[
          //@ts-ignore
          { type: "email", fieldId: "email", label: "Email *", placeholder: "Enter your email", required: true },
          //@ts-ignore
          { type: "name", fieldId: "name", label: "Name *", placeholder: "Enter your name", required: true },
          //@ts-ignore
          { type: "password", fieldId: "password" },
          //@ts-ignore
          { type: "custom:userType", fieldId: "custom:userType", label: "User Type *", placeholder: "Doctor | Nurse | Family", required: true }
        ]}
      />
    </AmplifyAuthenticator>
  )
}

export default IndexPage
