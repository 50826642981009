import React from "react"
import { Menu, Layout, Avatar, Dropdown, Button } from "antd"
import { ReloadOutlined } from "@ant-design/icons"
import { AmplifySignOut } from "@aws-amplify/ui-react"
import useCurrentUser from "../hooks/useCurrentUser"

const { Header } = Layout

const DashboardHeader: React.FC = () => {
  const { currentUser } = useCurrentUser()
  const menu = (
    <Menu>
      <Menu.Item key="logout">
        <AmplifySignOut />
      </Menu.Item>
    </Menu>
  )
  const onRefresh = () => {
    // console.log("Refresh")
    window.location.reload()
  }
  return (
    // @ts-ignore
    <Header style={styles.header}>
      <Button style={{ marginRight: 10 }} type="ghost" shape="circle" icon={<ReloadOutlined />} size="large" onClick={onRefresh}/>
      <Dropdown overlay={menu} trigger={['click']} >
        <Avatar style={styles.avatar} size="large">
          {(currentUser.name && currentUser.name[0].toUpperCase()) || "A"}
        </Avatar>
      </Dropdown>
    </Header>
  )
}

const styles = {
  header: {
    background: '#fff',
    textAlign: "right"
  },
  icon: {
    fontSize: 14,
    lineHeight: 0,
    verticalAlign: 'middle',
  },
  avatar: {
    backgroundColor: '#2599cc',
    verticalAlign: 'middle',
    // textTransform: 'capitalize',
    fontSize: 22,
  },
};

export default DashboardHeader
