import React, { RefObject, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { ReloadOutlined, SwapOutlined } from "@ant-design/icons"
import moment from "moment"
import { Button, Card, message, Modal } from 'antd'
import socketClient, { Socket } from 'socket.io-client'
import { SocketContext } from './SocketContext'
import useNotification from "../../hooks/useNotification"
import { processNotification } from "../../redux/Notification/actions"
import HandOverModal from "../HandOverModal"
import RiskLevelBadge from "../RiskLevelBadge"
import { palette } from "../../config"
import { getDeviceId } from "../../util"
import { API, graphqlOperation } from "aws-amplify"
import { getDeviceSettings, listDeviceSettingss } from '../../graphql/queries'
import { updateDeviceSettings } from '../../graphql/mutations'

const WebRTCMonitorCard: React.FC<{ item: any, withActionBar: boolean }> = ({ item, withActionBar }) => {
  const { notifications } = useSelector((state: any) => state.Notification)
  const dispatch = useDispatch()

  const { setOnMyWay, setIsFalseAlarm, handOver } = useNotification()
  const [visible, setVisible] = useState<boolean>(false)
  const [recordId, setRecordId] = useState<string>("")
  const [currentNotifications, setCurrentNotifications] = useState<any>([])
  const [isNotificationResolved, setIsNotificationResolved] = useState<boolean>(false)

  //const [isConnected, setIsConnected] = useState<boolean>(false)
  const { config, ServerAddress } = useContext(SocketContext)
  const myVideo = useRef<HTMLVideoElement>(null)
  const myConnection = useRef<RTCPeerConnection>()
  const mySocket = useRef<Socket>()

  // Set Video Modal
  const [videoModal, setVideoModal] = useState<any>()
  const modalVideo = useRef<HTMLVideoElement>(null)
  const modalConnection = useRef<RTCPeerConnection>()
  const modalSocket = useRef<Socket>()

  //Device Setting
  const [cameraType, setCameraType] = useState<string>("")

  useEffect(() => {
    const fetchDeviceSettings = async () => {
      const id = item?.deviceId
      // console.log("Item:", item)
      const response: any = await API.graphql(graphqlOperation(getDeviceSettings, { id }))
      //console.log("fetch response: ", response)
      setCameraType(response.data.getDeviceSettings?.cameraType)
    }
    fetchDeviceSettings()
  },[item])

  useEffect(() => {
    const sortedNotifications = notifications
      .filter((_: any) => _.position === item?.position)
      .sort((a: any, b: any) => {
        if (a.createdAt < b.createdAt) return 1
        if (a.createdAt > b.createdAt) return -1
        return 0
      })
    // Hotfix: set the first notification's processed property to true
    if (
      sortedNotifications[0]?.status === "Processing" ||
      sortedNotifications[0]?.handoverNurse
    ) {
      sortedNotifications[0].processed = true
      sortedNotifications[0].status = "Processing"
    }
    console.log(sortedNotifications)
    setCurrentNotifications(sortedNotifications)
    setIsNotificationResolved(false)
    // Hotfix: set the notification resolve state when status of notifiaction is "Proessing"
    if (
      sortedNotifications[0]?.status === "Processing" ||
      sortedNotifications[0]?.handoverNurse
    ) {
      setIsNotificationResolved(true)
    }
    if (sortedNotifications[0]?.processed) {
      setTimeout(() => {
        // If the notifications gets updated, avoid setting it to true
        if (sortedNotifications[0]?.processed) {
          setIsNotificationResolved(true)
        }
      }, 5000)
    }
  }, [notifications])


  useEffect(() => {
    var socket = socketClient(ServerAddress)
    const handler = (id: any, description: RTCSessionDescriptionInit) => {
      myConnection.current = new RTCPeerConnection(config);
      if(myConnection.current) {
        myConnection.current
        .setRemoteDescription(description)
        .then(() => myConnection.current?.createAnswer())
        //@ts-ignore
        .then(sdp => myConnection.current?.setLocalDescription(sdp))
        .then(() => {
          socket.emit("answer", id, myConnection.current?.localDescription);
        })
        .catch((error) => {
          console.log("Error in connnection: ", error)
        });
        myConnection.current.ontrack = event => {
          console.log("on track event: ", event)
          if(myVideo.current) {
            console.log("My video current")
            myVideo.current.srcObject = event.streams[0]
          }
        };
        myConnection.current.onicecandidate = event => {
          if (event.candidate) {
            socket.emit("candidate", id, event.candidate);
          }
        };
        // myConnection.current.oniceconnectionstatechange = () => {
        //   if(myConnection.current?.iceConnectionState === 'disconnected') {
        //       console.log("connection close")
        //       myConnection.current.close()
        //       socket.close()
        //       // setIsConnected(false)
        //   }
        // };
      }
    }
    socket.on("offer", handler)
    socket.on("candidate", (id, candidate) => {
      console.log("getting candidate: ", candidate)
      myConnection.current?.addIceCandidate(new RTCIceCandidate(candidate)).catch(e => console.error(e))
    });
    
    socket.on("connect", () => {
      console.log("I'm connecting with the webrtc server")
      socket.emit("watcher", getDeviceId(item))
    });
    
    socket.on("broadcaster", () => {
      console.log("I'm getting a broadcast signal")
      socket.emit("watcher", getDeviceId(item))
    });

    socket.on("disconnect", () => {
      console.log("Disconnect from broadcaster")
      // setIsConnected(false)
    })
    
    return () => {
      //console.log("Useeffect call return")
      socket.off("offer")
      socket.off("candidate")
      socket.off("connect")
      socket.off("broadcaster")
      socket.off("disconnect")
      if(myConnection.current) {
        myConnection.current.removeEventListener("track", event => {
          console.log("on track event: ", event)
          if(myVideo.current) {
            console.log("My video current")
            myVideo.current.srcObject = event.streams[0]
          }
        })
        myConnection.current.removeEventListener("icecandidate", event => {
          if (event.candidate) {
            //socket.emit("candidate", id, event.candidate);
          }
        })
        myConnection.current.close()
      }
      if(mySocket.current) {
        mySocket.current.close()
      }
    }
  },[item])

  const createNewConnection = (mySocket: any, myConnection: any, myVideo: any) => {
    var socket = socketClient(ServerAddress)
    mySocket.current = socket
    socket.on("offer", (id, description) => {
      myConnection.current = new RTCPeerConnection(config)
      if(myConnection.current) {
        myConnection.current
        .setRemoteDescription(description)
        .then(() => myConnection.current?.createAnswer())
        //@ts-ignore
        .then(sdp => myConnection.current?.setLocalDescription(sdp))
        .then(() => {
          socket.emit("answer", id, myConnection.current?.localDescription)
        })
        .catch((error: any) => {
          console.log("Error in connnection: ", error)
        });
        myConnection.current.ontrack = (event: any) => {
          console.log("on track event: ", event)
          if(myVideo.current) {
            myVideo.current.srcObject = event.streams[0]
          }
        }
        myConnection.current.onicecandidate = (event: any) => {
          if (event.candidate) {
            socket.emit("candidate", id, event.candidate)
          }
        }
        // myConnection.current.oniceconnectionstatechange = () => {
        //   if(myConnection.current?.iceConnectionState === 'disconnected') {
        //       console.log("connection close")
        //       myConnection.current.close()
        //       socket.close()
        //   }
        // }
      }
    })
    socket.on("candidate", (id, candidate) => {
      console.log("getting candidate: ", candidate)
      myConnection.current?.addIceCandidate(new RTCIceCandidate(candidate)).catch((e: any) => console.error(e));
    })
    socket.on("connect", () => {
      console.log("I'm connecting with the webrtc server")
      socket.emit("watcher", getDeviceId(item));
    })
    socket.on("broadcaster", () => {
      console.log("I'm getting a broadcast signal")
      socket.emit("watcher", getDeviceId(item));
    })
    socket.on("disconnect", () => {
      console.log("Disconnect from broadcaster")
      // setIsConnected(false)
    })
  }

  const onRefresh = () => {
    if(myConnection.current) {
      myConnection.current.removeEventListener("track", event => {
        console.log("on track event: ", event)
        if(myVideo.current) {
          console.log("My video current")
          myVideo.current.srcObject = event.streams[0]
        }
      })
      myConnection.current.removeEventListener("icecandidate", event => {
        if (event.candidate) {
          //socket.emit("candidate", id, event.candidate);
        }
      })
      myConnection.current.close()
    }
    if(mySocket.current) {
      let socket: Socket = mySocket.current
      socket.off("offer")
      socket.off("candidate")
      socket.off("connect")
      socket.off("broadcaster")
      socket.off("disconnect")
      mySocket.current.close()
    }

    requestAnimationFrame(() => {createNewConnection(mySocket, myConnection, myVideo)})
  }

  const onSwitch = async () => {
    let newCameraType = "2D"
    if(cameraType === "2D") {
      newCameraType = "3D"
    } 
    const updateInput = {
      id: item.deviceId,
      cameraType: newCameraType
    }
    const response: any = await API.graphql(graphqlOperation(updateDeviceSettings, {
      input: updateInput
    }))
    if(response.errors) {
      console.log("update errors: ", response)
    } else {
      setCameraType(newCameraType)
    }
  }

  const openVideo = () => {
    createNewConnection(modalSocket, modalConnection, modalVideo)
    setVideoModal(
      Modal.info({
        icon: null,
        title: "Room Monitor",
        width: "70%",
        content: (
          <VideoView myVideo={modalVideo} />
        )
      })
    )
  }

  const CardHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingLeft: 10,
          paddingRight: 10,
          height: 30,
          margin: 0,
          fontWeight: "bold"
        }}
      >
        <div
          style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <div style={{ marginRight: 5 }}>Room {item?.deviceId}</div>
          <RiskLevelBadge item={item?.patient} />
        </div>
        <div>
          <Button style={{ marginRight: 10 }} type="ghost" shape="circle" icon={<SwapOutlined />} size="middle" onClick={onSwitch} />
          <Button type="ghost" shape="circle" icon={<ReloadOutlined />} size="middle" onClick={onRefresh}/>
        </div>
      </div>
    )
  }

  const VideoView = useMemo(() => ({ myVideo }: { myVideo: RefObject<HTMLVideoElement> }) => {
    return (
      <div style={{ paddingTop: "56.25%", position:"relative" }}>
        <video 
          playsInline 
          muted 
          ref={myVideo} 
          autoPlay 
          style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", backgroundColor: "black" }}
        />
      </div>
    )
  }, [myVideo])

  const DefaultVideoView = () => {
    return (
      <div
        style={{
          height: "100%",
          minHeight: 150,
          justifyContent: "center",
          textAlign: "center",
          verticalAlign: "center",
          display: "flex"
        }}
      >
        <span style={{ marginTop: "auto", marginBottom: "auto" }}>
          Video stream is not available
        </span>
      </div>
    )
  }

  const ActionBar = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: 70,
          borderBottomLeftRadius: 10,
          borderBottomRightRadius: 10,
          backgroundColor:
            currentNotifications[0].type === "fall"
              ? palette.red
              : currentNotifications[0]?.type === "getup"
              ? palette.orange
              : currentNotifications[0]?.type === "askforhelp"
              ? palette.yellow
              : currentNotifications[0].status === "New"
              ? palette.red
              : currentNotifications[0].status === "Processing"
              ? palette.blue
              : palette.green,
          transition: "0.2s"
        }}
      >
        <div
          style={{
            textAlign: "center",
            color: "#fff",
            fontWeight: "bold",
            marginBottom: 1
          }}
        >
          {currentNotifications[0].isFalseAlarm
            ? "Continual learning, notification not required."
            : currentNotifications[0].handoverNurseId !== "null"
            ? "Handed over to " +
              (currentNotifications[0].handoverNurse?.name || "unknown")
            : currentNotifications[0].type === "fall"
            ? `Fall Detected at 
            ${moment(currentNotifications[0].createdAt).format(
              "H:mm:ssa DD.MM.YYYY"
            )}`
            : currentNotifications[0].status === "Processing"
            ? "Processing"
            : currentNotifications[0].type === "getup"
            ? `Patient On The Go ${moment(
                currentNotifications[0].createdAt
              ).format("H:mm:ssa DD.MM.YYYY")}`
            : currentNotifications[0].type === "askforhelp"
            ? `Ask for help ${moment(
                currentNotifications[0].createdAt
              ).format("H:mm:ssa DD.MM.YYYY")}`
            : `Processing`}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly"
          }}
        >
          <Button
            type="link"
            onClick={async () => {
              await setOnMyWay(currentNotifications[0].id)
              dispatch(processNotification(currentNotifications[0].id))
              setTimeout(() => {
                setIsNotificationResolved(true)
              }, 5000)
            }}
            style={{
              backgroundColor:
                currentNotifications[0].handoverNurseId !== "null" ||
                currentNotifications[0].isFalseAlarm ||
                currentNotifications[0].status === "Processing"
                  ? "grey"
                  : "#1991eb",
              color: "#fff",
              borderRadius: 5,
              fontWeight: "bold"
            }}
            disabled={
              currentNotifications[0].handoverNurseId !== "null" ||
              currentNotifications[0].isFalseAlarm ||
              currentNotifications[0].status === "Processing"
            }
          >
            On my way
          </Button>
          <Button
            type="link"
            onClick={() => {
              // pop up choose nurse
              setRecordId(currentNotifications[0].id)
              setVisible(true)
            }}
            style={{
              backgroundColor:
                currentNotifications[0].handoverNurseId !== "null" ||
                currentNotifications[0].isFalseAlarm ||
                currentNotifications[0].status === "Processing"
                  ? "grey"
                  : "#6b47db",
              color: "#fff",
              borderRadius: 5,
              fontWeight: "bold"
            }}
            disabled={
              currentNotifications[0].handoverNurseId !== "null" ||
              currentNotifications[0].isFalseAlarm ||
              currentNotifications[0].status === "Processing"
            }
          >
            Hand over
          </Button>
          <Button
            type="link"
            onClick={async () => {
              await setIsFalseAlarm(currentNotifications[0].id)
              dispatch(processNotification(currentNotifications[0].id))
              setTimeout(() => {
                setIsNotificationResolved(true)
              }, 5000)
            }}
            style={{
              backgroundColor:
                currentNotifications[0].handoverNurseId !== "null" ||
                currentNotifications[0].isFalseAlarm ||
                currentNotifications[0].status === "Processing"
                  ? "grey"
                  : "#7e7e7e",
              color: "#fff",
              borderRadius: 5,
              fontWeight: "bold"
            }}
            disabled={
              currentNotifications[0].handoverNurseId !== "null" ||
              currentNotifications[0].isFalseAlarm ||
              currentNotifications[0].status === "Processing"
            }
          >
            Report
          </Button>
        </div>
      </div>
    ) 
  }

  const DefaultActionBar = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: 70,
          fontWeight: "bold",
          color: "#fff",
          borderBottomLeftRadius: 10,
          borderBottomRightRadius: 10,
          backgroundColor: "#39b54a"
        }}
      >
        Safe
      </div>
    )
  }

  return (
    <Card
      style={{
        borderRadius: 10,
        marginRight: 5,
        marginTop: 5,
        display: "flex",
        flex: 1,
        minWidth: 300
      }}
      hoverable
      bodyStyle={{ padding: 0, margin: 0, display: "flex", flex: 1 }}
    >
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between"
        }}
      >
        <CardHeader />
        {item ? (
          <div onClick={() => {
            openVideo()
          }}>
            <VideoView myVideo={myVideo} />
          </div>
        ) : (
          <DefaultVideoView />
        )}
        {
          withActionBar ? (
            isNotificationResolved === false && currentNotifications.length ? (
              <ActionBar />
            ) : (
              <DefaultActionBar/>
            )
          ) : (
            null
          )
        }
      </div>
      {
        withActionBar ? (
          <HandOverModal
            visible={visible}
            setVisible={setVisible}
            onHandOver={async (nurseId: string) => {
              //console.log(nurseId)

              try {
                await handOver(recordId, nurseId)
                setVisible(false)
                dispatch(processNotification(currentNotifications[0].id))
                setTimeout(() => {
                  setIsNotificationResolved(true)
                }, 5000)
              } catch (error: any) {
                message.warning(error.message)
              }
            }}
          />
        ) : (
          null
        )
      }
    </Card>
  )
}

export default React.memo(WebRTCMonitorCard)
