/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPatient = /* GraphQL */ `
  mutation CreatePatient(
    $input: CreatePatientInput!
    $condition: ModelPatientConditionInput
  ) {
    createPatient(input: $input, condition: $condition) {
      id
      name
      avatar
      gender
      building
      room
      yearOfBirth
      activityLevel
      patientDoctorId
      patientNurseId
      dangerDegree
      organisationId
      status
      photo
      likelihood
      consequence
      riskCoefficient
      createdAt
      updatedAt
      doctor {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      nurse {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        
        device {
          nextToken
        }
      }
      family {
        items {
          id
          name
          avatar
          email
          phone
          gender
          relationship
          pushToken
          pushTokens
          prefer
          familyMemberPatientId
          createdAt
          updatedAt
        }
        nextToken
      }
      records {
        items {
          id
          recordDoctorId
          recordNurseId
          recordPatientId
          position
          video
          status
          isFalseAlarm
          type
          handoverNurseId
          createdAt
          updatedAt
        }
        nextToken
      }
      device {
        items {
          id
          deviceId
          deviceDoctorId
          devicePatientId
          deviceNurseId
          organisationId
          status
          position
          input
          output
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updatePatient = /* GraphQL */ `
  mutation UpdatePatient(
    $input: UpdatePatientInput!
    $condition: ModelPatientConditionInput
  ) {
    updatePatient(input: $input, condition: $condition) {
      id
      name
      avatar
      gender
      building
      room
      yearOfBirth
      activityLevel
      patientDoctorId
      patientNurseId
      dangerDegree
      organisationId
      status
      photo
      likelihood
      consequence
      riskCoefficient
      createdAt
      updatedAt
      doctor {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      nurse {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        
        device {
          nextToken
        }
      }
      family {
        items {
          id
          name
          avatar
          email
          phone
          gender
          relationship
          pushToken
          pushTokens
          prefer
          familyMemberPatientId
          createdAt
          updatedAt
        }
        nextToken
      }
      records {
        items {
          id
          recordDoctorId
          recordNurseId
          recordPatientId
          position
          video
          status
          isFalseAlarm
          type
          handoverNurseId
          createdAt
          updatedAt
        }
        nextToken
      }
      device {
        items {
          id
          deviceId
          deviceDoctorId
          devicePatientId
          deviceNurseId
          organisationId
          status
          position
          input
          output
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createDoctor = /* GraphQL */ `
  mutation CreateDoctor(
    $input: CreateDoctorInput!
    $condition: ModelDoctorConditionInput
  ) {
    createDoctor(input: $input, condition: $condition) {
      id
      name
      avatar
      email
      phone
      pushToken
      pushTokens
      organisationId
      createdAt
      updatedAt
      patient {
        items {
          id
          name
          avatar
          gender
          building
          room
          yearOfBirth
          activityLevel
          patientDoctorId
          patientNurseId
          dangerDegree
          organisationId
          status
          photo
          likelihood
          consequence
          riskCoefficient
          createdAt
          updatedAt
        }
        nextToken
      }
      records {
        items {
          id
          recordDoctorId
          recordNurseId
          recordPatientId
          position
          video
          status
          isFalseAlarm
          type
          handoverNurseId
          createdAt
          updatedAt
        }
        nextToken
      }
      device {
        items {
          id
          deviceId
          deviceDoctorId
          devicePatientId
          deviceNurseId
          organisationId
          status
          position
          input
          output
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateDoctor = /* GraphQL */ `
  mutation UpdateDoctor(
    $input: UpdateDoctorInput!
    $condition: ModelDoctorConditionInput
  ) {
    updateDoctor(input: $input, condition: $condition) {
      id
      name
      avatar
      email
      phone
      pushToken
      pushTokens
      organisationId
      createdAt
      updatedAt
      patient {
        items {
          id
          name
          avatar
          gender
          building
          room
          yearOfBirth
          activityLevel
          patientDoctorId
          patientNurseId
          dangerDegree
          organisationId
          status
          photo
          likelihood
          consequence
          riskCoefficient
          createdAt
          updatedAt
        }
        nextToken
      }
      records {
        items {
          id
          recordDoctorId
          recordNurseId
          recordPatientId
          position
          video
          status
          isFalseAlarm
          type
          handoverNurseId
          createdAt
          updatedAt
        }
        nextToken
      }
      device {
        items {
          id
          deviceId
          deviceDoctorId
          devicePatientId
          deviceNurseId
          organisationId
          status
          position
          input
          output
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createNurse = /* GraphQL */ `
  mutation CreateNurse(
    $input: CreateNurseInput!
    $condition: ModelNurseConditionInput
  ) {
    createNurse(input: $input, condition: $condition) {
      id
      name
      avatar
      email
      phone
      pushToken
      pushTokens
      organisationId
      createdAt
      updatedAt
      patient {
        items {
          id
          name
          avatar
          gender
          building
          room
          yearOfBirth
          activityLevel
          patientDoctorId
          patientNurseId
          dangerDegree
          organisationId
          status
          photo
          likelihood
          consequence
          riskCoefficient
          createdAt
          updatedAt
        }
        nextToken
      }
      records {
        items {
          id
          recordDoctorId
          recordNurseId
          recordPatientId
          position
          video
          status
          isFalseAlarm
          type
          handoverNurseId
          createdAt
          updatedAt
        }
        nextToken
      }
      handoverRecords {
        items {
          id
          recordDoctorId
          recordNurseId
          recordPatientId
          position
          video
          status
          isFalseAlarm
          type
          handoverNurseId
          createdAt
          updatedAt
        }
        nextToken
      }
      device {
        items {
          id
          deviceId
          deviceDoctorId
          devicePatientId
          deviceNurseId
          organisationId
          status
          position
          input
          output
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateNurse = /* GraphQL */ `
  mutation UpdateNurse(
    $input: UpdateNurseInput!
    $condition: ModelNurseConditionInput
  ) {
    updateNurse(input: $input, condition: $condition) {
      id
      name
      avatar
      email
      phone
      pushToken
      pushTokens
      organisationId
      createdAt
      updatedAt
      patient {
        items {
          id
          name
          avatar
          gender
          building
          room
          yearOfBirth
          activityLevel
          patientDoctorId
          patientNurseId
          dangerDegree
          organisationId
          status
          photo
          likelihood
          consequence
          riskCoefficient
          createdAt
          updatedAt
        }
        nextToken
      }
      records {
        items {
          id
          recordDoctorId
          recordNurseId
          recordPatientId
          position
          video
          status
          isFalseAlarm
          type
          handoverNurseId
          createdAt
          updatedAt
        }
        nextToken
      }
      handoverRecords {
        items {
          id
          recordDoctorId
          recordNurseId
          recordPatientId
          position
          video
          status
          isFalseAlarm
          type
          handoverNurseId
          createdAt
          updatedAt
        }
        nextToken
      }
      device {
        items {
          id
          deviceId
          deviceDoctorId
          devicePatientId
          deviceNurseId
          organisationId
          status
          position
          input
          output
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteNurse = /* GraphQL */ `
  mutation DeleteNurse(
    $input: DeleteNurseInput!
    $condition: ModelNurseConditionInput
  ) {
    deleteNurse(input: $input, condition: $condition) {
      id
      name
      avatar
      email
      phone
      pushToken
      pushTokens
      organisationId
      createdAt
      updatedAt
      patient {
        items {
          id
          name
          avatar
          gender
          building
          room
          yearOfBirth
          activityLevel
          patientDoctorId
          patientNurseId
          dangerDegree
          organisationId
          status
          photo
          likelihood
          consequence
          riskCoefficient
          createdAt
          updatedAt
        }
        nextToken
      }
      records {
        items {
          id
          recordDoctorId
          recordNurseId
          recordPatientId
          position
          video
          status
          isFalseAlarm
          type
          handoverNurseId
          createdAt
          updatedAt
        }
        nextToken
      }
      handoverRecords {
        items {
          id
          recordDoctorId
          recordNurseId
          recordPatientId
          position
          video
          status
          isFalseAlarm
          type
          handoverNurseId
          createdAt
          updatedAt
        }
        nextToken
      }
      device {
        items {
          id
          deviceId
          deviceDoctorId
          devicePatientId
          deviceNurseId
          organisationId
          status
          position
          input
          output
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createMember = /* GraphQL */ `
  mutation CreateMember(
    $input: CreateFamilyMemberInput!
    $condition: ModelFamilyMemberConditionInput
  ) {
    createMember(input: $input, condition: $condition) {
      id
      name
      avatar
      email
      phone
      gender
      relationship
      pushToken
      pushTokens
      prefer
      familyMemberPatientId
      stripeCustomerId
      isSubscribed
      subscriptionExpiry
      createdAt
      updatedAt
      patient {
        id
        name
        avatar
        gender
        building
        room
        yearOfBirth
        activityLevel
        patientDoctorId
        patientNurseId
        dangerDegree
        organisationId
        status
        photo
        likelihood
        consequence
        riskCoefficient
        createdAt
        updatedAt
        doctor {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        nurse {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        family {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
    }
  }
`;
export const updateMember = /* GraphQL */ `
  mutation UpdateMember(
    $input: UpdateFamilyMemberInput!
    $condition: ModelFamilyMemberConditionInput
  ) {
    updateMember(input: $input, condition: $condition) {
      id
      name
      avatar
      email
      phone
      gender
      relationship
      pushToken
      pushTokens
      prefer
      familyMemberPatientId
      stripeCustomerId
      isSubscribed
      subscriptionExpiry
      createdAt
      updatedAt
      patient {
        id
        name
        avatar
        gender
        building
        room
        yearOfBirth
        activityLevel
        patientDoctorId
        patientNurseId
        dangerDegree
        organisationId
        status
        photo
        likelihood
        consequence
        riskCoefficient
        createdAt
        updatedAt
        doctor {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        nurse {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        family {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
    }
  }
`;
export const createPatientDoctorRelation = /* GraphQL */ `
  mutation CreatePatientDoctorRelation(
    $input: CreatePatientDoctorRelationInput!
    $condition: ModelPatientDoctorRelationConditionInput
  ) {
    createPatientDoctorRelation(input: $input, condition: $condition) {
      id
      doctorId
      patientId
      createdAt
      updatedAt
      patient {
        id
        name
        avatar
        gender
        building
        room
        yearOfBirth
        activityLevel
        patientDoctorId
        patientNurseId
        dangerDegree
        organisationId
        status
        photo
        likelihood
        consequence
        riskCoefficient
        createdAt
        updatedAt
        doctor {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        nurse {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        family {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      doctor {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
    }
  }
`;
export const updatePatientDoctorRelation = /* GraphQL */ `
  mutation UpdatePatientDoctorRelation(
    $input: UpdatePatientDoctorRelationInput!
    $condition: ModelPatientDoctorRelationConditionInput
  ) {
    updatePatientDoctorRelation(input: $input, condition: $condition) {
      id
      doctorId
      patientId
      createdAt
      updatedAt
      patient {
        id
        name
        avatar
        gender
        building
        room
        yearOfBirth
        activityLevel
        patientDoctorId
        patientNurseId
        dangerDegree
        organisationId
        status
        photo
        likelihood
        consequence
        riskCoefficient
        createdAt
        updatedAt
        doctor {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        nurse {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        family {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      doctor {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
    }
  }
`;
export const deletePatientDoctorRelation = /* GraphQL */ `
  mutation DeletePatientDoctorRelation(
    $input: DeletePatientDoctorRelationInput!
    $condition: ModelPatientDoctorRelationConditionInput
  ) {
    deletePatientDoctorRelation(input: $input, condition: $condition) {
      id
      doctorId
      patientId
      createdAt
      updatedAt
      patient {
        id
        name
        avatar
        gender
        building
        room
        yearOfBirth
        activityLevel
        patientDoctorId
        patientNurseId
        dangerDegree
        organisationId
        status
        photo
        likelihood
        consequence
        riskCoefficient
        createdAt
        updatedAt
        doctor {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        nurse {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        family {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      doctor {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
    }
  }
`;
export const createAdmin = /* GraphQL */ `
  mutation CreateAdmin(
    $input: CreateAdminInput!
    $condition: ModelAdminConditionInput
  ) {
    createAdmin(input: $input, condition: $condition) {
      id
      name
      avatar
      email
      phone
      pushToken
      pushTokens
      organisationId
      createdAt
      updatedAt
      organisation {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateAdmin = /* GraphQL */ `
  mutation UpdateAdmin(
    $input: UpdateAdminInput!
    $condition: ModelAdminConditionInput
  ) {
    updateAdmin(input: $input, condition: $condition) {
      id
      name
      avatar
      email
      phone
      pushToken
      pushTokens
      organisationId
      createdAt
      updatedAt
      organisation {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteAdmin = /* GraphQL */ `
  mutation DeleteAdmin(
    $input: DeleteAdminInput!
    $condition: ModelAdminConditionInput
  ) {
    deleteAdmin(input: $input, condition: $condition) {
      id
      name
      avatar
      email
      phone
      pushToken
      pushTokens
      organisationId
      createdAt
      updatedAt
      organisation {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
`;
export const createOrganisation = /* GraphQL */ `
  mutation CreateOrganisation(
    $input: CreateOrganisationInput!
    $condition: ModelOrganisationConditionInput
  ) {
    createOrganisation(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateOrganisation = /* GraphQL */ `
  mutation UpdateOrganisation(
    $input: UpdateOrganisationInput!
    $condition: ModelOrganisationConditionInput
  ) {
    updateOrganisation(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrganisation = /* GraphQL */ `
  mutation DeleteOrganisation(
    $input: DeleteOrganisationInput!
    $condition: ModelOrganisationConditionInput
  ) {
    deleteOrganisation(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const createRecord = /* GraphQL */ `
  mutation CreateRecord(
    $input: CreateRecordInput!
    $condition: ModelRecordConditionInput
  ) {
    createRecord(input: $input, condition: $condition) {
      id
      recordDoctorId
      recordNurseId
      recordPatientId
      position
      alertStatus {
        id
        name
        confirm
      }
      video
      status
      isFalseAlarm
      type
      handoverNurseId
      createdAt
      updatedAt
      patient {
        id
        name
        avatar
        gender
        building
        room
        yearOfBirth
        activityLevel
        patientDoctorId
        patientNurseId
        dangerDegree
        organisationId
        status
        photo
        likelihood
        consequence
        riskCoefficient
        createdAt
        updatedAt
        doctor {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        nurse {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        family {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      doctor {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      nurse {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        
        device {
          nextToken
        }
      }
      handoverNurse {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        
        device {
          nextToken
        }
      }
    }
  }
`;
export const updateRecord = /* GraphQL */ `
  mutation UpdateRecord(
    $input: UpdateRecordInput!
    $condition: ModelRecordConditionInput
  ) {
    updateRecord(input: $input, condition: $condition) {
      id
      recordDoctorId
      recordNurseId
      recordPatientId
      position
      alertStatus {
        id
        name
        confirm
      }
      video
      status
      isFalseAlarm
      type
      handoverNurseId
      createdAt
      updatedAt
      patient {
        id
        name
        avatar
        gender
        building
        room
        yearOfBirth
        activityLevel
        patientDoctorId
        patientNurseId
        dangerDegree
        organisationId
        status
        photo
        likelihood
        consequence
        riskCoefficient
        createdAt
        updatedAt
        doctor {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        nurse {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        family {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      doctor {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      nurse {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        
        device {
          nextToken
        }
      }
      handoverNurse {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        
        device {
          nextToken
        }
      }
    }
  }
`;
export const deleteRecord = /* GraphQL */ `
  mutation DeleteRecord(
    $input: DeleteRecordInput!
    $condition: ModelRecordConditionInput
  ) {
    deleteRecord(input: $input, condition: $condition) {
      id
      recordDoctorId
      recordNurseId
      recordPatientId
      position
      alertStatus {
        id
        name
        confirm
      }
      video
      status
      isFalseAlarm
      type
      handoverNurseId
      createdAt
      updatedAt
      patient {
        id
        name
        avatar
        gender
        building
        room
        yearOfBirth
        activityLevel
        patientDoctorId
        patientNurseId
        dangerDegree
        organisationId
        status
        photo
        likelihood
        consequence
        riskCoefficient
        createdAt
        updatedAt
        doctor {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        nurse {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        family {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      doctor {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      nurse {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        
        device {
          nextToken
        }
      }
      handoverNurse {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        
        device {
          nextToken
        }
      }
    }
  }
`;
export const createRiskRecord = /* GraphQL */ `
  mutation CreateRiskRecord(
    $input: CreateRiskRecordInput!
    $condition: ModelRiskRecordConditionInput
  ) {
    createRiskRecord(input: $input, condition: $condition) {
      patientId
      likelihoood
      consequence
      riskCoefficient
      createdAt
      updatedAt
    }
  }
`;
export const updateRiskRecord = /* GraphQL */ `
  mutation UpdateRiskRecord(
    $input: UpdateRiskRecordInput!
    $condition: ModelRiskRecordConditionInput
  ) {
    updateRiskRecord(input: $input, condition: $condition) {
      patientId
      likelihoood
      consequence
      riskCoefficient
      createdAt
      updatedAt
    }
  }
`;
export const deleteRiskRecord = /* GraphQL */ `
  mutation DeleteRiskRecord(
    $input: DeleteRiskRecordInput!
    $condition: ModelRiskRecordConditionInput
  ) {
    deleteRiskRecord(input: $input, condition: $condition) {
      patientId
      likelihoood
      consequence
      riskCoefficient
      createdAt
      updatedAt
    }
  }
`;
export const createDevice = /* GraphQL */ `
  mutation CreateDevice(
    $input: CreateDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    createDevice(input: $input, condition: $condition) {
      id
      deviceId
      deviceDoctorId
      devicePatientId
      deviceNurseId
      organisationId
      status
      position
      input
      output
      deviceSettings {
        showPatientOutline
        showPatientNameAndRoom
      }
      createdAt
      updatedAt
      patient {
        id
        name
        avatar
        gender
        building
        room
        yearOfBirth
        activityLevel
        patientDoctorId
        patientNurseId
        dangerDegree
        organisationId
        status
        photo
        likelihood
        consequence
        riskCoefficient
        createdAt
        updatedAt
        doctor {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        nurse {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        family {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      doctor {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      nurse {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        
        device {
          nextToken
        }
      }
      organisation {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateDevice = /* GraphQL */ `
  mutation UpdateDevice(
    $input: UpdateDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    updateDevice(input: $input, condition: $condition) {
      id
      deviceId
      deviceDoctorId
      devicePatientId
      deviceNurseId
      organisationId
      status
      position
      input
      output
      deviceSettings {
        showPatientOutline
        showPatientNameAndRoom
      }
      createdAt
      updatedAt
      patient {
        id
        name
        avatar
        gender
        building
        room
        yearOfBirth
        activityLevel
        patientDoctorId
        patientNurseId
        dangerDegree
        organisationId
        status
        photo
        likelihood
        consequence
        riskCoefficient
        createdAt
        updatedAt
        doctor {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        nurse {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        family {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      doctor {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      nurse {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        
        device {
          nextToken
        }
      }
      organisation {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteDevice = /* GraphQL */ `
  mutation DeleteDevice(
    $input: DeleteDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    deleteDevice(input: $input, condition: $condition) {
      id
      deviceId
      deviceDoctorId
      devicePatientId
      deviceNurseId
      organisationId
      status
      position
      input
      output
      deviceSettings {
        showPatientOutline
        showPatientNameAndRoom
      }
      createdAt
      updatedAt
      patient {
        id
        name
        avatar
        gender
        building
        room
        yearOfBirth
        activityLevel
        patientDoctorId
        patientNurseId
        dangerDegree
        organisationId
        status
        photo
        likelihood
        consequence
        riskCoefficient
        createdAt
        updatedAt
        doctor {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        nurse {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        family {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      doctor {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      nurse {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        
        device {
          nextToken
        }
      }
      organisation {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
`;
export const createDeviceSettings = /* GraphQL */ `
  mutation CreateDeviceSettings(
    $input: CreateDeviceSettingsInput!
    $condition: ModelDeviceSettingsConditionInput
  ) {
    createDeviceSettings(input: $input, condition: $condition) {
      id
      showPatientOutline
      showPatientNameAndRoom
      cameraType
      createdAt
      updatedAt
    }
  }
`;
export const updateDeviceSettings = /* GraphQL */ `
  mutation UpdateDeviceSettings(
    $input: UpdateDeviceSettingsInput!
    $condition: ModelDeviceSettingsConditionInput
  ) {
    updateDeviceSettings(input: $input, condition: $condition) {
      id
      showPatientOutline
      showPatientNameAndRoom
      cameraType
      createdAt
      updatedAt
    }
  }
`;
export const deleteDeviceSettings = /* GraphQL */ `
  mutation DeleteDeviceSettings(
    $input: DeleteDeviceSettingsInput!
    $condition: ModelDeviceSettingsConditionInput
  ) {
    deleteDeviceSettings(input: $input, condition: $condition) {
      id
      showPatientOutline
      showPatientNameAndRoom
      cameraType
      createdAt
      updatedAt
    }
  }
`;
export const createRestrictedRegion = /* GraphQL */ `
  mutation CreateRestrictedRegion(
    $input: CreateRestrictedRegionInput!
    $condition: ModelRestrictedRegionConditionInput
  ) {
    createRestrictedRegion(input: $input, condition: $condition) {
      id
      deviceId
      name
      points
      type
      createdAt
      updatedAt
    }
  }
`;
export const updateRestrictedRegion = /* GraphQL */ `
  mutation UpdateRestrictedRegion(
    $input: UpdateRestrictedRegionInput!
    $condition: ModelRestrictedRegionConditionInput
  ) {
    updateRestrictedRegion(input: $input, condition: $condition) {
      id
      deviceId
      name
      points
      type
      createdAt
      updatedAt
    }
  }
`;
export const deleteRestrictedRegion = /* GraphQL */ `
  mutation DeleteRestrictedRegion(
    $input: DeleteRestrictedRegionInput!
    $condition: ModelRestrictedRegionConditionInput
  ) {
    deleteRestrictedRegion(input: $input, condition: $condition) {
      id
      deviceId
      name
      points
      type
      createdAt
      updatedAt
    }
  }
`;
export const createImageRequest = /* GraphQL */ `
  mutation CreateImageRequest(
    $input: CreateImageRequestInput!
    $condition: ModelImageRequestConditionInput
  ) {
    createImageRequest(input: $input, condition: $condition) {
      id
      imageUrl
      requestedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateImageRequest = /* GraphQL */ `
  mutation UpdateImageRequest(
    $input: UpdateImageRequestInput!
    $condition: ModelImageRequestConditionInput
  ) {
    updateImageRequest(input: $input, condition: $condition) {
      id
      imageUrl
      requestedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteImageRequest = /* GraphQL */ `
  mutation DeleteImageRequest(
    $input: DeleteImageRequestInput!
    $condition: ModelImageRequestConditionInput
  ) {
    deleteImageRequest(input: $input, condition: $condition) {
      id
      imageUrl
      requestedAt
      createdAt
      updatedAt
    }
  }
`;