import React, { useEffect, useState } from "react"
import { List, Card, Button, Typography, PageHeader, Tag } from "antd"
import usePatients from "../hooks/usePatients"
import useCurrentUser from "../hooks/useCurrentUser"
import { getNumId } from "../util"
import { PlusCircleFilled, ReloadOutlined } from "@ant-design/icons"
import PatientEditForm from "../components/PatientEditForm"
import RiskLevelBadge from "../components/RiskLevelBadge"
const { Meta } = Card

const showName = false

const Patients: React.FC = () => {
  const { patients, fetchPatients } = usePatients()
  const [loading, setLoading] = useState<boolean>(false)
  const [modalVisibility, setModalVisibility] = useState<boolean>(false)
  const [mode, setMode] = useState<"create" | "edit">("create")
  const [patientUnderEdit, setPatientUnderEdit] = useState<any>()
  const { currentUser } = useCurrentUser()
  useEffect(() => {
    fetchPatients({ isExtend: false })
    //eslint-disable-next-line
  }, [])

  const onLoadMore = async (isExtend = false) => {
    setLoading(true)
    if (currentUser.username) {
      fetchPatients({
        isExtend,
        callback: () => {
          setLoading(false)
        }
      })
    }
  }
  const loadMore = !loading ? (
    <div
      style={{
        textAlign: "center",
        marginTop: 12,
        height: 32,
        lineHeight: "32px"
      }}
    >
      <Button
        onClick={() => onLoadMore(true)}
        disabled={!currentUser.username || !patients.nextToken}
      >
        Load more
      </Button>
    </div>
  ) : null

  return (
    <>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Patients"
        extra={[
          <Button
            key="add"
            // type="primary"
            onClick={() => {
              setModalVisibility(true)
              setPatientUnderEdit(undefined)
              setMode("create")
            }}
            shape="round"
          >
            <PlusCircleFilled />
            Add New Patient
          </Button>,
          <Button
            style={{ float: "right" }}
            key="refresh"
            type="primary"
            onClick={() => onLoadMore()}
            shape="round"
          >
            <ReloadOutlined />
            Refresh
          </Button>
        ]}
      />
      <PatientEditForm
        visibility={modalVisibility}
        patientDetails={patientUnderEdit}
        mode={mode}
        onCancel={() => {
          setModalVisibility(false)
          setMode("create")
          setPatientUnderEdit(undefined)
        }}
        onSave={() => {}}
      />
      <List
        grid={{
          gutter: 10,
          xs: 1,
          sm: 1,
          md: 2,
          lg: 3,
          xl: 3,
          xxl: 4
        }}
        style={{ marginTop: '10px' }}
        loading={loading}
        itemLayout="horizontal"
        loadMore={loadMore}
        dataSource={patients.items}
        renderItem={(item: any, index: number) => {
          if (index > 8) {
            return null;
          }
          return (
            <List.Item>
              <Card
                key={item.updatedAt}
                hoverable
                title={
                  <Typography.Title level={3}>
                    {showName
                      ? `${item.name}`
                      : `Patient ID: ${getNumId(item.id)}`}
                  </Typography.Title>
                }
                // cover={
                //   <img
                //     key={item.id + item.updatedAt}
                //     src={getFilePath(item.avatar)}
                //     alt="avatar"
                //     style={{ height: 300, objectFit: "cover" }}
                //   />
                // }
                onClick={() => {
                  setPatientUnderEdit(item)
                  setModalVisibility(true)
                  setMode("edit")
                }}
              >
                <Meta
                  description={
                    <Typography.Title level={5}>
                      {/* Patient ID: {getNumId(item.id)} */}
                      {/* <br /> */}
                      {`Room: ${item.position || `${Math.floor(Math.random() * 10)}0${Math.floor(Math.random() * 10)}`}`}
                    </Typography.Title>
                  }
                />
                <div>
                  <span style={{ fontSize: 16 }}>Risk Level: {"  "}</span>
                  <span>
                    <RiskLevelBadge item={item} />
                  </span>
                </div>
                <div style={{ marginTop: 10 }}>
                  <span style={{ fontSize: 16 }}>
                    Fall Record Status: {"  "}
                  </span>
                  <span>
                    <Tag
                      color={
                        item.status === "Processing" ? "#f00" : "lightgrey"
                      }
                    >
                      {item.status || "None"}
                    </Tag>
                  </span>
                </div>
              </Card>
            </List.Item>
          )
        }}
      />
    </>
  )
}

export default Patients
