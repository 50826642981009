import React, { useEffect, useState } from "react"
import { Select } from "antd"
import { API, graphqlOperation } from "aws-amplify"
import { listPatients, listDoctors, listNurses } from "../graphql/queries"
import _ from "lodash"
const { Option } = Select
interface DoctorSelectProp {
  value: any
  onSelectionChange: (option: any) => void
  type: "doctor" | "patient" | "nurse"
}

const DoctorSearchSelect: React.FC<DoctorSelectProp> = ({
  value,
  onSelectionChange,
  type
}) => {
  const [data, setData] = useState<Array<any>>([])

  const handleSearch = async (text: string) => {
    console.log("searching...")
    try {
      const query = type === "doctor" ? listDoctors : type === "nurse" ? listNurses : listPatients
      const response: any = await API.graphql(
        graphqlOperation(query, {
          filter: { name: { contains: text } }
        })
      )
      setData(
        response.data[type === "doctor" ? "listDoctors" : type === "nurse" ? "listNurses" : "listPatients"].items
      )
    } catch (error) {
      console.error(error)
    }
  }

  const handleChange = (id: string) => {
    onSelectionChange(data.find((d) => d.id === id))
  }

  useEffect(() => {
    if (value?.id) {
      setData([value])
    }
  }, [value])

  const options = data.map((d) => (
    <Option key={d.id} value={d.id}>
      {d.name}
    </Option>
  ))
  return (
    <Select
      showSearch
      value={value?.id}
      style={{ width: "100%" }}
      placeholder={"Type to search..."}
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={_.debounce(handleSearch, 300)}
      onChange={handleChange}
      notFoundContent={null}
    >
      {options}
    </Select>
  )
}

export default DoctorSearchSelect
