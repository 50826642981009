import React, { useEffect, useState } from "react"
import { Button, Typography } from "antd"
import { WarningTwoTone } from "@ant-design/icons"
import useCurrentUser from "../hooks/useCurrentUser"
import { Link } from "react-router-dom"


const SubscribeFailPage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const { currentUser } = useCurrentUser()
  
  const NavButton = () => {
    return (
      <Button 
        type="primary" 
        color="secondary"
      > 
        <Link
          to={"/dashboard/settings"}
          //style={styles.navlink}
          //activeStyle={styles.navlinkActiveStyle}
        >
          Return to Setting Page
        </Link>
      </Button>
    )
  }

  return (
    <div style={{ backgroundColor: "white", borderRadius: "10px", height: 800, width: "100%", alignItems: "center", justifyContent: "center", flexDirection: "column", display: "flex" }}>
      <WarningTwoTone twoToneColor="#eb2f96" style={{ fontSize: 50, marginBottom: 20 }}/>
      <Typography style={{ fontSize: 30, marginBottom: 20 }}>Sorry, Subscription Failed</Typography>
      <NavButton />
    </div>
  )
}

export default SubscribeFailPage
