import React, { useEffect, useState, useRef } from "react"
import { Modal, Form, Input, Button, message } from "antd"
import SearchSelect from "./SearchSelect"
import useDevices from "../hooks/useDevices"

const { Item } = Form
const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 }
}

interface DeviceDetailFromProp {
  mode: "create" | "edit"
  onCancel: () => void
  visibility: boolean
}

const DeviceForm: React.FC<DeviceDetailFromProp> = ({
  mode,
  onCancel,
  visibility
}) => {
  const { singleDevice, updateSingleDevice, createNewDevice } = useDevices()
  const formRef = useRef<any>()

  const [selectedDoctor, setSelectedDoctor] = useState<any>(singleDevice.doctor)
  const [selectedPatient, setSelectedPatient] = useState<any>(singleDevice.patient)
  const [selectedNurse, setSelectedNurse] = useState<any>(singleDevice.nurse)

  useEffect(() => {
    formRef.current && formRef.current.resetFields()
    setSelectedDoctor(singleDevice.doctor)
    setSelectedPatient(singleDevice.patient)
    setSelectedNurse(singleDevice.nurse)
  }, [singleDevice])

  function onCancelClicked() {
    setSelectedPatient(undefined)
    setSelectedPatient(undefined)
    setSelectedNurse(undefined)
    formRef.current && formRef.current.resetFields()
    onCancel()
  }

  function onSaveClicked(props: any) {
    if (!selectedDoctor?.id) {
      return message.error("No doctor selected.")
    }
    if (!selectedPatient?.id) {
      return message.error("No patient selected.")
    }
    if (!selectedNurse?.id) {
      return message.error("No nurse selected.")
    }
    if (mode === "create") {
      createNewDevice(
        {
          ...props,
          id: props.deviceId,
          devicePatientId: selectedPatient.id,
          deviceDoctorId: selectedDoctor.id,
          deviceNurseId: selectedNurse.id,
          organisationId: "null"
        },
        onCancelClicked
      )
    } else {
      updateSingleDevice(
        {
          ...props,
          devicePatientId: selectedPatient.id,
          deviceDoctorId: selectedDoctor.id,
          deviceNurseId: selectedNurse.id,
        },
        onCancelClicked
      )
    }
  }
  return (
    <Modal
      visible={visibility}
      width={"70%"}
      onCancel={onCancelClicked}
      footer={[
        <Button key="save" htmlType="submit" form="detailForm" type="primary">
          Save
        </Button>,
        <Button key="cancel" onClick={onCancelClicked}>
          Cancel
        </Button>
      ]}
    >
      <Form
        id="detailForm"
        {...layout}
        ref={formRef}
        initialValues={singleDevice}
        onFinish={onSaveClicked}
        style={{ padding: 20 }}
      >
        {mode === "edit" && (
          <Item
            name="id"
            label="Device ID"
            rules={[{ required: true, message: "Name is required!" }]}
          >
            <Input disabled />
          </Item>
        )}
        <Item
          name="deviceId"
          label="Device Name"
          rules={[{ required: true, message: "Name is required!" }]}
        >
          <Input />
        </Item>
        <Item
          name="position"
          label="Position"
          rules={[{ required: true, message: "Position is required!" }]}
        >
          <Input />
        </Item>
        <Item label="Doctor" required>
          <SearchSelect
            value={selectedDoctor}
            onSelectionChange={setSelectedDoctor}
            type="doctor"
          />
        </Item>
        <Item label="Nurse" required>
          <SearchSelect
            value={selectedNurse}
            onSelectionChange={setSelectedNurse}
            type="nurse"
          />
        </Item>
        <Item label="Patient" required>
          <SearchSelect
            value={selectedPatient}
            onSelectionChange={setSelectedPatient}
            type="patient"
          />
        </Item>
      </Form>
    </Modal>
  )
}

export default DeviceForm
