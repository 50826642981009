import { API, graphqlOperation, Auth } from "aws-amplify"
import { useSelector, useDispatch } from "react-redux"
import { setUser } from "../redux/User/actions"
import useFamilyMembers from "./useFamilyMembers"
import { getMember } from "../graphql/queries"

const useCurrentUser = () => {
  const dispatch = useDispatch()
  const { currentUser } = useSelector((state: any) => state.User)
  const { fetchSingleFamilyMember } = useFamilyMembers()

  async function setCurrentUserData() {
    try {
      const { username, attributes, signInUserSession } = await Auth.currentAuthenticatedUser()
      const groups = signInUserSession.idToken.payload['cognito:groups']
      let type
      let familyMemberPatientId
      if (groups.includes("Admin")) {
        type = "Admin"
      } else if (groups.includes("Doctor")) {
        type = "Doctor"
      } else if (groups.includes("Nurse")) {
        type = "Nurse"
      } else if (groups.includes("Family")) {
        type = "Family"
        const response: any = await API.graphql(
          graphqlOperation(getMember, { id: username })
        )
        console.log(response.data)
        familyMemberPatientId = response.data.getMember.familyMemberPatientId
      }
      // console.log("Attributes: ", attributes)
      // const familyMemberPatientId = attributes["custom:patientId"]
      const { email, name } = attributes
      dispatch(setUser({ email, name, username, type, familyMemberPatientId }))
    } catch (error) {
      console.error(error)
    }
  }

  return { currentUser, setCurrentUserData }
}

export default useCurrentUser