import { message } from "antd"
import { API, graphqlOperation } from "aws-amplify"
import { useDispatch, useSelector } from "react-redux"
import { updateRecord } from "../graphql/mutations"
import {
  processNotification as processNotificationAction,
  updateNotification
} from "../redux/Notification/actions"

const useNotification = () => {
  const { notifications } = useSelector((state: any) => state.Notification)

  const dispatch = useDispatch()

  function processNotification(id: string) {
    dispatch(processNotificationAction({ notificationId: id }))
  }

  async function setIsFalseAlarm(id: string, isFalseAlarm: boolean = true) {
    try {
      const response: any = await API.graphql(
        graphqlOperation(updateRecord, {
          input: { id, isFalseAlarm }
        })
      )
      dispatch(
        updateNotification({
          notificationId: id,
          updated: { isFalseAlarm }
        })
      )
      message.success("Report success")

      // TODO: if update succeeded, update the local fall record
    } catch (error) {
      console.error(error)
      message.warn("Report failed")
    }
  }

  async function handOver(id: string, nurseId: string) {
    try {
      const response: any = await API.graphql(
        graphqlOperation(updateRecord, {
          input: { id, handoverNurseId: nurseId }
        })
      )
      console.log("data:", response.data.updateRecord)

      dispatch(
        updateNotification({
          notificationId: id,
          updated: {
            handoverNurseId: nurseId,
            handoverNurse: response.data.updateRecord.handoverNurse
          }
        })
      )

      message.success("handOver success")

      // TODO: if update succeeded, update the local fall record
    } catch (error) {
      console.error(error)
      message.warning(error.message)
    }
  }

  async function setOnMyWay(id: string) {
    try {
      const response: any = await API.graphql(
        graphqlOperation(updateRecord, {
          input: { id, status: "Processing" }
        })
      )
      dispatch(
        updateNotification({
          notificationId: id,
          updated: { status: "Processing" }
        })
      )

      message.success("Notification processing")

      // TODO: if update succeeded, update the local fall record
    } catch (error) {
      console.error(error)
      message.warning(error.message)
    }
  }

  return {
    notifications,
    updateNotification,
    processNotification,
    setOnMyWay,
    handOver,
    setIsFalseAlarm
  }
}

export default useNotification
