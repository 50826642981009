import { API, graphqlOperation } from "aws-amplify"
import { useDispatch, useSelector } from "react-redux"
import { updateRecord } from "../graphql/mutations"
import { searchRecords } from "../graphql/queries"
import { fallDetected, fallProcessed, fetchFalls } from "../redux/Falls/actions"
import { isAdmin, isNurse, isDoctor, isFamily } from "../util/authHelper"
import useCurrentUser from "./useCurrentUser"

const allAlertTypes = ["fall", "getup", "predict"]

const defaultFilter = {
  selectedpatient: null,
  selectedRooms: null,
  selectedAlertTypes: allAlertTypes,
  createdAt: { gt: null, lt: null }
}

const useRecords = () => {
  const { currentUser } = useCurrentUser()

  const { unprocessedFalls, allFalls } = useSelector(
    (state: any) => state.Falls
  )

  const dispatch = useDispatch()

  const loadRecord = async () => {
    if (currentUser.username) {
      if (isAdmin(currentUser)) {
        fetchFallByDoctor({
          nextToken: "",
          isExtend: false,
          filter: {}
        })
      } else if (isNurse(currentUser)) {
        fetchFallByDoctor({
          nextToken: "",
          isExtend: false,
          filter: {}
        })
      } else if (isDoctor(currentUser)) {
        fetchFallByDoctor({
          id: currentUser.username,
          nextToken: "",
          isExtend: false,
          filter: {}
        })
      } else if (isFamily(currentUser)) {
        fetchRecordByPatient({
          patientId: currentUser.familyMemberPatientId,
          nextToken: "",
          isExtend: false,
          filter: {}
        })
      }
    }
  }

  async function fetchFallByDoctor({
    limit = 100,
    nextToken,
    id,
    callback,
    isExtend = false,
    filter
  }: {
    limit?: number
    nextToken?: string
    id?: string
    callback?: () => void
    isExtend?: boolean
    filter?: any
  }) {
    try {
      const response: any = await API.graphql(
        graphqlOperation(searchRecords, {
          filter:
            (id || JSON.stringify(filter) !== "{}")
              ? {
                  recordDoctorId: id ? { eq: id } : undefined,
                  ...filter
                }
              : undefined,
          sort: {
            direction: "desc",
            field: "createdAt"
          },
          limit,
          nextToken: isExtend && nextToken ? nextToken : null
        })
      )
      // const { items, nextToken: newToken, total } = response.data.searchRecords;
      if (response.data) {
        dispatch(fetchFalls(response.data.searchRecords, isExtend))
      }
    } catch (error) {
      console.error(error)
    } finally {
      callback && callback()
    }
  }

  async function fetchRecordByPatient({
    limit = 100,
    nextToken,
    patientId,
    callback,
    isExtend = false,
    filter
  }: {
    limit?: number
    nextToken?: string
    patientId?: string
    callback?: () => void
    isExtend?: boolean
    filter?: any
  }) {
    try {
      const response: any = await API.graphql(
        graphqlOperation(searchRecords, {
          filter:
            (patientId || JSON.stringify(filter) !== "{}")
              ? {
                recordPatientId: patientId ? { eq: patientId } : undefined,
                  ...filter
                }
              : undefined,
          sort: {
            direction: "desc",
            field: "createdAt"
          },
          limit,
          nextToken: isExtend && nextToken ? nextToken : null
        })
      )
      // const { items, nextToken: newToken, total } = response.data.searchRecords;
      if (response.data) {
        console.log("fetch record by patient id: ", response.data)
        dispatch(fetchFalls(response.data.searchRecords, isExtend))
      }
    } catch (error) {
      console.error(error)
    } finally {
      callback && callback()
    }
  }


  function receiveAlert(record: any) {
    dispatch(fallDetected({ fall: record }))
  }

  function processAlert(id: string) {
    dispatch(fallProcessed({ fallId: id }))
  }

  async function setIsFalseAlarm(id: string, isFalseAlarm: boolean = true) {
    try {
      const response: any = await API.graphql(
        graphqlOperation(updateRecord, {
          input: { id, isFalseAlarm }
        })
      )

      // TODO: if update succeeded, update the local fall record
    } catch (error) {
      console.error(error)
    }
  }

  return {
    allFalls,
    unprocessedFalls,
    loadRecord,
    fetchFallByDoctor,
    receiveAlert,
    processAlert,
    setIsFalseAlarm,
    fetchRecordByPatient
  }
}

export default useRecords
