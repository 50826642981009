import actions from "./actions"
import { Action } from "../../models/General"
import { Fall } from "../../models/Fall"
const initState = {
  unprocessedFalls: [],
  allFalls: {
    items: [],
    nextToken: "",
    total: 0
  }
}

const fallReducer = (state = initState, { type, ...action }: Action) => {
  switch (type) {
    case actions.FALL_DETECTED:
      return {
        ...state,
        unprocessedFalls: [action.payload.fall, ...state.unprocessedFalls]
        // allFalls: [action.payload.fall, ...state.allFalls],
      }
    case actions.FALL_PROCESSED:
      return {
        ...state,
        unprocessedFalls: state.unprocessedFalls.filter(
          (f: Fall) => f.id !== action.payload.fallId
        )
      }
    case actions.FETCH_FALL_HISTORY:
      return {
        ...state,
        allFalls: action.payload.isExtend
          ? {
              items: [...state.allFalls.items, ...action.payload.falls.items],
              nextToken: action.payload.falls.nextToken,
              total: action.payload.falls.total + state.allFalls.total
            }
          : action.payload.falls
      }
    default:
      return state
  }
}

export default fallReducer
