import { Tag } from "antd"
import { getBadgeColor, getBadgeBgColor, getBadgeTextColor, getBadgeDescription } from "../util"

type props = {
  item: Partial<{
    riskCoefficient: number
  }>
}

const RiskLevelBadge = ({ item }: props) => {
  return (
    <Tag 
      color={getBadgeBgColor(item)} 
      style={{
        color: getBadgeTextColor(item),
        borderWidth: 1,
        borderColor: getBadgeColor(item)
      }}>
      {getBadgeDescription(item)}
    </Tag>
  )
}

export default RiskLevelBadge
