import React from "react"

interface IndicatorProp {
  x: number
  y: number
}

const Indicator: React.FC<IndicatorProp> = ({ x, y }) => {
  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: "50%",
        padding: 4,
        position: "absolute",
        bottom: x * 92 - 8.5,
        left: y * 92 - 8.5
      }}
    >
      <div
        style={{
          backgroundColor: "blue",
          borderRadius: "50%",
          width: 15,
          height: 15,
          textAlign: "center",
          display: "flex",
          justifyContent: "center"
        }}
      >
      </div>
    </div>
  )
}

export default Indicator
