import React from "react"
import { Route, Switch, useRouteMatch } from "react-router-dom"
import { Layout } from "antd"
import NavSidebar from "./NavSidebar"
import DashboardHeader from "../components/DashboardHeader"
import Monitor from "../containers/CCTVMonitor"
import StaffManagementContainer from "../containers/StaffManagement"
import FallRecordList from "../containers/FallRecords"
import Patients from "../containers/Patients"
import DeviceManagement from "../containers/DeviceManagement"
import useCurrentUser from "../hooks/useCurrentUser"
import Notifications from "../containers/Notifications"
import Settings from "../containers/Settings"
import SubscribeSuccessPage from "./SubscribeSuccessPage"
import SubscribeFailPage from "./SubscribeFailPage"

const { Content, Footer, Sider } = Layout

const Dashboard: React.FC = () => {
  let match = useRouteMatch()
  const { currentUser } = useCurrentUser()
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider style={{background: "linear-gradient(44deg, #8EDDF5 0%, #23499B 100%)"}}>
        <NavSidebar />
      </Sider>
      <Layout className="site-layout">
        <DashboardHeader />
        <Content style={{ margin: "10px 16px" }}>
          <div
            className="site-layout-background"
            style={{ padding: 24, minHeight: 360 }}
          >
            <Switch>
              <Route path={`${match.url}/cctv`} component={Monitor} />
              <Route
                path={`${match.url}/notifications`}
                component={Notifications}
              />
              <Route path={`${match.url}/records`} component={FallRecordList} />
              <Route path={`${match.url}/patients`} component={Patients} />
              {currentUser.type === "Admin" && (
                <Route
                  path={`${match.url}/users`}
                  component={StaffManagementContainer}
                />
              )}
              {currentUser.type === "Admin" && (
                <Route
                  path={`${match.url}/device`}
                  component={DeviceManagement}
                />
              )}
              {(currentUser.type === "Admin" || currentUser.type === "Family") && (
                <>
                <Route
                  path={`${match.url}/settings`}
                  component={Settings}
                />
                <Route path={`${match.url}/subscribeSuccess`} component={SubscribeSuccessPage} />
                <Route path={`${match.url}/subscribeFail`} component={SubscribeFailPage} />
                </>
              )}
            </Switch>
          </div>
        </Content>
        <Footer style={{ textAlign: "center" }}>AiBuild © 2021</Footer>
      </Layout>
    </Layout>
  )
}

export default Dashboard
