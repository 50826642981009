import { API } from "aws-amplify"
import React, { useEffect, useState } from "react"
import { List, Button, PageHeader, Popconfirm, Descriptions, Table, Typography, Tabs } from "antd"
import {loadStripe} from '@stripe/stripe-js'
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js'
import { stripePublishKey } from "../config"
import useCurrentUser from "../hooks/useCurrentUser"
import { Link } from "react-router-dom"
import useFamilyMembers from "../hooks/useFamilyMembers"
import moment from "moment"

const stripePromise = loadStripe(stripePublishKey)
const apiName = "CaptureFallRest"
const apiEndpoint = "/checkout/subscribe"
// const name = "Test"
// const description = "Test stripe api"
// const amount = 550
// const currency = "aud"
const quantity = 1
const priceid = "price_1JUMRrCylesAzCcBt3RuMlRC"
const success_url = "https://stream.capture.live/#/dashboard/subscribeSuccess"
const cancel_url = "https://stream.capture.live/#/dashboard/subscribeFail"

const { TabPane } = Tabs

// Example
const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();

    if (elements == null) {
      return;
    }

    //@ts-ignore
    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      //@ts-ignore
      card: elements.getElement(CardElement),
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <button type="submit" disabled={!stripe || !elements}>
        Pay
      </button>
    </form>
  );
};

const Settings: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [mode, setMode] = useState<"create" | "edit">("create")
  const [modalVisibility, setModalVisibility] = useState<boolean>(false)
  const { currentUser } = useCurrentUser()
  const {  
    familyMembers,
    currentMember,
    fetchFamilyMemberList,
    fetchSingleFamilyMember,
    setFamilyMember
  } = useFamilyMembers()

  useEffect(() => {
    fetchFamilyMemberList({ callback: () => {
      // To do: need to add some data field to check if the current user is subscribed or not
      const email = currentUser.email
      const familyMember = familyMembers.items.filter((i:any) => { return i.email === email })
      console.log("filter family member: ", familyMember)
      console.log(familyMember.length)
      if (familyMember.length > 0) {
        setFamilyMember(familyMember[0])
      } else {
        setFamilyMember({ email: currentUser.email, isSubscribed: false })
      }
    }})
  }, [])

  function callback(key: any) {
    //console.log(key);
  }

  const SettingTabs = () => (
    <Tabs defaultActiveKey="1" onChange={callback} style={{ margin: 20 }}>
      <TabPane tab="Subscription" key="1">
        <div>
          <Typography style={{ marginBottom: 20 }}>Subscription Status: {currentMember.data.isSubscribed ? "Subscribed" : "No Subscription"}</Typography>
          {
            currentMember.data.isSubscribed ? <Typography style={{ marginBottom: 20 }}>Subscription Expiry Date: {moment(currentMember.data.subscriptionExpiry).format("MMMM Do YYYY")}</Typography> : null
          }
          <Elements stripe={stripePromise}>
            <PayButton />
          </Elements>
        </div>
      </TabPane>
      <TabPane tab="Others" key="2">
        {/* Other Settings */}
        <Button 
          type="primary"
          color="secondary"
        > 
          <Link
            to={"/dashboard/subscribeSuccess"}
          >
            Test subscribe return page
          </Link>
        </Button>
      </TabPane>
    </Tabs>
  );
  
  const handlePay = async () => {
    const body = {
        userid: currentUser.username,
        quantity: quantity,
        priceid: priceid,
        success_url: success_url,
        cancel_url: cancel_url,
        // name: name,
        // description: description,
        //images: images,
        // amount: amount,
        // currency: currency,
    }
    // Make the request
    const response = await API.post(apiName, apiEndpoint, { body })
    console.log("Response: ", response)
    // Redirect the user to the checkout session
    // {sessionId: response.session.id}
    const session = response.session
    console.log("Stripe Promise: ", stripePromise)
    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripePromise
    if(result) {
      console.log("Result from the server: ", result)
      const redirect = await result.redirectToCheckout({
        sessionId: session.id,
      })
      if(redirect.error) {
        console.log(redirect.error.message)
      }
    } 
  }
  
  const PayButton = () => {
    return (
      <Button 
        type="primary"
        onClick={handlePay} 
        //disabled={loading} 
        //variant="contained" 
        color="secondary"
      > 
        Subscribe
      </Button>
    )
  }

  return (
    <div style={{ backgroundColor: "white", borderRadius: "10px", width: "100%", height: 800 }}>
      <PageHeader
        ghost={false}
        // onBack={() => window.history.back()}
        style={{ borderRadius: "10px" }}
        title="Settings"
      />
      <SettingTabs />
    </div>
  )
}

export default Settings
