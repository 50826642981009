import { Action } from "../../models/General"

const actions = {
  LIST_STAFF: "LIST_STAFF",
  ADD_STAFF: "ADD_STAFF",
  UPDATE_STAFF: "UPDATE_STAFF",
  FETCH_DETAIL: "FETCH_DETAIL",
  DELETE_STAFF: "DELETE_STAFF"
}

export function getStaffList(props: any): Action {
  return { type: actions.LIST_STAFF, payload: props }
}

export function addStaff(props: any): Action {
  return { type: actions.ADD_STAFF, payload: props }
}

export function modifyStaff(props: any): Action {
  return { type: actions.UPDATE_STAFF, payload: props }
}

export function getSingleStaffDetail(props: any): Action {
  return { type: actions.FETCH_DETAIL, payload: props }
}

export function deleteCurrentStaff(props: {
  staffType: "doctors" | "nurses"
  id: string
}): Action {
  return { type: actions.DELETE_STAFF, payload: props }
}

export default actions
