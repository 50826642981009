import { Action } from "../../models/General"

const actions = {
  LIST_FAMILY_MEMBERS: "LIST_FAMILY_MEMBERS",
  GET_FAMILY_MEMBER_DETAIL: "GET_FAMILY_MEMBER_DETAIL",
  SET_CURRENT_FAMILY_MEMBER: "SET_CURRENT_FAMILY_MEMBER",
  UPDATE_CURRENT_FAMILY_MEMBER: "UPDATE_CURRENT_FAMILY_MEMBER",
  CREATE_FAMILY_MEMBER: "CREATE_FAMILY_MEMBER"
}

export function getFamilyMemberList(props: any, isExtend?: boolean): Action {
  return { type: actions.LIST_FAMILY_MEMBERS, payload: { props, isExtend } }
}

export function getFamilyMemberDetail(props: any): Action {
  return { type: actions.GET_FAMILY_MEMBER_DETAIL, payload: props }
}

export function setCurrentFamilyMember(props: any): Action {
  return { type: actions.SET_CURRENT_FAMILY_MEMBER, payload: props }
}

export function addNewFamilyMember(props: any): Action {
  return { type: actions.CREATE_FAMILY_MEMBER, payload: props }
}

export function modifyFamilyMember(props: any): Action {
  return { type: actions.UPDATE_CURRENT_FAMILY_MEMBER, payload: props }
}

export default actions
