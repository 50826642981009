/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listPatients = /* GraphQL */ `
  query ListPatients(
    $filter: ModelPatientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPatients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        avatar
        gender
        building
        room
        yearOfBirth
        activityLevel
        patientDoctorId
        patientNurseId
        dangerDegree
        organisationId
        status
        photo
        likelihood
        consequence
        riskCoefficient
        createdAt
        updatedAt
        doctor {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        nurse {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        family {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getPatient = /* GraphQL */ `
  query GetPatient($id: ID!) {
    getPatient(id: $id) {
      id
      name
      avatar
      gender
      building
      room
      yearOfBirth
      activityLevel
      patientDoctorId
      patientNurseId
      dangerDegree
      organisationId
      status
      photo
      likelihood
      consequence
      riskCoefficient
      createdAt
      updatedAt
      doctor {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      nurse {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        
        device {
          nextToken
        }
      }
      family {
        items {
          id
          name
          avatar
          email
          phone
          gender
          relationship
          pushToken
          pushTokens
          prefer
          familyMemberPatientId
          createdAt
          updatedAt
        }
        nextToken
      }
      records {
        items {
          id
          recordDoctorId
          recordNurseId
          recordPatientId
          position
          video
          status
          isFalseAlarm
          type
          handoverNurseId
          createdAt
          updatedAt
        }
        nextToken
      }
      device {
        items {
          id
          deviceId
          deviceDoctorId
          devicePatientId
          deviceNurseId
          organisationId
          status
          position
          input
          output
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const patientByName = /* GraphQL */ `
  query PatientByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelPatientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    patientByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        avatar
        gender
        building
        room
        yearOfBirth
        activityLevel
        patientDoctorId
        patientNurseId
        dangerDegree
        organisationId
        status
        photo
        likelihood
        consequence
        riskCoefficient
        createdAt
        updatedAt
        doctor {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        nurse {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        family {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const searchPatients = /* GraphQL */ `
  query SearchPatients(
    $filter: SearchablePatientFilterInput
    $sort: SearchablePatientSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchPatients(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        name
        avatar
        gender
        building
        room
        yearOfBirth
        activityLevel
        patientDoctorId
        patientNurseId
        dangerDegree
        organisationId
        status
        photo
        likelihood
        consequence
        riskCoefficient
        createdAt
        updatedAt
        doctor {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        nurse {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        family {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      nextToken
      total
    }
  }
`;
export const listDoctors = /* GraphQL */ `
  query ListDoctors(
    $filter: ModelDoctorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDoctors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getDoctor = /* GraphQL */ `
  query GetDoctor($id: ID!) {
    getDoctor(id: $id) {
      id
      name
      avatar
      email
      phone
      pushToken
      pushTokens
      organisationId
      createdAt
      updatedAt
      patient {
        items {
          id
          name
          avatar
          gender
          building
          room
          yearOfBirth
          activityLevel
          patientDoctorId
          patientNurseId
          dangerDegree
          organisationId
          status
          photo
          likelihood
          consequence
          riskCoefficient
          createdAt
          updatedAt
        }
        nextToken
      }
      records {
        items {
          id
          recordDoctorId
          recordNurseId
          recordPatientId
          position
          video
          status
          isFalseAlarm
          type
          handoverNurseId
          createdAt
          updatedAt
        }
        nextToken
      }
      device {
        items {
          id
          deviceId
          deviceDoctorId
          devicePatientId
          deviceNurseId
          organisationId
          status
          position
          input
          output
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const doctorByName = /* GraphQL */ `
  query DoctorByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelDoctorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    doctorByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const searchDoctors = /* GraphQL */ `
  query SearchDoctors(
    $filter: SearchableDoctorFilterInput
    $sort: SearchableDoctorSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchDoctors(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      nextToken
      total
    }
  }
`;
export const listNurses = /* GraphQL */ `
  query ListNurses(
    $filter: ModelNurseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNurses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        
        device {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getNurse = /* GraphQL */ `
  query GetNurse($id: ID!) {
    getNurse(id: $id) {
      id
      name
      avatar
      email
      phone
      pushToken
      pushTokens
      organisationId
      createdAt
      updatedAt
      patient {
        items {
          id
          name
          avatar
          gender
          building
          room
          yearOfBirth
          activityLevel
          patientDoctorId
          patientNurseId
          dangerDegree
          organisationId
          status
          photo
          likelihood
          consequence
          riskCoefficient
          createdAt
          updatedAt
        }
        nextToken
      }
      records {
        items {
          id
          recordDoctorId
          recordNurseId
          recordPatientId
          position
          video
          status
          isFalseAlarm
          type
          handoverNurseId
          createdAt
          updatedAt
        }
        nextToken
      }
      
      device {
        items {
          id
          deviceId
          deviceDoctorId
          devicePatientId
          deviceNurseId
          organisationId
          status
          position
          input
          output
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const getMember = /* GraphQL */ `
  query GetMember($id: ID!) {
    getMember(id: $id) {
      id
      name
      avatar
      email
      phone
      gender
      relationship
      pushToken
      pushTokens
      prefer
      familyMemberPatientId
      createdAt
      updatedAt
      patient {
        id
        name
        avatar
        gender
        building
        room
        yearOfBirth
        activityLevel
        patientDoctorId
        patientNurseId
        dangerDegree
        organisationId
        status
        photo
        likelihood
        consequence
        riskCoefficient
        createdAt
        updatedAt
        # doctor {
        #   id
        #   name
        #   avatar
        #   email
        #   phone
        #   pushToken
        #   pushTokens
        #   organisationId
        #   createdAt
        #   updatedAt
        # }
        # nurse {
        #   id
        #   name
        #   avatar
        #   email
        #   phone
        #   pushToken
        #   pushTokens
        #   organisationId
        #   createdAt
        #   updatedAt
        # }
        family {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
    }
  }
`;
export const listMember = /* GraphQL */ `
  query ListMember(
    $filter: ModelFamilyMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMember(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        avatar
        email
        phone
        gender
        relationship
        pushToken
        pushTokens
        prefer
        familyMemberPatientId
        stripeCustomerId
        isSubscribed
        subscriptionExpiry
        createdAt
        updatedAt
        patient {
          id
          name
          avatar
          gender
          building
          room
          yearOfBirth
          activityLevel
          patientDoctorId
          patientNurseId
          dangerDegree
          organisationId
          status
          photo
          likelihood
          consequence
          riskCoefficient
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getPatientDoctorRelation = /* GraphQL */ `
  query GetPatientDoctorRelation($id: ID!) {
    getPatientDoctorRelation(id: $id) {
      id
      doctorId
      patientId
      createdAt
      updatedAt
      patient {
        id
        name
        avatar
        gender
        building
        room
        yearOfBirth
        activityLevel
        patientDoctorId
        patientNurseId
        dangerDegree
        organisationId
        status
        photo
        likelihood
        consequence
        riskCoefficient
        createdAt
        updatedAt
        doctor {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        nurse {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        family {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      doctor {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
    }
  }
`;
export const listPatientDoctorRelations = /* GraphQL */ `
  query ListPatientDoctorRelations(
    $filter: ModelPatientDoctorRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPatientDoctorRelations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        doctorId
        patientId
        createdAt
        updatedAt
        patient {
          id
          name
          avatar
          gender
          building
          room
          yearOfBirth
          activityLevel
          patientDoctorId
          patientNurseId
          dangerDegree
          organisationId
          status
          photo
          likelihood
          consequence
          riskCoefficient
          createdAt
          updatedAt
        }
        doctor {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const relationByDoctor = /* GraphQL */ `
  query RelationByDoctor(
    $doctorId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPatientDoctorRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    relationByDoctor(
      doctorId: $doctorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        doctorId
        patientId
        createdAt
        updatedAt
        patient {
          id
          name
          avatar
          gender
          building
          room
          yearOfBirth
          activityLevel
          patientDoctorId
          patientNurseId
          dangerDegree
          organisationId
          status
          photo
          likelihood
          consequence
          riskCoefficient
          createdAt
          updatedAt
        }
        doctor {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const relationByPatient = /* GraphQL */ `
  query RelationByPatient(
    $patientId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPatientDoctorRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    relationByPatient(
      patientId: $patientId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        doctorId
        patientId
        createdAt
        updatedAt
        patient {
          id
          name
          avatar
          gender
          building
          room
          yearOfBirth
          activityLevel
          patientDoctorId
          patientNurseId
          dangerDegree
          organisationId
          status
          photo
          likelihood
          consequence
          riskCoefficient
          createdAt
          updatedAt
        }
        doctor {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getAdmin = /* GraphQL */ `
  query GetAdmin($id: ID!) {
    getAdmin(id: $id) {
      id
      name
      avatar
      email
      phone
      pushToken
      pushTokens
      organisationId
      createdAt
      updatedAt
      organisation {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
`;
export const listAdmins = /* GraphQL */ `
  query ListAdmins(
    $filter: ModelAdminFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdmins(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        organisation {
          id
          name
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listOrganisations = /* GraphQL */ `
  query ListOrganisations(
    $filter: ModelOrganisationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganisations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrganisation = /* GraphQL */ `
  query GetOrganisation($id: ID!) {
    getOrganisation(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const getRecord = /* GraphQL */ `
  query GetRecord($id: ID!) {
    getRecord(id: $id) {
      id
      recordDoctorId
      recordNurseId
      recordPatientId
      position
      alertStatus {
        id
        name
        confirm
      }
      video
      status
      isFalseAlarm
      type
      handoverNurseId
      createdAt
      updatedAt
      patient {
        id
        name
        avatar
        gender
        building
        room
        yearOfBirth
        activityLevel
        patientDoctorId
        patientNurseId
        dangerDegree
        organisationId
        status
        photo
        likelihood
        consequence
        riskCoefficient
        createdAt
        updatedAt
        doctor {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        nurse {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        family {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      doctor {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      nurse {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        
        device {
          nextToken
        }
      }
      handoverNurse {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        
        device {
          nextToken
        }
      }
    }
  }
`;
export const listRecords = /* GraphQL */ `
  query ListRecords(
    $filter: ModelRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecords(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        recordDoctorId
        recordNurseId
        recordPatientId
        position
        alertStatus {
          id
          name
          confirm
        }
        video
        status
        isFalseAlarm
        type
        handoverNurseId
        createdAt
        updatedAt
        patient {
          id
          name
          avatar
          gender
          building
          room
          yearOfBirth
          activityLevel
          patientDoctorId
          patientNurseId
          dangerDegree
          organisationId
          status
          photo
          likelihood
          consequence
          riskCoefficient
          createdAt
          updatedAt
        }
        doctor {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        nurse {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        handoverNurse {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const searchRecords = /* GraphQL */ `
  query SearchRecords(
    $filter: SearchableRecordFilterInput
    $sort: SearchableRecordSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchRecords(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        recordDoctorId
        recordNurseId
        recordPatientId
        position
        alertStatus {
          id
          name
          confirm
        }
        video
        status
        isFalseAlarm
        type
        handoverNurseId
        createdAt
        updatedAt
        patient {
          id
          name
          avatar
          gender
          building
          room
          yearOfBirth
          activityLevel
          patientDoctorId
          patientNurseId
          dangerDegree
          organisationId
          status
          photo
          likelihood
          consequence
          riskCoefficient
          createdAt
          updatedAt
        }
        doctor {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        nurse {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        handoverNurse {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
      }
      nextToken
      total
    }
  }
`;
export const getRiskRecord = /* GraphQL */ `
  query GetRiskRecord($patientId: ID!, $createdAt: AWSDateTime!) {
    getRiskRecord(patientId: $patientId, createdAt: $createdAt) {
      patientId
      likelihoood
      consequence
      riskCoefficient
      createdAt
      updatedAt
    }
  }
`;
export const listRiskRecords = /* GraphQL */ `
  query ListRiskRecords(
    $patientId: ID
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelRiskRecordFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRiskRecords(
      patientId: $patientId
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        patientId
        likelihoood
        consequence
        riskCoefficient
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDevice = /* GraphQL */ `
  query GetDevice($id: ID!) {
    getDevice(id: $id) {
      id
      deviceId
      deviceDoctorId
      devicePatientId
      deviceNurseId
      organisationId
      status
      position
      input
      output
      deviceSettings {
        showPatientOutline
        showPatientNameAndRoom
      }
      createdAt
      updatedAt
      patient {
        id
        name
        avatar
        gender
        building
        room
        yearOfBirth
        activityLevel
        patientDoctorId
        patientNurseId
        dangerDegree
        organisationId
        status
        photo
        likelihood
        consequence
        riskCoefficient
        createdAt
        updatedAt
        doctor {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        nurse {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        family {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      doctor {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      nurse {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        
        device {
          nextToken
        }
      }
      organisation {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
`;
export const listDevices = /* GraphQL */ `
  query ListDevices(
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        deviceId
        deviceDoctorId
        devicePatientId
        deviceNurseId
        organisationId
        status
        position
        input
        output
        deviceSettings {
          showPatientOutline
          showPatientNameAndRoom
        }
        createdAt
        updatedAt
        patient {
          id
          name
          avatar
          gender
          building
          room
          yearOfBirth
          activityLevel
          patientDoctorId
          patientNurseId
          dangerDegree
          organisationId
          status
          photo
          likelihood
          consequence
          riskCoefficient
          createdAt
          updatedAt
        }
        doctor {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        nurse {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        organisation {
          id
          name
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getDeviceSettings = /* GraphQL */ `
  query GetDeviceSettings($id: ID!) {
    getDeviceSettings(id: $id) {
      id
      showPatientOutline
      showPatientNameAndRoom
      cameraType
      createdAt
      updatedAt
    }
  }
`;
export const listDeviceSettingss = /* GraphQL */ `
  query ListDeviceSettingss(
    $filter: ModelDeviceSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeviceSettingss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        showPatientOutline
        showPatientNameAndRoom
        cameraType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRestrictedRegion = /* GraphQL */ `
  query GetRestrictedRegion($id: ID!) {
    getRestrictedRegion(id: $id) {
      id
      deviceId
      name
      points
      type
      createdAt
      updatedAt
    }
  }
`;
export const listRestrictedRegions = /* GraphQL */ `
  query ListRestrictedRegions(
    $filter: ModelRestrictedRegionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRestrictedRegions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deviceId
        name
        points
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getImageRequest = /* GraphQL */ `
  query GetImageRequest($id: ID!) {
    getImageRequest(id: $id) {
      id
      imageUrl
      requestedAt
      createdAt
      updatedAt
    }
  }
`;
export const listImageRequests = /* GraphQL */ `
  query ListImageRequests(
    $filter: ModelImageRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listImageRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        imageUrl
        requestedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
