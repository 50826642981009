import React from "react"

interface GridProp {
  x: number
  y: number
}

export const getBackgroundColor = (x: number, y: number) => {
  const result = x * y
  if (result >= 15) return "#E53E3E"
  if (result >= 8) return "#ED8936"
  if (result >= 4) return "#F7C948"
  return "#81E0A9"
}

const Grid: React.FC<GridProp> = ({ x, y }) => {
  return (
    <div
      style={{ backgroundColor: "white", padding: 2, display: "table-cell" }}
    >
      <div
        style={{
          backgroundColor: getBackgroundColor(x, y),
          padding: 40
        }}
      >
        <div
          style={{
            width: 10,
            height: 10,
            textAlign: "center",
            display: "flex",
            justifyContent: "center"
          }}
        >
          <span style={{ lineHeight: 1 }}>{x * y}</span>
        </div>
      </div>
    </div>
  )
}

export default Grid
