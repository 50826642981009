import { useSelector, useDispatch } from "react-redux"
import { listDevices, getDevice, listRestrictedRegions, listImageRequests, getRestrictedRegion, getImageRequest } from "../graphql/queries"
import { createDevice, updateDevice, deleteDevice, createImageRequest, createRestrictedRegion, deleteRestrictedRegion, updateRestrictedRegion, updateImageRequest } from "../graphql/mutations"
import { API, graphqlOperation } from "aws-amplify"
import {
  getSingleDevice,
  fetchDevices,
  addNewDevice,
  modifyDevice,
  removeDevice,
  fetchRegions,
  fetchImages,
  createNewRegion,
  createNewImage,
  removeRegion,
  modifyRegion,
  setCurrentRegion,
  setCurrentImage
} from "../redux/Device/actions"

const useDevices = () => {

  const { devices, singleDevice, regions, images, currentRegion, currentImage } = useSelector((state: any) => state.Device)
  const { nextToken } = devices
  const dispatch = useDispatch()

  async function getDevices(isExtend: boolean, callback?: () => void) {
    try {
      const response: any = await API.graphql(graphqlOperation(listDevices, {
        limit: 10,
        nextToken: isExtend ? nextToken : null
      }))
      console.log("device response: ", response)
      dispatch(fetchDevices(response.data.listDevices, isExtend))
    } catch (error) {
      console.error(error)
    } finally {
      callback && callback()
    }
  }

  async function createNewDevice(props: any, callback?: () => void) {
    try {
      const response: any = await API.graphql(graphqlOperation(createDevice, {
        input: props
      }));
      console.log(response)
      dispatch(addNewDevice(response.data.createDevice))
    } catch (error) {
      console.error(error)
    } finally {
      callback && callback()
    }
  }

  async function fetchSingleDevice(id: string, callback?: () => void) {
    try {
      const response: any = await API.graphql(graphqlOperation(getDevice, { id }))
      dispatch(getSingleDevice(response.data.getDevice))
    } catch (error) {
      console.error(error)
    } finally {
      callback && callback()
    }
  } 

  async function updateSingleDevice(props: any, callback?: () => void) {
    try {
      const response: any = await API.graphql(graphqlOperation(updateDevice, {
        input: props
      }))
      dispatch(modifyDevice(response.data.updateDevice))
    } catch (error) {
      console.error(error)
    } finally {
      callback && callback()
    }
  }

  async function deleteSingleDevice(id: string, callback?: () => void) {
    try {
      const response: any = await API.graphql(graphqlOperation(deleteDevice, {
        input: { id }
      }))
      console.log(response)
      dispatch(removeDevice(id))
    } catch (error) {
      console.error(error)
    } finally {
      callback && callback()
    }
  }

  function resetDevice() {
    dispatch(getSingleDevice({}))
  }

  async function fetchRestrictedRegions(callback?: () => void) {
    try {
      const response: any = await API.graphql(graphqlOperation(listRestrictedRegions, {}))
      //console.log("Data return: ", response.data)
      dispatch(fetchRegions(response.data.listRestrictedRegions))
    } catch (error) {
      console.error(error)
    } finally {
      callback && callback()
    }
  }

  async function fetchImageRequests(callback?: () => void) {
    try {
      const response: any = await API.graphql(graphqlOperation(listImageRequests, {}))
      //console.log("Data return: ", response.data)
      dispatch(fetchImages(response.data.listImageRequests))
    } catch (error) {
      console.error(error)
    } finally {
      callback && callback()
    }
  }

  async function createNewRestrictedRegion(props: any, callback?: () => void) {
    try {
      const response: any = await API.graphql(graphqlOperation(createRestrictedRegion, {
        input: props
      }))
      console.log(response)
      dispatch(createNewRegion(response.data.createRestrictedRegion))
    } catch (error) {
      console.error(error)
    } finally {
      callback && callback()
    }
  }

  async function createNewImageRequest(props: any, callback?: () => void) {
    try {
      const response: any = await API.graphql(graphqlOperation(createImageRequest, {
        input: props
      }))
      console.log(response)
      dispatch(createNewImage(response.data.createImageRequest))
    } catch (error) {
      console.error(error)
    } finally {
      callback && callback()
    }
  }

  async function deleteRegion(id: string, callback?: () => void) {
    try {
      const response: any = await API.graphql(graphqlOperation(deleteRestrictedRegion, {
        input: { id }
      }))
      console.log(response)
      dispatch(removeRegion(id))
    } catch (error) {
      console.error(error)
    } finally {
      callback && callback()
    }
  }

  async function updateRegion(props: any, callback?: () => void) {
    try {
      const response: any = await API.graphql(graphqlOperation(updateRestrictedRegion, {
        input: props
      }))
      dispatch(modifyRegion(response.data.updateRestrictedRegion))
    } catch (error) {
      console.error(error)
    } finally {
      callback && callback()
    }
  }

  async function updateImage(props: any, callback?: () => void) {
    try {
      const response: any = await API.graphql(graphqlOperation(updateImageRequest, {
        input: props
      }))
      dispatch(modifyRegion(response.data.updateImageRequest))
    } catch (error) {
      console.error(error)
    } finally {
      callback && callback()
    }
  }

  function setRegion(props: any) {
    dispatch(setCurrentRegion(props))
  }

  function setImage(props: any) {
    dispatch(setCurrentImage(props))
  }

  return {
    devices,
    singleDevice,
    getDevices,
    fetchSingleDevice,
    updateSingleDevice,
    deleteSingleDevice,
    resetDevice,
    createNewDevice,
    fetchRestrictedRegions,
    fetchImageRequests,
    createNewRestrictedRegion,
    createNewImageRequest,
    images,
    regions,
    deleteRegion,
    updateRegion,
    setRegion,
    setImage,
    currentRegion,
    currentImage,
    updateImage
  }
}

export default useDevices
