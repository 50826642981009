import { Action } from "../../models/General"

const actions = {
  NOTIFICATION_PROCESSED: "NOTIFICATION_PROCESSED",
  NOTIFICATION_UPDATED: "NOTIFICATION_UPDATED"
}

export const processNotification = ({
  notificationId
}: {
  notificationId: string
}): Action => {
  return { type: actions.NOTIFICATION_PROCESSED, payload: { notificationId } }
}

export const updateNotification = ({
  notificationId,
  updated
}: {
  notificationId: string
  updated: any
}): Action => {
  return {
    type: actions.NOTIFICATION_UPDATED,
    payload: { notificationId, updated }
  }
}

export default actions
