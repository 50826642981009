import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { PageHeader, Row, Col, List, Modal, Button } from "antd"
import { ReloadOutlined } from "@ant-design/icons"
import FlvPlayer from "../components/FlvPlayer"
import useCurrentUser from "../hooks/useCurrentUser"
import useRecords from "../hooks/useRecords"
import NotificationAlertCard from "../components/NotificationAlertCard"
import NotificationMonitorCard from "../components/NotificationMonitorCard"
import { SERVER_ADDRESS } from "../config"
import WebRTCMonitorCard from "../components/WebRTC/WebRTCMonitorCard"
import WebRTCPlayer from "../components/WebRTC/WebRTCPlayer"

// const getVideoUrl = (item: any) => {
//   if (item.position === "Room 412") {
//     return `${SERVER_ADDRESS}/test.flv`
//   } else if (item.position === "Room 413") {
//     return `${SERVER_ADDRESS}/test2.flv`
//   }
// }

export default function Notifications() {
  const { notifications } = useSelector((state: any) => state.Notification)
  const { loadRecord, allFalls, unprocessedFalls } = useRecords()
  const { currentUser } = useCurrentUser()
  const [loading, setLoading] = useState<boolean>(false)
  const [monitorItem, setMonitorItem] = useState<any>(null)
  const [videoModal, setVideoModal] = useState<any>()

  const { items, total } = allFalls

  useEffect(() => {
    if (total === 0 && currentUser.username) {
      console.log("load first batch")
      loadRecord()
    } else if (allFalls.items.length > 0) {
      // Once loaded, open the first item
      setMonitorItem(orderByFallType(allFalls.items)[0])
    }
  }, [currentUser.username, total])

  useEffect(() => {
    if (unprocessedFalls.length > 0) {
      // The first item is always the latest
      setMonitorItem(unprocessedFalls[0]);
    }
  }, [unprocessedFalls])

  const orderByFallType = (items: any[]) => {
    if (!items || items.length === 0) {
      return []
    }
    const typeOrder = ['fall', 'fallpredict', 'getup', 'askforhelp']
    // Add filter to items - only display the latest records in two hours
    const filterResult = items.filter((record: any) => {
      let twoHoursBefore = new Date()
      twoHoursBefore.setHours(twoHoursBefore.getHours() - 2)
      return new Date(record.createdAt) >= twoHoursBefore
    })
    return filterResult.sort((a, b) => {
      let aTypeIndex = typeOrder.indexOf(a.type);
      // Place at last if the type is unknown
      if (aTypeIndex < 0) {
        aTypeIndex = 99;
      }
      let bTypeIndex = typeOrder.indexOf(b.type);
      // Place at last if the type is unknown
      if (bTypeIndex < 0) {
        bTypeIndex = 99;
      }
      if (aTypeIndex > bTypeIndex) {
        return 1
      } else if (aTypeIndex < bTypeIndex) {
        return -1
      } else {
        // Preserve the original order if the types are the same
        // in this case, order by createdAt, Descending
        return 0
      }
    })
  }

  const AlertList = (
    <List
      bordered={false}
      loading={loading}
      itemLayout="horizontal"
      dataSource={orderByFallType([...unprocessedFalls, ...items])}
      renderItem={(item: any) => {
        return (
          <List.Item
            onClick={() => {
              setMonitorItem(item)
              console.log("Notification Item: ", item)
            }}
            style={{
              paddingTop: 0,
              paddingBottom: 5,
              borderWidth: 0
            }}
          >
            <NotificationAlertCard item={item}></NotificationAlertCard>
          </List.Item>
        )
      }}
    />
  )

  // const openVideo = (item: any) => {
  //   setVideoModal(
  //     Modal.info({
  //       icon: null,
  //       title: "Video Player",
  //       width: 640,
  //       // height: 480,
  //       content: <FlvPlayer url={getVideoUrl(item)} type="flv" loop />
  //     })
  //   )
  // }

  return (
    <Row style={{ justifyContent: "space-between" }}>
      <Col
        span={11}
        style={{ backgroundColor: "white", borderRadius: "10px" }}
      >
        <PageHeader
          ghost={false}
          style={{ borderRadius: "10px" }}
          title="Alerts"
          extra={[
            <Button
              style={{ float: "right" }}
              key="refresh"
              type="primary"
              onClick={() => {
                loadRecord()
              }}
              shape="round"
            >
              <ReloadOutlined />
              Refresh
            </Button>
          ]}
        />
        {AlertList}
      </Col>
      <Col
        span={12}
        style={{ backgroundColor: "white", borderRadius: "10px" }}
      >
        <PageHeader
          ghost={false}
          style={{ borderRadius: "10px" }}
          title="CCTV"
        />
        <WebRTCPlayer item={monitorItem} />
        {/* <WebRTCMonitorCard item={monitorItem} withActionBar={false} /> */}
        {/* <NotificationMonitorCard
          item={monitorItem}
          openVideo={openVideo}
        /> */}
      </Col>
    </Row>
  )
}
