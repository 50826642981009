import actions from "./actions"
import { Action } from "../../models/General"

const initState = {
  doctors: {
    items: [],
    nextToken: null
  },
  nurses: {
    items: [],
    nextToken: null
  },
  currentStaff: {
    type: "",
    data: {}
  }
}

export default function staffReducer(
  state = initState,
  { type, ...action }: Action
) {
  switch (type) {
    case actions.LIST_STAFF: {
      const {
        dataType,
        data,
        isExtend
      }: {
        dataType: "doctors" | "nurses"
        data: { items: Array<any>; nextToken: string | null }
        isExtend: boolean
      } = action.payload
      return {
        ...state,
        [dataType]: isExtend
          ? {
              items: [...state[dataType].items, ...data.items],
              nextToken: data.nextToken
            }
          : data
      }
    }
    case actions.FETCH_DETAIL:
      return {
        ...state,
        currentStaff: action.payload
      }
    case actions.ADD_STAFF: {
      const {
        dataType,
        detail
      }: { dataType: "doctors" | "nurses"; detail: any } = action.payload
      return {
        ...state,
        [dataType]: {
          ...state[dataType],
          items: [detail, ...state[dataType].items]
        }
      }
    }
    case actions.UPDATE_STAFF: {
      const {
        dataType,
        detail
      }: { dataType: "doctors" | "nurses"; detail: any } = action.payload
      const newDetail = { ...state.currentStaff.data, ...detail }
      return {
        ...state,
        currentStaff: { ...state.currentStaff, data: newDetail },
        [dataType]: {
          ...state[dataType],
          items: state[dataType].items.map((staff: any) =>
            staff.id === newDetail.id ? newDetail : staff
          )
        }
      }
    }
    case actions.DELETE_STAFF: {
      const {
        staffType,
        id
      }: { staffType: "doctors" | "nurses"; id: string } = action.payload
      return {
        ...state,
        [staffType]: {
          ...state[staffType],
          items: state[staffType].items.filter((staff: any) => staff.id !== id)
        }
      }
    }

    default:
      return state
  }
}
