import { Fall } from "../../models/Fall"
import { Action } from "../../models/General"

const actions = {
  FALL_DETECTED: "FALL_DETECTED",
  FALL_PROCESSED: "FALL_PROCESSED",
  FETCH_FALL_HISTORY: "FETCH_FALL_HISTORY"
}

export const fallDetected = ({ fall }: { fall: Fall }): Action => {
  return { type: actions.FALL_DETECTED, payload: { fall } }
}

export const fallProcessed = ({ fallId }: { fallId: string }): Action => {
  return { type: actions.FALL_PROCESSED, payload: { fallId }}
}

export const fetchFalls = (falls: any, isExtend: boolean): Action => {
  return { type: actions.FETCH_FALL_HISTORY, payload: { falls, isExtend }}
}

export default actions
