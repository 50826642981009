import { List, Modal, Popconfirm } from "antd"
import { API, graphqlOperation } from "aws-amplify"
import { useEffect, useState } from "react"
import * as queries from "../graphql/queries"
import { CloseOutlined } from "@ant-design/icons"
import './HandOverModal.css'

export default function HandOverModal({
  visible,
  setVisible,
  onHandOver
}: any) {
  const [{ nurses, nextToken }, setState] = useState<any>({
    nurses: [],
    nextToken: null
  })

  const listNurse = async () => {
    ;(API.graphql(graphqlOperation(queries.listNurses, { nextToken })) as any)
      .then((result: any) => {
        //console.log(result.data.listNurses.items)

        setState((state: any) => ({
          nurses: nextToken
            ? [...state.nurses, ...result.data.listNurses.items]
            : result.data.listNurses.items,
          nextToken: result.data.listNurses.nextToken
        }))
      })
      .catch((error: any) => {
        console.log(error)
      })
  }

  useEffect(() => {
    ;(async () => {
      do {
        await listNurse()
      } while (nextToken !== null)
    })()
  }, [])

  return (
    <Modal
      title="Hand over to"
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      bodyStyle={{
        backgroundColor: "white",
        borderBottomRightRadius: 20,
        borderBottomLeftRadius: 20
      }}
      closeIcon={<CloseOutlined style={{ color: "white" }} />}
    >
      {/* {nurses.map((nurse: any) => (
        <div>{nurse.id}</div>
      ))} */}
      <List
        // header={<div>Header</div>}
        // footer={<div>Footer</div>}
        dataSource={nurses}
        rowKey={(item: any) => item.id}
        renderItem={(item: any) => (
          <Popconfirm
            title={`Are you sure to hand over to ${item.name}?`}
            onConfirm={() => {
              onHandOver(item.id)
            }}
            onCancel={() => null}
            okText="Yes"
            cancelText="No"
            style={{ borderRadius: 20 }}
          >
            <List.Item className="nurse-name">
              {item.name}
            </List.Item>
          </Popconfirm>
        )}
      />
    </Modal>
  )
}
