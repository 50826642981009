import { ReloadOutlined, SearchOutlined } from "@ant-design/icons"
import { Checkbox, DatePicker, Input, Modal, Select, Spin } from "antd"
import React, { useEffect, useReducer, useState } from "react"
import { useSelector } from "react-redux"
import FlvPlayer from "../components/FlvPlayer"
import MonitorCard from "../components/MonitorCard"
import useCurrentUser from "../hooks/useCurrentUser"
import useDevices from "../hooks/useDevices"
import useRecords from "../hooks/useRecords"
import { getFilePath } from "../util"
import { SERVER_ADDRESS, UserType } from "../config"
import WebRTCMonitorCard from "../components/WebRTC/WebRTCMonitorCard"
import useFamilyMembers from "../hooks/useFamilyMembers"
import useWindowDimensions from "../hooks/useWindowDimensions"

const alertOPtions = [
  // { label: "All", value: "all" },
  { label: "Get up Alert", value: "getup" },
  { label: "Fall Detection Alert", value: "fall" },
  { label: "Fall Prediction Alert", value: "predict" },
  { label: "Ask For Help Alert", value: "askforhelp" }
]

const allAlertTypes = ["fall", "getup", "predict"]

const Monitor: React.FC = () => {
  // eslint-disable-next-line
  const [videoModal, setVideoModal] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false)
  const { devices, getDevices } = useDevices()
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0)
  const [alertTypes, setAlertTypes] = React.useState<any>([])
  const [rooms, setRooms] = React.useState<Array<string>>([])
  const [name, setName] = React.useState("")
  const [positions, setPositions] = React.useState<any>([])

  const { notifications } = useSelector((state: any) => state.Notification)
  const { loadRecord, allFalls } = useRecords()
  const { currentUser } = useCurrentUser()
  const { height, width } = useWindowDimensions()
  const { familyMembers, fetchFamilyMemberList } = useFamilyMembers()

  const { total } = allFalls

  const onAlertTypesChanged = (checked: any) => {
    console.log("check", checked)

    setAlertTypes((pre: any) => {
      if (!pre.includes("all") && checked.includes("all")) {
        return ["all"]
      } else if (pre.includes("all") && checked.length > 1) {
        return checked.filter((_: any) => _ !== "all")
      } else {
        return checked
      }
    })
  }
  useEffect(() => {
    setPositions([
      ...new Set(
        notifications
          .filter((notification: any) => {
            if (alertTypes.includes("all")) {
              return true
            }
            return alertTypes.includes(notification.type)
          })
          .map((notification: any) => notification.position)
      )
    ])
  }, [notifications, alertTypes])
  useEffect(() => {
    setLoading(true)
    getDevices(false, () => {
      setLoading(false)
    })
    if (total === 0 && currentUser.username) {
      loadRecord()
    }
    // eslint-disable-next-line
  }, [currentUser.username])

  useEffect(() => {
    setLoading(true)
    getDevices(false, () => {
      setLoading(false)
      // fetchFamilyMemberList({ callback: () => {
      // }})
    })
    // eslint-disable-next-line
  }, [])

  const openVideo = (item: any) => {
    setVideoModal(
      Modal.info({
        icon: null,
        title: "Video Player",
        width: 640,
        // height: 480,
        content: (
          <FlvPlayer
            url={
              item.isFake
                ? getFilePath(item.videoUrl)
                : `${SERVER_ADDRESS}/${item.id}.flv`
            }
            type={item.isFake ? "mp4" : "flv"}
            loop
          />
        )
      })
    )
  }
  return (
    <Spin spinning={loading}>
      <div style={{ backgroundColor: "#fff", padding: 10, borderRadius: 10 }}>
        <div style={styles.searchBarContainer}>
          <Input
            placeholder="Patien name, ID..."
            style={styles.searchInput}
            value={name}
            onChange={(e) => setName(e.target.value)}
            suffix={<SearchOutlined style={styles.searchIcon} />}
          />
          <DatePicker
            showTime
            placeholder="Period from"
            style={styles.datePicker}
            onChange={(value: any, dateString: any) => {
              console.log("Selected Time: ", value)
              console.log("Formatted Selected Time: ", dateString)
            }}
            onOk={(value) => console.log("onOk: ", value)}
          />
          <Select
            showArrow
            placeholder="Room 412, Room 413..."
            mode="multiple"
            style={styles.pickRoomSelect}
            onChange={(value: any) => {
              setRooms(value)
            }}
          >
            {devices.items.map((item: any) => {
              return (
                <Select.Option key={item.position} value={item.position}>
                  {item.position}
                </Select.Option>
              )
            })}
          </Select>
          <Checkbox.Group
            options={alertOPtions}
            defaultValue={allAlertTypes}
            value={alertTypes}
            onChange={onAlertTypesChanged}
          />
          {/* <Button
            style={{ float: "right", marginLeft: "10px", marginRight: "10px" }}
            key="refresh"
            type="primary"
            onClick={() => {
              getDevices(false)
              forceUpdate()
            }}
            shape="round"
          >
            <ReloadOutlined />
            Refresh
          </Button> */}
        </div>
        <div
          style={
            width <= 903 // normal iPad width
              ? styles.gridContainerOneCol
              : width <= 1204 // normal iPad height
              ? styles.gridContainerTwoCol
              : styles.gridContainer
          }
        >
          {devices.items
            .filter(
              (_: any) => rooms.includes(_.position) || rooms.length === 0
            )
            .filter(
              (_: any) =>
                _.patient.name.includes(name) ||
                _.patient.id.includes(name) ||
                name === ""
            )
            .filter((_: any) => {
              //console.log("positions:", positions)

              if (!_.position) {
                return true
              }
              if (alertTypes.length === 0) {
                return true
              }
              return positions.includes(_.position)
            })
            .filter((_: any) => {
              // Add User Type Filter
              switch (currentUser.type) {
                case UserType.Nurse:
                  return _.deviceNurseId === currentUser.username
                case UserType.Doctor:
                  return _.deviceDoctorId === currentUser.username
                case UserType.Family:
                  // const memberInfo = familyMembers.items.filter((member: any) => {
                  //   return member.id === currentUser.username
                  // })[0]
                  return currentUser.familyMemberPatientId === _.devicePatientId
                case UserType.Admin:
                  return true
                default:
                  return false
              }
            })
            .sort((a: any, b: any) => {
              if (a.room > b.room) {
                return 1
              } else return -1
            })
            .map((item: any) => (
              <WebRTCMonitorCard
                key={item.id}
                item={item}
                withActionBar={true}
              />
              // <MonitorCard key={item.id} item={item} openVideo={openVideo} />
            ))}
        </div>
      </div>
    </Spin>
  )
}

export default Monitor

const styles: any = {
  searchBarContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    marginBottom: 30,
    flexWrap: "wrap"
  },
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(min(300px,100%), 33%))"
  },
  gridContainerTwoCol: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(min(300px,100%), 50%))"
  },
  gridContainerOneCol: {
    display: "grid",
    gridTemplateColumns: "100%"
  },
  pickRoomSelect: {
    minWidth: 200,
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    marginRight: "1vw"
  },
  datePicker: {
    borderRadius: 3,
    minWidth: 100,
    boxShadow: "1px 2px 5px 0px #D6CDCF",
    flex: 1,
    marginRight: "1vw"
  },
  searchIcon: { fontSize: 16, color: "#7d86a9" },
  searchInput: {
    minWidth: 100,
    borderRadius: 3,
    boxShadow: "1px 2px 5px 0px #D6CDCF",
    flex: 1,
    marginRight: "1vw"
  }
}
