import React, { Component, useEffect, useState, useMemo } from 'react'
import { Stage, Layer, Rect, Text, Line, Arrow, Circle, Image } from 'react-konva'
import { Button, Modal, Radio, Input } from 'antd'
import Konva from 'konva'
import useImage from 'use-image'
import useDevice from '../hooks/useDevices'
import useWindowDimensions from '../util/useWindowDimensions'

interface CanvasModalProp {
  mode: "create" | "edit"
  onCancel: () => void
  visibility: boolean
}

const CanvasModal: React.FC<CanvasModalProp> = ({
  mode,
  onCancel,
  visibility,
}) => {

  const { height, width } = useWindowDimensions()
  const { singleDevice, createNewRestrictedRegion, updateRegion, currentRegion, currentImage } = useDevice()
  const [points, setPoints] = useState<any[]>([])
  const [curMousePos, setCurMousePos] = useState<number[]>([0, 0])
  const [isMouseOverStartPoint, setIsMouseOverStartPoint] = useState<boolean>(false)
  const [isFinished, setIsFinished] = useState<boolean>(false)
  const [regionType, setRegionType] = useState<string> ("0")
  const [image] = useImage(currentImage.imageUrl)
  const [regionName, setRegionName] = useState<string>("")

  useEffect(() => {
    const transformPoints = () => {
      const readablePoints: any[] = []
      //console.log("Current Region: ", currentRegion)
      if(currentRegion.points) {
        const parsePoints = JSON.parse(currentRegion.points)
        parsePoints.forEach((point: any) => {
          readablePoints.push([Number(point.x), Number(point.y)])
        })
      }
      //console.log("readable points: ", readablePoints)
      return readablePoints
    }
    if(mode === "edit") {
      const result = transformPoints()
      setPoints(result)
      setRegionName(currentRegion.name)
      setRegionType(currentRegion.type)
      setIsFinished(true)
    } else if(mode === "create") {
      setPoints([])
      setRegionName("")
      setIsFinished(false)
    }
  }, [mode, currentRegion])

  const getMousePos = (stage: { getPointerPosition: () => { (): any; new(): any; x: any; y: any } }) => {
    return [stage.getPointerPosition().x, stage.getPointerPosition().y]
  }

  const handleClick = (event: { target: { getStage: () => any } }) => {
    const stage = event.target.getStage()
    const mousePos = getMousePos(stage)
    if (isFinished) {
      return
    }
    if (isMouseOverStartPoint && points.length >= 3) {
      setIsFinished(true)
    } else {
      setPoints([...points, mousePos])
    }
  }

  const handleMouseMove = (event: { target: { getStage: () => any } }) => {
    const stage = event.target.getStage()
    const mousePos = getMousePos(stage)
    setCurMousePos(mousePos)
  }

  const handleMouseOverStartPoint = (event: { target: { scale: (arg0: { x: number; y: number }) => void } }) => {
    if (isFinished || points.length < 3) 
      return
    event.target.scale({ x: 2, y: 2 })
    setIsMouseOverStartPoint(true)
  }

  const handleMouseOutStartPoint = (event: { target: { scale: (arg0: { x: number; y: number }) => void } }) => {
    event.target.scale({ x: 1, y: 1 })
    setIsMouseOverStartPoint(false)
  }

  const handleDragStartPoint = (event: any) => {
    console.log("start", event)
  }

  const handleDragMovePoint = (event: { target: { index: number; attrs: { x: any; y: any } } }) => {
    const index = event.target.index - 1
    console.log(event.target)
    const pos = [event.target.attrs.x, event.target.attrs.y]
    console.log("move", event)
    console.log(pos)
    setPoints([...points.slice(0, index), pos, ...points.slice(index + 1)])
  }

  const handleDragEndPoint = (event: any) => {
    console.log("end", event);
  }

  const getFlattenPoints = () => {
    return points
      .concat(isFinished ? [] : curMousePos)
      .reduce((a, b) => a.concat(b), [])
  }

  const onSaveHandler = async () => {
    const newPoints: { x: number; y: number }[] = []
    points.forEach((point: any) => {
      newPoints.push({
        x: point[0].toFixed(0),
        y: point[1].toFixed(0)
      })
    })
    const newPointsJson = JSON.stringify(newPoints)
    if(mode === "create") {
      const newRescritedRegion = {
        deviceId: singleDevice.deviceId,
        points: newPointsJson,
        type: regionType,
        name: regionName,
        createdAt: (new Date()).toISOString(),
        updatedAt: (new Date()).toISOString()
      }
      console.log("new restricted region: ", newRescritedRegion)
      await createNewRestrictedRegion(newRescritedRegion)
      setPoints([])
      setIsFinished(false)
    } else if(mode === "edit") {
      const updatedRescritedRegion = {
        id: currentRegion.id,
        deviceId: currentRegion.deviceId,
        points: newPointsJson,
        type: regionType,
        name: regionName,
        updatedAt: (new Date()).toISOString()
      }
      console.log("update restricted region: ", updatedRescritedRegion)
      await updateRegion(updatedRescritedRegion)
    }
    onCancel()
  }

  const onClearHandler = () => {
    console.log("current points: ", points)
    // Clear the polygon
    setPoints([])
    setIsFinished(false)
  }

  const onCancelHandler = () => {
    onCancel()
  }

  const onChangeTypeHandler= (e: any) => {
    //console.log("On change: ", e)
    setRegionType(e.target.value)
  }

  const memoImage = useMemo(() => {
    // return <Image image={image} width={1280} height={720} />
    // console.log("Image size: ", image?.naturalWidth, image?.naturalHeight)
    return <Image image={image} width={image?.naturalWidth} height={image?.naturalHeight} />
  }, [image])

  return (
    <Modal
      visible={visibility}
      width={"55%"}
      onCancel={onCancelHandler}
      footer={[
        <Button key="save" type="primary" onClick={onSaveHandler}>
          Save
        </Button>,
        <Button key="clear" danger type="primary" onClick={onClearHandler}>
          Clear
        </Button>,
        <Button key="cancel" danger type="default" onClick={onCancelHandler}>
          Cancel
        </Button>
      ]}
    >
      <Input 
        placeholder="Enter a name for the region" style={{ width: "50%", marginBottom: 10 }}
        value={regionName}
        onChange={(ev: any) => {
          //console.log(ev.target.value)
          setRegionName(ev.target.value)
        }}
      />
      <Radio.Group
        name="radiogroup" 
        defaultValue={"0"} 
        value={regionType}
        onChange={onChangeTypeHandler}
        style={{ marginLeft: 10 }}
      >
        <Radio value={"0"}>Disabled</Radio>
        <Radio value={"1"}>Enabled</Radio>
      </Radio.Group>
      {/* // Stage is a div container
      // Layer is actual canvas element (so you may have several canvases in the stage)
      // And then we have canvas shapes inside the Layer */}
      <Stage
        // width={640} 
        // height={576}
        width={image?.naturalWidth}
        height={image?.naturalHeight}
        onMouseDown={handleClick}
        onMouseMove={handleMouseMove}
        style={{
          overflowX: "scroll",
          overflowY: "scroll"
        }}
      >
        <Layer>
          {memoImage}
        </Layer>
        <Layer>
          <Line
            points={getFlattenPoints()}
            stroke="black"
            strokeWidth={5}
            closed={isFinished}
          />
          {points.map((point, index) => {
            const width = 6;
            const x = point[0] - width / 2;
            const y = point[1] - width / 2;
            const startPointAttr =
              index === 0
                ? {
                    hitStrokeWidth: 12,
                    onMouseOver: handleMouseOverStartPoint,
                    onMouseOut: handleMouseOutStartPoint
                  }
                : null;
            return (
              <Rect
                key={index}
                x={x}
                y={y}
                width={width}
                height={width}
                fill="white"
                stroke="black"
                strokeWidth={3}
                onDragStart={handleDragStartPoint}
                onDragMove={handleDragMovePoint}
                onDragEnd={handleDragEndPoint}
                draggable
                {...startPointAttr}
              />
            );
          })}
        </Layer>
      </Stage>
    </Modal>
  )
}

export default CanvasModal
