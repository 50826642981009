import React from "react"
import { Button, Col, Descriptions, Drawer, Row, Typography } from "antd"
import ReactPlayer from "react-player/lazy"
import useRecords from "../hooks/useRecords"
import { getFilePath, getNumId } from "../util"
import moment from "moment"
import { displayDateFormat, SERVER_ADDRESS } from "../config"
import FlvPlayer from "./FlvPlayer"
import WebRTCPlayer from "./WebRTC/WebRTCPlayer"

const getType = (type: string) => {
  if (type === "fall") {
    return "Fall"
  } else if (type === "getup") {
    return "Get Up"
  } else if (type === "askforhelp") {
    return "Ask for Help"
  }
  return "Others"
}

interface AlertDrawerProp {
  drawerId: string
  visibility: boolean
  onClose: (id: string) => void
}

const style = {
  contentStyle: {
    fontWeight: 700
  }
}

const AlertDrawer: React.FC<AlertDrawerProp> = ({
  drawerId,
  visibility,
  onClose
}) => {
  const { unprocessedFalls, setIsFalseAlarm } = useRecords()
  const details = unprocessedFalls.find((f: any) => f.id === drawerId)
  // console.log("Unprocessed falls: ", unprocessedFalls)
  // console.log("Alert drawer details: ", details)

  return (
    <Drawer
      onClose={() => onClose(drawerId)}
      visible={visibility}
      width="800px"
      placement="right"
    >
      {details ? (
        <>
          <Descriptions
            title={`Patient ID: ${getNumId(details.patient.id)}`}
            layout="vertical"
          >
            <Descriptions.Item
              label="Position"
              contentStyle={style.contentStyle}
            >
              {details?.position}
            </Descriptions.Item>
            <Descriptions.Item
              label="Time Captured"
              contentStyle={style.contentStyle}
            >
              {moment(details.createdAt).format(displayDateFormat)}
            </Descriptions.Item>
            <Descriptions.Item label="Type" contentStyle={style.contentStyle}>
              {getType(details.type)}
            </Descriptions.Item>
            <Descriptions.Item label="Doctor" contentStyle={style.contentStyle}>
              {details?.doctor?.name}
            </Descriptions.Item>
            <Descriptions.Item
              label="Doctor Phone"
              contentStyle={style.contentStyle}
              span={16}
            >
              {details?.doctor?.phone}
            </Descriptions.Item>
            <Descriptions.Item label="Nurse" contentStyle={style.contentStyle}>
              {details?.nurse?.name}
            </Descriptions.Item>
            <Descriptions.Item
              label="Nurse Phone"
              contentStyle={style.contentStyle}
              span={16}
            >
              {details?.nurse?.phone}
            </Descriptions.Item>
          </Descriptions>
          <Typography.Title level={3}>Monitor</Typography.Title>
          {/* Show real time monitor on the device, instead of recording */}
          <WebRTCPlayer item={details}/>
          {/* <FlvPlayer
            // url={getFilePath(details?.video)}
            url={
              // TODO: no device ID in record, add device id or find by device or position
              details.position.includes("Room 413")
                ? `${SERVER_ADDRESS}/test2.flv`
                : `${SERVER_ADDRESS}/test.flv`
            }
            type="flv"
            hasAudio={false}
            style={{ maxWidth: "100%", backgroundColor: "grey" }}
          /> */}
          {/* <ReactPlayer
            url={getFilePath(details?.video)}
            controls
            style={{ maxWidth: "99%", backgroundColor: "grey" }}
          /> */}
          <Row style={{ marginTop: 20 }}>
            <Col
              span={24}
              style={{ justifyContent: "center", textAlign: "center" }}
            >
              <Button
                type="primary"
                onClick={() => {
                  onClose(details.id)
                }}
              >
                On the way
              </Button>
              <Button
                style={{ color: "red", marginLeft: 20 }}
                onClick={async () => {
                  await setIsFalseAlarm(details.id)
                }}
              >
                False Alarm
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        "Not Found"
      )}
    </Drawer>
  )
}

export default AlertDrawer
