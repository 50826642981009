import React from "react"
import Indicator from "./FloatIndicator"
import Grid from "./Grid"

interface MatrixProps {
  x: number
  y: number
}

interface ConsequenceGrid {
  startingX: number
  endX: number
  label: string
}
interface LikelihoodGrid {
  startingY: number
  endY: number
  label: string
}

const RiskMatrix: React.FC<MatrixProps> = ({ x, y }) => {
  const consequenceList: Array<ConsequenceGrid> = [
    {
      startingX: 0,
      endX: 1,
      label: "Minor"
    },
    {
      startingX: 1,
      endX: 2,
      label: "Moderate"
    },
    {
      startingX: 2,
      endX: 3,
      label: "Significant"
    },
    {
      startingX: 3,
      endX: 4,
      label: "Major"
    },
    {
      startingX: 4,
      endX: 5,
      label: "Catastrophic"
    }
  ]

  const likelihookList: Array<LikelihoodGrid> = [
    {
      startingY: 4,
      endY: 5,
      label: "Almost Certain"
    },
    {
      startingY: 3,
      endY: 4,
      label: "Likely"
    },
    {
      startingY: 2,
      endY: 3,
      label: "Possible"
    },
    {
      startingY: 1,
      endY: 2,
      label: "Unlikely"
    },
    {
      startingY: 0,
      endY: 1,
      label: "Rare"
    }
  ]

  const RenderRow = (likelihoodGrid: LikelihoodGrid) => {
    return (
      <div key={likelihoodGrid.label}>
        {consequenceList.map((consiquence: ConsequenceGrid) => {
          return <Grid key={`${consiquence.endX*likelihoodGrid.endY}`} x={consiquence.endX} y={likelihoodGrid.endY} />
        })}
      </div>
    )
  }

  return (
    <div style={{ display: "flex" }}>
      <div style={{ marginTop: 42 }}>
        {likelihookList
          .map((c: LikelihoodGrid) => c.label)
          .map((label) => {
            return (
              <div
                key={label}
                style={{
                  height: 94,
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <span style={{ lineHeight: 1, fontWeight: "bold" }}>
                  {label}
                </span>
              </div>
            )
          })}
      </div>
      <div>
        <div
          style={{
            border: "2px solid white",
            display: "inline-block",
            position: "relative"
          }}
        >
          {likelihookList.map(RenderRow)}
          <Indicator x={x} y={y} />
        </div>
        <div style={{ display: "flex", border: "2px solid white" }}>
          {consequenceList
            .map((lg) => lg.label)
            .map((label: string) => {
              return (
                <div
                  key={`consequence-${label}`}
                  style={{
                    width: 94,
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <span style={{ lineHeight: 1, fontWeight: "bold" }}>
                    {label}
                  </span>
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}

export default RiskMatrix
