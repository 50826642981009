export const LOGIN_TIMEOUT = 5000

export const SERVER_ADDRESS = "http://13.236.69.21:8000/live"

export const displayDateFormat = "hh:mmA, DD MMMM YYYY"

export const notificationDisplayDateFormat = "DD.MMMM.YYYY hh:mm:ssA"

export const stripePublishKey = "pk_test_51JSWOVCylesAzCcB6wnuaG4vD4Y1Y7O6G02fSQNo63ekagCH5Oy1t0CnNPuCwQwXZ2l9LoNd8j0WEw5TC5eiLcJy00DDs63EYp"

export const palette = {
  orange: "#F7981C",
  red: "#FF4D4F",
  blue: "#1890FF",
  green: "#39B54A",
  yellow: "#F6D247"
}

export enum UserType {
  Nurse = "Nurse",
  Doctor = "Doctor",
  Admin = "Admin",
  Family = "Family"
}