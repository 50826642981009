import { useSelector, useDispatch } from "react-redux"
import { API, graphqlOperation, Auth } from "aws-amplify"
import {
  listDoctors,
  listNurses,
  getDoctor,
  getNurse,
  searchPatients
} from "../graphql/queries"
import {
  createDoctor,
  createNurse,
  deleteNurse,
  updateDoctor,
  updateNurse
} from "../graphql/mutations"
import {
  getStaffList,
  getSingleStaffDetail,
  addStaff,
  modifyStaff,
  deleteCurrentStaff
} from "../redux/Staff/actions"
import { message } from "antd"

const useStaff = () => {
  const dispatch = useDispatch()
  const { doctors, nurses, currentStaff } = useSelector(
    (state: any) => state.Staff
  )

  async function fetchStaffList({
    type,
    limit = 10,
    callback,
    isExtend = false
  }: {
    type: "doctors" | "nurses"
    limit?: number
    callback?: () => void
    isExtend?: boolean
  }) {
    try {
      const query = type === "doctors" ? listDoctors : listNurses
      const token = type === "doctors" ? doctors.nextToken : nurses.nextToken
      const response: any = await API.graphql(
        graphqlOperation(query, {
          limit,
          nextToken: isExtend ? token || null : null
        })
      )
      dispatch(
        getStaffList({
          dataType: type,
          data:
            response.data[type === "doctors" ? "listDoctors" : "listNurses"],
          isExtend
        })
      )
    } catch (error) {
      console.error(error)
    } finally {
      callback && callback()
    }
  }

  async function fetchSingleStaff(props: {
    staffType: "doctor" | "nurse"
    id: string
    callback?: () => void
  }) {
    const { staffType, id, callback } = props
    try {
      const query = staffType === "doctor" ? getDoctor : getNurse
      const response: any = await API.graphql(
        graphqlOperation(query, {
          id
        })
      )
      console.log(response.data)
      dispatch(
        getSingleStaffDetail({
          type: staffType,
          data: response.data[staffType === "doctor" ? "getDoctor" : "getNurse"]
        })
      )
    } catch (error) {
      console.error(error)
    } finally {
      callback && callback()
    }
  }

  function resetSingleStaff() {
    dispatch(getSingleStaffDetail({ type: "", data: {} }))
  }

  // To do: sign up the user in cognito
  async function createNewStaff(props: { detail: any; callback?: () => void }) {
    const { detail, callback } = props
    try {
      const { type, ...staffDetail } = detail
      const query = type === "Doctor" ? createDoctor : createNurse
      const response: any = await API.graphql(
        graphqlOperation(query, {
          input: staffDetail
        })
      )
      console.log(response.data)
      const newStaffDetail = {
        dataType: type === "Doctor" ? "doctors" : "nurses",
        detail:
          response.data[type === "Doctor" ? "createDoctor" : "createNurse"]
      }
      dispatch(addStaff(newStaffDetail))
      return newStaffDetail.detail.id
    } catch (error) {
      console.error(error)
    } finally {
      callback && callback()
    }
  }

  async function updateCurrentStaff(props: {
    id: string
    staffType: "Doctor" | "Nurse"
    details: any
    callback?: () => void
  }) {
    const { id, staffType, details, callback } = props

    try {
      const query = staffType === "Doctor" ? updateDoctor : updateNurse
      const response: any = await API.graphql(
        graphqlOperation(query, {
          input: { id, ...details }
        })
      )
      const newStaffDetail = {
        dataType: staffType === "Doctor" ? "doctors" : "nurses",
        detail:
          response.data[staffType === "Doctor" ? "updateDoctor" : "updateNurse"]
      }
      dispatch(modifyStaff(newStaffDetail))
    } catch (error) {
      console.error(error)
    } finally {
      callback && callback()
    }
  }

  async function deleteStaff(props: {
    staffType: "doctor" | "nurse"
    id: string
  }) {
    const { staffType, id } = props
    let query = deleteNurse
    let condition = { patientNurseId: { eq: id } }
    if (staffType === "doctor") {
      // query = null
    }
    try {
      const response: any = await API.graphql(
        graphqlOperation(searchPatients, {
          filter: condition
        })
      )
      if (response.data.searchPatients.total > 0) {
        return message.error(
          "Current staff has patients, please update patients first!"
        )
      }
      await API.graphql(
        graphqlOperation(query, {
          input: { id }
        })
      )
      dispatch(
        deleteCurrentStaff({
          staffType: "nurses",
          id
        })
      )
    } catch (error) {
      console.error(error)
    }
  }

  return {
    doctors,
    nurses,
    currentStaff,
    fetchStaffList,
    fetchSingleStaff,
    resetSingleStaff,
    createNewStaff,
    updateCurrentStaff,
    deleteStaff
  }
}

export default useStaff
