import { useSelector, useDispatch } from "react-redux"
import { API, graphqlOperation, Auth } from "aws-amplify"
import {
  listMember,
  getMember
} from "../graphql/queries"
import {
  createMember,
  updateMember
} from "../graphql/mutations"
import {
  getFamilyMemberList,
  getFamilyMemberDetail,
  setCurrentFamilyMember,
  addNewFamilyMember,
  modifyFamilyMember
} from "../redux/FamilyMembers/actions"

const useFamilyMembers = () => {
  const dispatch = useDispatch()
  const { familyMembers, currentMember } = useSelector(
    (state: any) => state.FamilyMembers
  )

  async function fetchFamilyMemberList({
    limit = 10,
    callback,
    isExtend = false
  }: {
    limit?: number
    callback?: () => void
    isExtend?: boolean
  }) {
    try {
      const response: any = await API.graphql(
        graphqlOperation(listMember, {
          limit,
          nextToken: isExtend ? familyMembers.nextToken || null : null
        })
      )
      dispatch(
        getFamilyMemberList(response.data.listMember, isExtend)
      )
    } catch (error) {
      console.error(error)
    } finally {
      callback && callback()
    }
  }

  async function fetchSingleFamilyMember(id: string, callback?: () => void) {
    try {
      const response: any = await API.graphql(
        graphqlOperation(getMember, { id })
      )
      console.log(response.data)
      dispatch(setCurrentFamilyMember(response.data.getMember))
    } catch (error) {
      console.error(error)
    } finally {
      callback && callback()
    }
  }

  // To do: sign up the user for cognito
  async function createNewFamilyMember(details: any, callback?: () => void) {
    try {
      const { type, ...userDetail } = details
      console.log("userdetail: ", userDetail)
      const response: any = await API.graphql(
        graphqlOperation(createMember, { input: { ...userDetail, prefer: false, pushToken: "" } })
      )
      console.log(response.data)
      // const newStaffDetail = {
      //   dataType: type === "Doctor" ? "doctors" : "nurses",
      //   detail:
      //     response.data[type === "Doctor" ? "createDoctor" : "createNurse"]
      // }
      // dispatch(addStaff(newStaffDetail))
      return response.data.createMember
    } catch (error) {
      console.error(error)
    } finally {
      callback && callback()
    }
  }

  async function updateCurrentFamilyMember(
    id: string,
    details: any,
    callback?: () => void
  ) {
    try {
      const response: any = await API.graphql(
        graphqlOperation(updateMember, {
          input: { id, ...details }
        })
      )
      dispatch(modifyFamilyMember(response.data.updateMember))
    } catch (error) {
      console.error(error)
    } finally {
      callback && callback()
    }
  }

  function setFamilyMember(props: any) {
    dispatch(setCurrentFamilyMember(props))
  }

  return {
    familyMembers,
    currentMember,
    fetchFamilyMemberList,
    fetchSingleFamilyMember,
    setFamilyMember,
    createNewFamilyMember,
    updateCurrentFamilyMember
  }
}

export default useFamilyMembers
