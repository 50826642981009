import { Action } from "../../models/General"
import { User } from "../../models/User"
import actions from "./actions"

type UserReducerState = {
  currentUser?: User
}

const initState = {
  currentUser: {}
}

export default function userReducer(
  state: UserReducerState = initState,
  { type, ...action }: Action
) {
  switch (type) {
    case actions.SET_USER:
      return {
        ...state,
        currentUser: action.payload
      }
    default:
      return state
  }
}
