import { withAuthenticator } from "@aws-amplify/ui-react"
import "antd/dist/antd.css"
import "./App.css"
import Amplify /* ,{ API, Auth, graphqlOperation } */ from "aws-amplify"
import React from "react"
import { Provider } from "react-redux"
import { HashRouter as Router } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import aswExports from "./aws-exports"
// import AlertDrawer from "./components/AlertDrawer"
// import { onCreateRecord } from "./graphql/subscriptions"
// import useRecords from "./hooks/useRecords"
import Pages from "./pages"
import store from "./redux/store"

Amplify.configure(aswExports)

function App() {
  // const { receiveAlert, processAlert } = useRecords()
  // const [drawerVisibility, setDrawerVisibility] = useState<boolean>(false)
  // const [drawerId, setDrawerId] = useState<string>("")
  // useEffect(() => {
  //   let subscription: any = null
  //   Auth.currentSession()
  //     .then((session) => {
  //       // const username = session.getIdToken().decodePayload().sub
  //       subscription = API.graphql(
  //         graphqlOperation(onCreateRecord)
  //         // @ts-ignore
  //       ).subscribe({
  //         next: ({ provider, value }: any) => {
  //           console.log(value)
  //           receiveAlert(value.data)
  //           toast.warning("A fall is detected!", {
  //             toastId: value.data.id,
  //             onClick: () => {
  //               setDrawerId(value.data.id)
  //               setDrawerVisibility(true)
  //             }
  //           })
  //         },
  //         error: (error: Error) => {
  //           console.warn(error)
  //         }
  //       })
  //     })
  //     .catch((error) => {
  //       console.log("Stop subscription, error: ", error)
  //     })
  //   return () => {
  //     subscription?.unsubscribe()
  //   }
  // }, [])
  return (
    <Provider store={store}>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={false} />
      <Router>
        <Pages />
      </Router>
    </Provider>
  )
}

// export default withAuthenticator(App)

export default App
