import React, { useEffect, useState, useRef } from "react"
import { Button, PageHeader, Typography, Form, DatePicker, Select, Drawer, Descriptions, Table, Input, Checkbox } from "antd"
import { RightOutlined } from "@ant-design/icons"
import ReactPlayer from "react-player/lazy"
import moment, { Moment } from "moment"
import useRecords from "../hooks/useRecords"
import useDevices from "../hooks/useDevices"
import usePatients from "../hooks/usePatients"
import useCurrentUser from "../hooks/useCurrentUser"
import RiskLevelBadge from "../components/RiskLevelBadge"
import { getFilePath, getNumId } from "../util"
import { isAdmin, isNurse } from "../util/authHelper"
import AlertTypeTag from "../components/AlertTypeTag"

// const { Meta } = Card
// const { RangePicker } = DatePicker
const { Item } = Form
const { Search } = Input

const rooms = ["Room 412", "Room 413"]
const searchDateFormat = "YYYY/MM/DD hhA"
const displayDateFormat = "hh:mmA, DD MMMM YYYY"
const alertOPtions = [
  { label: 'Get up Alert', value: 'getup' },
  { label: 'Fall Detection Alert', value: 'fall' },
  { label: 'Fall Prediction Alert', value: 'fallpredict' },
  { label: 'Ask For Help Alert', value: 'askforhelp' }
]
const defaultTime: Moment = moment()
defaultTime.hours(0)
defaultTime.minutes(0)
defaultTime.seconds(0)

const FallRecordList: React.FC = () => {
  const { fetchFallByDoctor, allFalls } = useRecords()
  const { currentUser } = useCurrentUser()
  const { items, nextToken, total } = allFalls
  const { devices, getDevices } = useDevices()
  const { fetchPatients } = usePatients()
  
  const [loading, setLoading] = useState<boolean>(false)
  const [filter, setFilter] = useState<any>(null)

  const [selectedRecord, setSelectedRecord] = useState<any>(null)
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false)
  
  // const [searchItems, setSearchItems] = useState<any>(items)
  // const [rooms, setRooms] = useState<string[]>([])
  const [patientName, setPatientName] = useState<string>("")
  const [timeRange, setTimeRange] = useState<any>(null)
  const [selectedRooms, setSelectedRooms] = useState<string[]>([])
  const [selectedAlertTypes, setSelectedAlertTypes] = useState<string[]>([])

  const filterRef = useRef<any>()

  const onLoadMore = async (
    isExtend = false,
    isFiltering = false,
    currentFilter?: any
  ) => {
    setLoading(true)
    //console.log("Items: ", items)
    if (currentUser.username) {
      if (isAdmin(currentUser)) {
        console.log("fetch records as admin...")
        fetchFallByDoctor({
          nextToken,
          isExtend,
          callback: () => setLoading(false),
          filter: isFiltering ? currentFilter || filter : {},
          limit: 1000
        })
      } else if (isNurse(currentUser)) {
        fetchFallByDoctor({
          nextToken,
          isExtend,
          callback: () => setLoading(false),
          filter: isFiltering
            ? { ...currentFilter, recordNurseId: { eq: currentUser.username } }
            : {},
          limit: 1000
        })
      } else {
        // user type is Doctor
        fetchFallByDoctor({
          id: currentUser.username,
          nextToken,
          isExtend,
          callback: () => setLoading(false),
          filter: isFiltering ? currentFilter || filter : {},
          limit: 1000
        })
      }
    }
  }

  useEffect(() => {
    if (currentUser.username) {
      //console.log("load first batch")
      onLoadMore()
    }
    // eslint-disable-next-line
  }, [currentUser.username, total])

  // useEffect(() => {
  //   const getAllRooms = async () => {
  //     const rooms: string[] = []
  //     const result = await getDevices(false)
  //     if(result.items?.length > 0) {
  //       result.items.forEach((item: { position: string }) => {
  //         rooms.push(item.position)
  //       })
  //     }
  //     const uniqueRooms = [...Array.from(new Set(rooms))]
  //     setRooms(uniqueRooms)
  //   }
  //   getAllRooms()
  // }, [devices])

  const loadMore = !loading ? (
    <div
      style={{
        textAlign: "center",
        marginTop: 12,
        height: 32,
        lineHeight: "32px"
      }}
    >
      <Button
        onClick={() => onLoadMore(true, !!filter)}
        disabled={!currentUser.username || !nextToken}
      >
        Load More
      </Button>
    </div>
  ) : null

  const getPatientIdByName = async (name: string) => {
    //console.log("Patients: ", patients)
    const patients = await fetchPatients({})
    //console.log("Patients: ", patients)
    let filterResult = patients.items?.filter((item: { name: string }) => item.name === name)
    if(filterResult?.length > 0) {
      return filterResult[0].id
    } else {
      return "Not found"
    }
  }

  const onPatientNameChanged = (value: any) => {
    setPatientName(value)
  }

  const onTimeRangeChanged = (value: any, dateString: any) => {
    // console.log('Selected Time: ', value);
    // console.log('Formatted Selected Time: ', dateString);
    if(value !== null) {
      setTimeRange([value, moment()])
    }
  }

  const onSelectedRoomsChanged = (value: any) => {
    // console.log("selected rooms: ", value)
    setSelectedRooms(value)
  }

  const onAlertOptionsChanged = (value: any) => {
    // console.log("selected alert types: ", value)
    setSelectedAlertTypes(value)
  }
  
  const onSubmitForm = async (props: any) => {
    const { patient, timeRange, rooms, alertTypes } = props
    let tempFilter: any = {}
    if(patient) {
      let id = await getPatientIdByName(patient)
      if(id != "Not found") {
        tempFilter.recordPatientId = { eq: id }
      }
    }
    if(timeRange) {
      const start = timeRange.toISOString()
      const end = moment().toISOString()
      // const start = timeRange[0].toISOString()
      // console.log(start)
      //const end = timeRange[1].toISOString()
      tempFilter.createdAt = { gt: start, lt: end }
    }
    if(rooms?.length > 0) {
      if(rooms.length === 1) {
        tempFilter.position = { eq: rooms[0] }
      } else {
        let regExp = ""
        rooms.forEach((room: string,index: number) => {
          regExp = regExp + room
          if(index !== rooms.length - 1) {
            regExp = regExp + '|'
          }
        })
        tempFilter.position = { regexp: regExp }
      }
    }
    if(alertTypes?.length > 0) {
      if(alertTypes.length === 1) {
        tempFilter.type = { eq: alertTypes[0] }
      } else {
        let regExp = ""
        regExp = alertTypes.join("|")
        tempFilter.type = { regexp: regExp }
      }
    }
    setFilter(tempFilter)
    console.log(tempFilter)
    onLoadMore(false, true, tempFilter)
  }

  const onSearch = async (value: any) => {
    console.log(items)
    let tempFilter: any = {}
    if(value) {
      let id = await getPatientIdByName(value)
      if(id != "Not found") {
        tempFilter.recordPatientId = { eq: id }
      }
    }
    if(timeRange) {
      const start = timeRange[0].toISOString()
      //console.log(start)
      const end = timeRange[1].toISOString()
      tempFilter.createdAt = { gt: start, lt: end }
    }
    if(selectedRooms?.length > 0) {
      if(selectedRooms.length === 1) {
        tempFilter.position = { eq: selectedRooms[0] }
      } else {
        let regExp = ""
        selectedRooms.forEach((room,index) => {
          regExp = regExp + room
          if(index !== selectedRooms.length - 1) {
            regExp = regExp + '|'
          }
        })
        tempFilter.position = { regexp: regExp }
      }
    }
    if(selectedAlertTypes?.length > 0) {
      if(selectedAlertTypes.length === 1) {
        tempFilter.type = { eq: selectedAlertTypes[0] }
      } else {
        let regExp = ""
        selectedAlertTypes.forEach((type,index) => {
          regExp = regExp + type
          if(index !== selectedAlertTypes.length - 1) {
            regExp = regExp + '|'
          }
        })
        tempFilter.type = { regexp: regExp }
      }
    }
    setFilter(tempFilter)
    console.log(tempFilter)
    onLoadMore(false, true, tempFilter)
  }

  const columns = [
    {
      title: 'Room ID',
      dataIndex: 'position',
      render: (position: string) => (
        <Typography>
          {position}
        </Typography>
      )
    },
    {
      title: 'Alert Type',
      dataIndex: 'type',
      render: (type: string, row: any) => (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Typography style={{ marginRight: 10 }}>
            {type}
          </Typography>
          <AlertTypeTag item={row} />
        </div>
      )
    },
    {
      title: 'Alert sent at',
      dataIndex: 'createdAt',
      render: (createdAt: string) => (
        <Typography>
          {moment(createdAt).format(displayDateFormat)}
        </Typography>
      )
    },
    {
      title: 'Respond at',
      dataIndex: 'updatedAt',
      render: (updatedAt: string) => (
        <Typography>
          {moment(updatedAt).format(displayDateFormat)}
        </Typography>
      )
    },
    {
      title: 'Resolved by',
      dataIndex: 'nurse',
      render: (nurse: { name: string }) => (
        <Typography>
          {nurse?.name || ""}
        </Typography>
      )
    },
    {
      width: '3%',
      render: () => (
        <RightOutlined/>
      )
    }
  ]

  return (
    <div style={{ backgroundColor: "white", borderRadius: "10px" }}>
      <PageHeader
        ghost={false}
        //onBack={() => window.history.back()}
        style={{ borderRadius: "10px" }}
        title="Fall Records"
      >
      </PageHeader>
      <Form
        layout="inline"
        style={{ margin: 20 }}
        ref={filterRef}
        onFinish={onSubmitForm}
      >
        <Item name="patient">
          <Search 
            placeholder="Patient Name" 
            allowClear 
            onSearch={onSearch}
            onChange={onPatientNameChanged}
            style={{ width: 200, boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }} 
          />
        </Item>
        <Item name="timeRange">
          <DatePicker
             showTime
             placeholder="Date from"
             style={{ width: 200, boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
             format={searchDateFormat}
             onChange={onTimeRangeChanged}
             disabledDate={current => {
               return current.isAfter(Date.now())
             }}
             defaultPickerValue={defaultTime}
          />
          {/* <RangePicker 
            showTime 
            style={{ width: 400, boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
            format={searchDateFormat}
            onChange={onTimeRangeChanged}
            disabledDate={current => {
              return current.isAfter(Date.now())
            }}
            defaultPickerValue={[defaultTime, defaultTime]}
          /> */}
        </Item>
        <Item name="rooms">
          <Select 
            showArrow
            placeholder="Room 412, Room 413..."
            mode="multiple"
            style={{ width: 300, boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
            onChange={onSelectedRoomsChanged}
          >
            {
              rooms.map(room => {
                return (
                  <Select.Option key={room} value={room}>
                    {room}
                  </Select.Option>
                )
              })
            }
          </Select>
        </Item>
        <Item name="alertTypes">
          <Checkbox.Group 
            options={alertOPtions} 
            value={selectedAlertTypes} 
            onChange={onAlertOptionsChanged}
            style={{ margin: 10 }}
          />
        </Item>
        <Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ margin: 10 }}
          >
            Search
          </Button>
        </Item>
      </Form>
      <Table
        loading={loading}
        columns={columns} 
        dataSource={items}
        onRow={(record, rowIndex) => {
          return {
            onClick: event => {
              setSelectedRecord(record)
              setDrawerVisible(true)
            },
          };
        }}
        rowKey={item => item.id}
      />
      <Drawer
        visible={drawerVisible}
        onClose={() => {
          setDrawerVisible(false)
        }}
        width={800}
      >
        <RecordDetail key={selectedRecord?.id || "0"} record={selectedRecord} />
      </Drawer>
    </div>
  )
}

// Component to show detail of the fall record
const RecordDetail = ({ record }: { record?: any }) => {
  if (!record) {
    return <p>Record Information Not Found</p>
  }

  //console.log("Record Url: ", record?.video)
  //console.log(JSON.stringify(record))

  const position = record?.position || "N/A"

  const patient = record.patient
  const nurse = record.nurse

  const getType = (type: string) => {
    if (type === "fall") {
      return "Fall"
    } else if (type === "getup") {
      return "Get Up"
    } else if (type === "askforhelp") {
      return "Ask for help"
    }
    return "Others"
  }

  return (
    <>
      <Descriptions title="Record Detail">
        {/* <Descriptions.Item label="ID" span={24}>
          {record.id}
        </Descriptions.Item> */}
        <Descriptions.Item label="Position" span={12}>
          {position}
        </Descriptions.Item>

        <Descriptions.Item label="Created At" span={12}>
          {moment(record.createdAt).format(displayDateFormat)}
        </Descriptions.Item>
        <Descriptions.Item label="Last Updated At" span={12}>
          {moment(record.updatedAt).format(displayDateFormat)}
        </Descriptions.Item>
        <Descriptions.Item label="Status" span={3}>
          {record.status}
        </Descriptions.Item>
        <Descriptions.Item label="Type" span={3}>
          {getType(record.type)}
        </Descriptions.Item>
        {
          record.video ? (
            <Descriptions.Item label="Video" span={24}>
              <ReactPlayer
                controls
                style={{ maxWidth: "99%", backgroundColor: "grey" }}
                url={getFilePath(record.video)}
              />
            </Descriptions.Item>
          ) : (
            <Descriptions.Item label="Video" span={24}>
              <div
                style={{
                  height: "100%",
                  minHeight: 150,
                  justifyContent: "center",
                  textAlign: "center",
                  verticalAlign: "center",
                  display: "flex"
                }}
              >
                <span style={{ marginTop: "auto", marginBottom: "auto" }}>
                  Video stream is not available
                </span>
              </div>
            </Descriptions.Item>
          )
        }
      </Descriptions>
      {patient ? (
        <Descriptions title="Patient Info">
          <Descriptions.Item label="Patient ID">
            {getNumId(patient.id)}
          </Descriptions.Item>
          {/* <Descriptions.Item label="activityLevel">
            {patient.activityLevel}
          </Descriptions.Item> */}
          <Descriptions.Item label="Patient Risk Level">
            <RiskLevelBadge item={patient} />
          </Descriptions.Item>
        </Descriptions>
      ) : null}
      {record.doctor ? (
        <Descriptions title="Doctor">
          <Descriptions.Item label="Name">
            {record?.doctor?.name}
          </Descriptions.Item>
        </Descriptions>
      ) : null}
      {record.nurse ? (
        <Descriptions title="Nurse Info">
          <Descriptions.Item label="Name" span={24}>
            {record?.nurse?.name}
          </Descriptions.Item>
          <Descriptions.Item label="Email" span={12}>
            {record?.nurse?.email}
          </Descriptions.Item>
          <Descriptions.Item label="Phone">
            {record?.nurse?.phone}
            <Button
              href={`tel:${record.nurse.phone}`}
              type="primary"
              size="middle"
            >
              Call
            </Button>
          </Descriptions.Item>
        </Descriptions>
      ) : null}
    </>
  )
}

export default FallRecordList
