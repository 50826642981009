import { Action } from "../../models/General"

const actions = {
  GET_ALL_PATIENTS: "GET_ALL_PATIENTS",
  GET_PATIENT: "GET_PATIENT",
  CREATE_PATIENT: "CREATE_PATIENT",
  UPDATE_PATIENT: "UPDATE_PATIENT"
}

export const getAllPatients = (props: any, isExtend: boolean): Action => {
  return { type: actions.GET_ALL_PATIENTS, payload: { props, isExtend }}
}

export const getPatientDetail = (props: any): Action => {
  return { type: actions.GET_PATIENT, payload: { props}}
}

export const addPatient = (props: any): Action => {
  return { type: actions.CREATE_PATIENT, payload: props }
}

export const modifyPatient = (props: any): Action => {
  return { type: actions.UPDATE_PATIENT, payload: props }
}


export default actions