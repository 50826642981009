/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onUpdatePatientByDoctor = /* GraphQL */ `
  subscription OnUpdatePatientByDoctor($patientDoctorId: ID!) {
    onUpdatePatientByDoctor(patientDoctorId: $patientDoctorId) {
      id
      name
      avatar
      gender
      building
      room
      yearOfBirth
      activityLevel
      patientDoctorId
      patientNurseId
      dangerDegree
      organisationId
      status
      photo
      likelihood
      consequence
      riskCoefficient
      createdAt
      updatedAt
      doctor {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      nurse {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        
        device {
          nextToken
        }
      }
      family {
        items {
          id
          name
          avatar
          email
          phone
          gender
          relationship
          pushToken
          pushTokens
          prefer
          familyMemberPatientId
          createdAt
          updatedAt
        }
        nextToken
      }
      records {
        items {
          id
          recordDoctorId
          recordNurseId
          recordPatientId
          position
          video
          status
          isFalseAlarm
          type
          handoverNurseId
          createdAt
          updatedAt
        }
        nextToken
      }
      device {
        items {
          id
          deviceId
          deviceDoctorId
          devicePatientId
          deviceNurseId
          organisationId
          status
          position
          input
          output
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreatePatientByDoctor = /* GraphQL */ `
  subscription OnCreatePatientByDoctor($patientDoctorId: ID!) {
    onCreatePatientByDoctor(patientDoctorId: $patientDoctorId) {
      id
      name
      avatar
      gender
      building
      room
      yearOfBirth
      activityLevel
      patientDoctorId
      patientNurseId
      dangerDegree
      organisationId
      status
      photo
      likelihood
      consequence
      riskCoefficient
      createdAt
      updatedAt
      doctor {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      nurse {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        
        device {
          nextToken
        }
      }
      family {
        items {
          id
          name
          avatar
          email
          phone
          gender
          relationship
          pushToken
          pushTokens
          prefer
          familyMemberPatientId
          createdAt
          updatedAt
        }
        nextToken
      }
      records {
        items {
          id
          recordDoctorId
          recordNurseId
          recordPatientId
          position
          video
          status
          isFalseAlarm
          type
          handoverNurseId
          createdAt
          updatedAt
        }
        nextToken
      }
      device {
        items {
          id
          deviceId
          deviceDoctorId
          devicePatientId
          deviceNurseId
          organisationId
          status
          position
          input
          output
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateRecordByPatient = /* GraphQL */ `
  subscription OnUpdateRecordByPatient($recordPatientId: ID!) {
    onUpdateRecordByPatient(recordPatientId: $recordPatientId) {
      id
      recordDoctorId
      recordNurseId
      recordPatientId
      position
      alertStatus {
        id
        name
        confirm
      }
      video
      status
      isFalseAlarm
      type
      handoverNurseId
      createdAt
      updatedAt
      patient {
        id
        name
        avatar
        gender
        building
        room
        yearOfBirth
        activityLevel
        patientDoctorId
        patientNurseId
        dangerDegree
        organisationId
        status
        photo
        likelihood
        consequence
        riskCoefficient
        createdAt
        updatedAt
        doctor {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        nurse {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        family {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      doctor {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      nurse {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        
        device {
          nextToken
        }
      }
      handoverNurse {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        
        device {
          nextToken
        }
      }
    }
  }
`;
export const onCreateRecordByPatient = /* GraphQL */ `
  subscription OnCreateRecordByPatient($recordPatientId: ID!) {
    onCreateRecordByPatient(recordPatientId: $recordPatientId) {
      id
      recordDoctorId
      recordNurseId
      recordPatientId
      position
      alertStatus {
        id
        name
        confirm
      }
      video
      status
      isFalseAlarm
      type
      createdAt
      updatedAt
      patient {
        id
        name
        avatar
        gender
        building
        room
        yearOfBirth
        activityLevel
        patientDoctorId
        patientNurseId
        dangerDegree
        organisationId
        status
        photo
        likelihood
        consequence
        riskCoefficient
        createdAt
        updatedAt
      }
      doctor {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
      }
      nurse {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
      }
      # handoverNurse {
      #   id
      #   name
      #   avatar
      #   email
      #   phone
      #   pushToken
      #   pushTokens
      #   organisationId
      #   createdAt
      #   updatedAt
      #   patient {
      #     nextToken
      #   }
      #   records {
      #     nextToken
      #   }
        
      #   device {
      #     nextToken
      #   }
      # }
    }
  }
`;
export const onUpdateRecordById = /* GraphQL */ `
  subscription OnUpdateRecordById($id: ID!) {
    onUpdateRecordById(id: $id) {
      id
      recordDoctorId
      recordNurseId
      recordPatientId
      position
      alertStatus {
        id
        name
        confirm
      }
      video
      status
      isFalseAlarm
      type
      handoverNurseId
      createdAt
      updatedAt
      patient {
        id
        name
        avatar
        gender
        building
        room
        yearOfBirth
        activityLevel
        patientDoctorId
        patientNurseId
        dangerDegree
        organisationId
        status
        photo
        likelihood
        consequence
        riskCoefficient
        createdAt
        updatedAt
        doctor {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        nurse {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        family {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      doctor {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      nurse {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        
        device {
          nextToken
        }
      }
      handoverNurse {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        
        device {
          nextToken
        }
      }
    }
  }
`;
export const onCreateRecordByDoctor = /* GraphQL */ `
  subscription OnCreateRecordByDoctor($recordDoctorId: ID!) {
    onCreateRecordByDoctor(recordDoctorId: $recordDoctorId) {
      id
      recordDoctorId
      recordNurseId
      recordPatientId
      position
      alertStatus {
        id
        name
        confirm
      }
      video
      status
      isFalseAlarm
      type
      handoverNurseId
      createdAt
      updatedAt
      patient {
        id
        name
        avatar
        gender
        building
        room
        yearOfBirth
        activityLevel
        patientDoctorId
        patientNurseId
        dangerDegree
        organisationId
        status
        photo
        likelihood
        consequence
        riskCoefficient
        createdAt
        updatedAt
        doctor {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        nurse {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        family {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      doctor {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      nurse {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        
        device {
          nextToken
        }
      }
      handoverNurse {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        
        device {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateRecordByDoctor = /* GraphQL */ `
  subscription OnUpdateRecordByDoctor($recordDoctorId: ID!) {
    onUpdateRecordByDoctor(recordDoctorId: $recordDoctorId) {
      id
      recordDoctorId
      recordNurseId
      recordPatientId
      position
      alertStatus {
        id
        name
        confirm
      }
      video
      status
      isFalseAlarm
      type
      handoverNurseId
      createdAt
      updatedAt
      patient {
        id
        name
        avatar
        gender
        building
        room
        yearOfBirth
        activityLevel
        patientDoctorId
        patientNurseId
        dangerDegree
        organisationId
        status
        photo
        likelihood
        consequence
        riskCoefficient
        createdAt
        updatedAt
        doctor {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        nurse {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        family {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      doctor {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      nurse {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        
        device {
          nextToken
        }
      }
      handoverNurse {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        
        device {
          nextToken
        }
      }
    }
  }
`;
export const onCreatePatient = /* GraphQL */ `
  subscription OnCreatePatient {
    onCreatePatient {
      id
      name
      avatar
      gender
      building
      room
      yearOfBirth
      activityLevel
      patientDoctorId
      patientNurseId
      dangerDegree
      organisationId
      status
      photo
      likelihood
      consequence
      riskCoefficient
      createdAt
      updatedAt
      doctor {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      nurse {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        
        device {
          nextToken
        }
      }
      family {
        items {
          id
          name
          avatar
          email
          phone
          gender
          relationship
          pushToken
          pushTokens
          prefer
          familyMemberPatientId
          createdAt
          updatedAt
        }
        nextToken
      }
      records {
        items {
          id
          recordDoctorId
          recordNurseId
          recordPatientId
          position
          video
          status
          isFalseAlarm
          type
          handoverNurseId
          createdAt
          updatedAt
        }
        nextToken
      }
      device {
        items {
          id
          deviceId
          deviceDoctorId
          devicePatientId
          deviceNurseId
          organisationId
          status
          position
          input
          output
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdatePatient = /* GraphQL */ `
  subscription OnUpdatePatient {
    onUpdatePatient {
      id
      name
      avatar
      gender
      building
      room
      yearOfBirth
      activityLevel
      patientDoctorId
      patientNurseId
      dangerDegree
      organisationId
      status
      photo
      likelihood
      consequence
      riskCoefficient
      createdAt
      updatedAt
      doctor {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      nurse {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        
        device {
          nextToken
        }
      }
      family {
        items {
          id
          name
          avatar
          email
          phone
          gender
          relationship
          pushToken
          pushTokens
          prefer
          familyMemberPatientId
          createdAt
          updatedAt
        }
        nextToken
      }
      records {
        items {
          id
          recordDoctorId
          recordNurseId
          recordPatientId
          position
          video
          status
          isFalseAlarm
          type
          handoverNurseId
          createdAt
          updatedAt
        }
        nextToken
      }
      device {
        items {
          id
          deviceId
          deviceDoctorId
          devicePatientId
          deviceNurseId
          organisationId
          status
          position
          input
          output
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateNurse = /* GraphQL */ `
  subscription OnCreateNurse {
    onCreateNurse {
      id
      name
      avatar
      email
      phone
      pushToken
      pushTokens
      organisationId
      createdAt
      updatedAt
      patient {
        items {
          id
          name
          avatar
          gender
          building
          room
          yearOfBirth
          activityLevel
          patientDoctorId
          patientNurseId
          dangerDegree
          organisationId
          status
          photo
          likelihood
          consequence
          riskCoefficient
          createdAt
          updatedAt
        }
        nextToken
      }
      records {
        items {
          id
          recordDoctorId
          recordNurseId
          recordPatientId
          position
          video
          status
          isFalseAlarm
          type
          handoverNurseId
          createdAt
          updatedAt
        }
        nextToken
      }
      handoverRecords {
        items {
          id
          recordDoctorId
          recordNurseId
          recordPatientId
          position
          video
          status
          isFalseAlarm
          type
          handoverNurseId
          createdAt
          updatedAt
        }
        nextToken
      }
      device {
        items {
          id
          deviceId
          deviceDoctorId
          devicePatientId
          deviceNurseId
          organisationId
          status
          position
          input
          output
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateNurse = /* GraphQL */ `
  subscription OnUpdateNurse {
    onUpdateNurse {
      id
      name
      avatar
      email
      phone
      pushToken
      pushTokens
      organisationId
      createdAt
      updatedAt
      patient {
        items {
          id
          name
          avatar
          gender
          building
          room
          yearOfBirth
          activityLevel
          patientDoctorId
          patientNurseId
          dangerDegree
          organisationId
          status
          photo
          likelihood
          consequence
          riskCoefficient
          createdAt
          updatedAt
        }
        nextToken
      }
      records {
        items {
          id
          recordDoctorId
          recordNurseId
          recordPatientId
          position
          video
          status
          isFalseAlarm
          type
          handoverNurseId
          createdAt
          updatedAt
        }
        nextToken
      }
      handoverRecords {
        items {
          id
          recordDoctorId
          recordNurseId
          recordPatientId
          position
          video
          status
          isFalseAlarm
          type
          handoverNurseId
          createdAt
          updatedAt
        }
        nextToken
      }
      device {
        items {
          id
          deviceId
          deviceDoctorId
          devicePatientId
          deviceNurseId
          organisationId
          status
          position
          input
          output
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteNurse = /* GraphQL */ `
  subscription OnDeleteNurse {
    onDeleteNurse {
      id
      name
      avatar
      email
      phone
      pushToken
      pushTokens
      organisationId
      createdAt
      updatedAt
      patient {
        items {
          id
          name
          avatar
          gender
          building
          room
          yearOfBirth
          activityLevel
          patientDoctorId
          patientNurseId
          dangerDegree
          organisationId
          status
          photo
          likelihood
          consequence
          riskCoefficient
          createdAt
          updatedAt
        }
        nextToken
      }
      records {
        items {
          id
          recordDoctorId
          recordNurseId
          recordPatientId
          position
          video
          status
          isFalseAlarm
          type
          handoverNurseId
          createdAt
          updatedAt
        }
        nextToken
      }
      handoverRecords {
        items {
          id
          recordDoctorId
          recordNurseId
          recordPatientId
          position
          video
          status
          isFalseAlarm
          type
          handoverNurseId
          createdAt
          updatedAt
        }
        nextToken
      }
      device {
        items {
          id
          deviceId
          deviceDoctorId
          devicePatientId
          deviceNurseId
          organisationId
          status
          position
          input
          output
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateFamilyMember = /* GraphQL */ `
  subscription OnCreateFamilyMember {
    onCreateFamilyMember {
      id
      name
      avatar
      email
      phone
      gender
      relationship
      pushToken
      pushTokens
      prefer
      familyMemberPatientId
      createdAt
      updatedAt
      patient {
        id
        name
        avatar
        gender
        building
        room
        yearOfBirth
        activityLevel
        patientDoctorId
        patientNurseId
        dangerDegree
        organisationId
        status
        photo
        likelihood
        consequence
        riskCoefficient
        createdAt
        updatedAt
        doctor {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        nurse {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        family {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateFamilyMember = /* GraphQL */ `
  subscription OnUpdateFamilyMember {
    onUpdateFamilyMember {
      id
      name
      avatar
      email
      phone
      gender
      relationship
      pushToken
      pushTokens
      prefer
      familyMemberPatientId
      createdAt
      updatedAt
      patient {
        id
        name
        avatar
        gender
        building
        room
        yearOfBirth
        activityLevel
        patientDoctorId
        patientNurseId
        dangerDegree
        organisationId
        status
        photo
        likelihood
        consequence
        riskCoefficient
        createdAt
        updatedAt
        doctor {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        nurse {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        family {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
    }
  }
`;
export const onCreatePatientDoctorRelation = /* GraphQL */ `
  subscription OnCreatePatientDoctorRelation {
    onCreatePatientDoctorRelation {
      id
      doctorId
      patientId
      createdAt
      updatedAt
      patient {
        id
        name
        avatar
        gender
        building
        room
        yearOfBirth
        activityLevel
        patientDoctorId
        patientNurseId
        dangerDegree
        organisationId
        status
        photo
        likelihood
        consequence
        riskCoefficient
        createdAt
        updatedAt
        doctor {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        nurse {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        family {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      doctor {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
    }
  }
`;
export const onUpdatePatientDoctorRelation = /* GraphQL */ `
  subscription OnUpdatePatientDoctorRelation {
    onUpdatePatientDoctorRelation {
      id
      doctorId
      patientId
      createdAt
      updatedAt
      patient {
        id
        name
        avatar
        gender
        building
        room
        yearOfBirth
        activityLevel
        patientDoctorId
        patientNurseId
        dangerDegree
        organisationId
        status
        photo
        likelihood
        consequence
        riskCoefficient
        createdAt
        updatedAt
        doctor {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        nurse {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        family {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      doctor {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
    }
  }
`;
export const onDeletePatientDoctorRelation = /* GraphQL */ `
  subscription OnDeletePatientDoctorRelation {
    onDeletePatientDoctorRelation {
      id
      doctorId
      patientId
      createdAt
      updatedAt
      patient {
        id
        name
        avatar
        gender
        building
        room
        yearOfBirth
        activityLevel
        patientDoctorId
        patientNurseId
        dangerDegree
        organisationId
        status
        photo
        likelihood
        consequence
        riskCoefficient
        createdAt
        updatedAt
        doctor {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        nurse {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        family {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      doctor {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
    }
  }
`;
export const onCreateAdmin = /* GraphQL */ `
  subscription OnCreateAdmin {
    onCreateAdmin {
      id
      name
      avatar
      email
      phone
      pushToken
      pushTokens
      organisationId
      createdAt
      updatedAt
      organisation {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUpdateAdmin = /* GraphQL */ `
  subscription OnUpdateAdmin {
    onUpdateAdmin {
      id
      name
      avatar
      email
      phone
      pushToken
      pushTokens
      organisationId
      createdAt
      updatedAt
      organisation {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
`;
export const onDeleteAdmin = /* GraphQL */ `
  subscription OnDeleteAdmin {
    onDeleteAdmin {
      id
      name
      avatar
      email
      phone
      pushToken
      pushTokens
      organisationId
      createdAt
      updatedAt
      organisation {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
`;
export const onCreateOrganisation = /* GraphQL */ `
  subscription OnCreateOrganisation {
    onCreateOrganisation {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateOrganisation = /* GraphQL */ `
  subscription OnUpdateOrganisation {
    onUpdateOrganisation {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteOrganisation = /* GraphQL */ `
  subscription OnDeleteOrganisation {
    onDeleteOrganisation {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onCreateRecord = /* GraphQL */ `
  subscription OnCreateRecord {
    onCreateRecord {
      id
      isFalseAlarm
      position
      status
      type
      updatedAt
      video
      createdAt
      nurse {
        id
        name
        phone
      }
      patient {
        gender
        id
        name
        likelihood
      }
      doctor {
        id
        name
        phone
      }
      handoverNurseId
      handoverNurse {
        id
        name
      }
      recordPatientId
      recordDoctorId
    }
  }
`;
export const onUpdateRecord = /* GraphQL */ `
  subscription OnUpdateRecord {
    onUpdateRecord {
      id
      recordDoctorId
      recordNurseId
      recordPatientId
      position
      alertStatus {
        id
        name
        confirm
      }
      video
      status
      isFalseAlarm
      type
      handoverNurseId
      createdAt
      updatedAt
      patient {
        id
        name
        avatar
        gender
        building
        room
        yearOfBirth
        activityLevel
        patientDoctorId
        patientNurseId
        dangerDegree
        organisationId
        status
        photo
        likelihood
        consequence
        riskCoefficient
        createdAt
        updatedAt
      }
      doctor {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
      }
      nurse {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
      }
      handoverNurse {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
      }
    }
  }
`;
export const onDeleteRecord = /* GraphQL */ `
  subscription OnDeleteRecord {
    onDeleteRecord {
      id
      recordDoctorId
      recordNurseId
      recordPatientId
      position
      alertStatus {
        id
        name
        confirm
      }
      video
      status
      isFalseAlarm
      type
      handoverNurseId
      createdAt
      updatedAt
      patient {
        id
        name
        avatar
        gender
        building
        room
        yearOfBirth
        activityLevel
        patientDoctorId
        patientNurseId
        dangerDegree
        organisationId
        status
        photo
        likelihood
        consequence
        riskCoefficient
        createdAt
        updatedAt
        doctor {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        nurse {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        family {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      doctor {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      nurse {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        
        device {
          nextToken
        }
      }
      handoverNurse {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        
        device {
          nextToken
        }
      }
    }
  }
`;
export const onCreateRiskRecord = /* GraphQL */ `
  subscription OnCreateRiskRecord {
    onCreateRiskRecord {
      patientId
      likelihoood
      consequence
      riskCoefficient
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateRiskRecord = /* GraphQL */ `
  subscription OnUpdateRiskRecord {
    onUpdateRiskRecord {
      patientId
      likelihoood
      consequence
      riskCoefficient
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteRiskRecord = /* GraphQL */ `
  subscription OnDeleteRiskRecord {
    onDeleteRiskRecord {
      patientId
      likelihoood
      consequence
      riskCoefficient
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDevice = /* GraphQL */ `
  subscription OnCreateDevice {
    onCreateDevice {
      id
      deviceId
      deviceDoctorId
      devicePatientId
      deviceNurseId
      organisationId
      status
      position
      input
      output
      deviceSettings {
        showPatientOutline
        showPatientNameAndRoom
      }
      createdAt
      updatedAt
      patient {
        id
        name
        avatar
        gender
        building
        room
        yearOfBirth
        activityLevel
        patientDoctorId
        patientNurseId
        dangerDegree
        organisationId
        status
        photo
        likelihood
        consequence
        riskCoefficient
        createdAt
        updatedAt
        doctor {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        nurse {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        family {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      doctor {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      nurse {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        
        device {
          nextToken
        }
      }
      organisation {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUpdateDevice = /* GraphQL */ `
  subscription OnUpdateDevice {
    onUpdateDevice {
      id
      deviceId
      deviceDoctorId
      devicePatientId
      deviceNurseId
      organisationId
      status
      position
      input
      output
      deviceSettings {
        showPatientOutline
        showPatientNameAndRoom
      }
      createdAt
      updatedAt
      patient {
        id
        name
        avatar
        gender
        building
        room
        yearOfBirth
        activityLevel
        patientDoctorId
        patientNurseId
        dangerDegree
        organisationId
        status
        photo
        likelihood
        consequence
        riskCoefficient
        createdAt
        updatedAt
        doctor {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        nurse {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        family {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      doctor {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      nurse {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        
        device {
          nextToken
        }
      }
      organisation {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
`;
export const onDeleteDevice = /* GraphQL */ `
  subscription OnDeleteDevice {
    onDeleteDevice {
      id
      deviceId
      deviceDoctorId
      devicePatientId
      deviceNurseId
      organisationId
      status
      position
      input
      output
      deviceSettings {
        showPatientOutline
        showPatientNameAndRoom
      }
      createdAt
      updatedAt
      patient {
        id
        name
        avatar
        gender
        building
        room
        yearOfBirth
        activityLevel
        patientDoctorId
        patientNurseId
        dangerDegree
        organisationId
        status
        photo
        likelihood
        consequence
        riskCoefficient
        createdAt
        updatedAt
        doctor {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        nurse {
          id
          name
          avatar
          email
          phone
          pushToken
          pushTokens
          organisationId
          createdAt
          updatedAt
        }
        family {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      doctor {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        device {
          nextToken
        }
      }
      nurse {
        id
        name
        avatar
        email
        phone
        pushToken
        pushTokens
        organisationId
        createdAt
        updatedAt
        patient {
          nextToken
        }
        records {
          nextToken
        }
        
        device {
          nextToken
        }
      }
      organisation {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
`;
export const onCreateDeviceSettings = /* GraphQL */ `
  subscription OnCreateDeviceSettings {
    onCreateDeviceSettings {
      id
      showPatientOutline
      showPatientNameAndRoom
      cameraType
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDeviceSettings = /* GraphQL */ `
  subscription OnUpdateDeviceSettings {
    onUpdateDeviceSettings {
      id
      showPatientOutline
      showPatientNameAndRoom
      cameraType
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDeviceSettings = /* GraphQL */ `
  subscription OnDeleteDeviceSettings {
    onDeleteDeviceSettings {
      id
      showPatientOutline
      showPatientNameAndRoom
      cameraType
      createdAt
      updatedAt
    }
  }
`;
export const onCreateRestrictedRegion = /* GraphQL */ `
  subscription OnCreateRestrictedRegion {
    onCreateRestrictedRegion {
      id
      deviceId
      name
      points
      type
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateRestrictedRegion = /* GraphQL */ `
  subscription OnUpdateRestrictedRegion {
    onUpdateRestrictedRegion {
      id
      deviceId
      name
      points
      type
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteRestrictedRegion = /* GraphQL */ `
  subscription OnDeleteRestrictedRegion {
    onDeleteRestrictedRegion {
      id
      deviceId
      name
      points
      type
      createdAt
      updatedAt
    }
  }
`;
export const onCreateImageRequest = /* GraphQL */ `
  subscription OnCreateImageRequest {
    onCreateImageRequest {
      id
      imageUrl
      requestedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateImageRequest = /* GraphQL */ `
  subscription OnUpdateImageRequest {
    onUpdateImageRequest {
      id
      imageUrl
      requestedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteImageRequest = /* GraphQL */ `
  subscription OnDeleteImageRequest {
    onDeleteImageRequest {
      id
      imageUrl
      requestedAt
      createdAt
      updatedAt
    }
  }
`;
