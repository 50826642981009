import React, {
  useState,
  useRef,
  useEffect,
  Dispatch,
  SetStateAction
} from "react"
import { Form, Input, Select, Modal, Button, Slider } from "antd"
import { Patient } from "../models/Patient"
import Uploader from "./ImageUploader"
import { uploadImage } from "../util"
import usePatients from "../hooks/usePatients"
import RiskMatrix from "./RiskMatrix"
const { Item } = Form

interface PatientEditProp {
  mode: "create" | "edit"
  visibility: boolean
  onSave: (props: any) => void
  onCancel: () => void
  patientDetails?: Patient
}

const DEFAULT_SLIDER_COLOR = "#57BB8A"

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 }
}

const PatientEditForm: React.FC<PatientEditProp> = ({
  onCancel,
  onSave,
  visibility,
  mode,
  patientDetails
}) => {
  const formRef = useRef<any>()
  const [imageUrl, setImageUrl] = useState<string>(patientDetails?.avatar || "")
  const [file, setFile] = useState<File>()

  const [consequence, setConsequence] = useState<number>(0)
  const [likelihood, setLikelihood] = useState<number>(0)

  const [likelihoodColor, setLikelihoodColor] = useState<string>(
    DEFAULT_SLIDER_COLOR
  )
  const [consequenceColor, setConsequenceColor] = useState<string>(
    DEFAULT_SLIDER_COLOR
  )

  const { createNewPatient, updateCurrentPatient } = usePatients()
  const onSaveClicked = async (props: {
    name: string
    gender: "Female" | "Male"
    likelihood: number
    consequence: number
  }) => {
    const calcultedProps = {
      ...props,
      riskCoefficient: props.likelihood * props.consequence
    }
    // if mode is create
    // 1. create a patient record
    if (mode === "create") {
      try {
        const id = await createNewPatient(calcultedProps)
        if (!file) {
          return onCancelClicked()
        }
        const path = (await uploadImage("Patient", file, id)) || ""
        await updateCurrentPatient({ id, avatar: path }, onCancelClicked)
        // setImageUrl(getFilePath(path))
        // 2. udpate patient record with given image url
      } catch (error) {
        console.error("patient create error:", error)
      }
    }

    // if mode is edit
    // 1. update name, gender
    // 2. upload image and update image url
    // console.log(getFilePath(imageUrl))
    else {
      try {
        const id = patientDetails?.id || ""
        const details: any = { id, ...calcultedProps }
        if (file) {
          const path = (await uploadImage("Patient", file, id)) || ""
          details.avatar = path
        }
        await updateCurrentPatient(details, onCancelClicked)
        // setImageUrl(getFilePath(path))
      } catch (error) {
        console.error("patient update error:", error)
      }
    }
  }

  const onCancelClicked = () => {
    setFile(undefined)
    setImageUrl("")
    onCancel()
  }

  useEffect(() => {
    formRef.current && formRef.current.resetFields()
    setImageUrl(patientDetails?.avatar || "")
    setConsequenceColor(DEFAULT_SLIDER_COLOR)
    setLikelihoodColor(DEFAULT_SLIDER_COLOR)
    onColorChange(patientDetails?.consequence, setConsequenceColor)
    onColorChange(patientDetails?.likelihood, setLikelihoodColor)
    setLikelihood(patientDetails?.likelihood || 0)
    setConsequence(patientDetails?.consequence || 0)
  }, [patientDetails])

  const onColorChange = (
    value: any,
    func: Dispatch<SetStateAction<string>>
  ) => {
    if (value > 4) {
      return func("#cc0200")
    }
    if (value > 3) {
      return func("#E69138")
    }
    if (value > 2) {
      return func("#F1C233")
    }
    if (value > 1) {
      return func("#ABC978")
    }
    return func("#57BB8A")
  }

  const onSetLikelihoodTooltip = (value: any) => {
    if (value > 4) {
      return "Almost Certain"
    }
    if (value > 3) {
      return "Likely"
    }
    if (value > 2) {
      return "Possible"
    }
    if (value > 1) {
      return "Unlikely"
    }
    return "Rare"
  }

  const onSetConsequenceTooltip = (value: any) => {
    if (value > 4) {
      return "Catastrophic"
    }
    if (value > 3) {
      return "Major"
    }
    if (value > 2) {
      return "Significant"
    }
    if (value > 1) {
      return "Moderate"
    }
    return "Minor"
  }
  return (
    <Modal
      visible={visibility}
      width="800px"
      onCancel={onCancelClicked}
      footer={[
        <Button key="save" htmlType="submit" form="detailForm" type="primary">
          Save
        </Button>,
        <Button key="cancel" onClick={onCancelClicked}>
          Cancel
        </Button>
      ]}
    >
      <Form
        {...layout}
        id="detailForm"
        ref={formRef}
        initialValues={patientDetails ? patientDetails : undefined}
        onFinish={onSaveClicked}
        style={{ padding: 20 }}
      >
        <Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Name is required!" }]}
        >
          <Input />
        </Item>
        <Item
          name="gender"
          label="Gender"
          rules={[{ required: true, message: "Gender is required!" }]}
        >
          <Select>
            <Select.Option key="Male" value="Male">
              Male
            </Select.Option>
            <Select.Option key="Female" value="Female">
              Female
            </Select.Option>
          </Select>
        </Item>
        <Item label="Image">
          <Uploader
            imageUrl={imageUrl}
            onFileChange={setFile}
            onUrlChange={setImageUrl}
          />
        </Item>
        <Item label="Likelihood" name="likelihood">
          <Slider
            trackStyle={{ backgroundColor: likelihoodColor }}
            handleStyle={{ borderColor: likelihoodColor }}
            onChange={(value: number) => {
              onColorChange(value, setLikelihoodColor)
              setLikelihood(value)
            }}
            tipFormatter={onSetLikelihoodTooltip}
            step={0.2}
            max={5}
            min={0}
          />
        </Item>
        <Item label="Consequence" name="consequence">
          <Slider
            trackStyle={{ backgroundColor: consequenceColor }}
            handleStyle={{ borderColor: consequenceColor }}
            onChange={(value: number) => {
              onColorChange(value, setConsequenceColor)
              setConsequence(value)
            }}
            tipFormatter={onSetConsequenceTooltip}
            max={5}
            min={0}
            step={0.2}
          />
        </Item>
        <Item label="Risk Matrix">
          <RiskMatrix x={likelihood} y={consequence} />
        </Item>
      </Form>
    </Modal>
  )
}

export default PatientEditForm
