import { Action } from "../../models/General"

const actions = {
  FETCH_DEVICES: "FETCH_DEVICES",
  GET_SINGLE_DEVICE: "GET_SINGLE_DEVICE",
  CREATE_DEVICE: "CREATE_DEVICE",
  UPDATE_DEVICE: "UPDATE_DEVICE",
  DELETE_DEVICE: "DELETE_DEVICE",
  FETCH_REGIONS: "FETCH_REGIONS",
  FETCH_IMAGES: "FETCH_IMAGES",
  CREATE_REGION: "CREATE_REGION",
  CREATE_IMAGE_REQUEST: "CREATE_IMAGE_REQUEST",
  DELETE_REGION: "DELETE_REGION",
  UPDATE_REGION: "UPDATE_REGION",
  SET_CURRENT_REGION: "SET_CURRENT_REGION",
  SET_CUREENT_IMAGE: "SET_CUREENT_IMAGE"
}

export function fetchDevices(props: any, isExtend?: boolean): Action {
  return { type: actions.FETCH_DEVICES, payload: { props, isExtend } }
}

export function addNewDevice(props: any): Action {
  return { type: actions.CREATE_DEVICE, payload: props }
}

export function modifyDevice(props: any): Action {
  return { type: actions.UPDATE_DEVICE, payload: props }
}

export function removeDevice(id: string): Action {
  return { type: actions.DELETE_DEVICE, payload: id }
}

export function getSingleDevice(props: any): Action {
  return { type: actions.GET_SINGLE_DEVICE, payload: props }
}

export function fetchRegions(props: any): Action {
  return { type: actions.FETCH_REGIONS, payload: props }
}

export function fetchImages(props: any): Action {
  return { type: actions.FETCH_IMAGES, payload: props }
}

export function createNewRegion(props: any): Action {
  return { type: actions.CREATE_REGION, payload: props }
}

export function createNewImage(props: any): Action {
  return { type: actions.CREATE_IMAGE_REQUEST, payload: props }
}

export function removeRegion(id: string): Action {
  return { type: actions.DELETE_REGION, payload: id }
}

export function modifyRegion(props: any): Action {
  return { type: actions.UPDATE_REGION, payload: props }
}

export function setCurrentRegion(props: any): Action {
  return{ type: actions.SET_CURRENT_REGION, payload: props }
}

export function setCurrentImage(props: any): Action {
  return{ type: actions.SET_CUREENT_IMAGE, payload: props }
}

export default actions
