import { Action } from "../../models/General"
import actions from "./actions"

const initState = {
  patients: {
    items: [],
    nextToken: "",
    total: 0
  },
  singlePatient: {}
}

export default function patientReducer(
  state = initState,
  { type, ...action }: Action
) {
  switch (type) {
    case actions.GET_ALL_PATIENTS:
      return {
        ...state,
        patients: action.payload.isExtend
          ? {
              items: [...state.patients.items, action.payload.props.items],
              nextToken: action.payload.props.nextToken,
              total: action.payload.props.total + state.patients.total
            }
          : action.payload.props
      }
    case actions.CREATE_PATIENT:
      return {
        ...state,
        patients: {
          ...state.patients,
          items: [action.payload, ...state.patients.items]
        }
      }
    case actions.UPDATE_PATIENT:
      return {
        ...state,
        patients: {
          ...state.patients,
          items: state.patients.items.map((p: any) =>
            p.id === action.payload.id ? { ...p, ...action.payload } : p
          )
        }
      }
    default:
      return state
  }
}
