// @ts-nocheck
import { Component, Ref } from "react"
import flvjs from "flv.js"

// const FlvPlayer: React.FC = (props: any) => {
//   const { className, style } = props
//   const initFlv = useRef(($video: any) => {
//     if ($video) {
//       if (flvjs.isSupported()) {
//         let flvPlayer = flvjs.createPlayer({ ...props }, props.config)
//         flvPlayer.attachMediaElement($video)
//         flvPlayer.load()
//         flvPlayer.play()
//         return flvPlayer
//       }
//     }
//   })

//   useEffect(() => {
//     return () => {
//       if (initFlv.current) {
//         initFlv.current.unload()
//         initFlv.current.detachMediaElement()
//         initFlv.current.destroy()
//       }
//     }
//   })
//   return (
//     <video
//       className={className}
//       style={Object.assign(
//         {
//           width: "100%"
//         },
//         style
//       )}
//       ref={initFlv}
//     />
//   )
// }

// export default FlvPlayer

type Props = {
  className?: string
  style?: object
  loop?: boolean
  /**
   * media URL, can be starts with 'https(s)' or 'ws(s)' (WebSocket)
   */
  url?: string
  /**
   * media type, 'flv' or 'mp4'
   */
  type: "flv" | "mp4"
  /**
   * whether the data source is a **live stream**
   */
  isLive?: boolean
  /**
   * whether to enable CORS for http fetching
   */
  cors?: boolean
  /**
   * whether to do http fetching with cookies
   */
  withCredentials?: boolean
  /**
   * whether the stream has audio track
   */
  hasAudio?: boolean
  /**
   * whether the stream has video track
   */
  hasVideo?: boolean
  /**
   * total media duration, in milliseconds
   */
  duration?: boolean
  /**
   * total file size of media file, in bytes
   */
  filesize?: number
  /**
   * Optional field for multipart playback, see MediaSegment
   */
  segments?: Array<{
    /**
     * indicates segment duration in milliseconds
     */
    duration: number
    /**
     * indicates segment file size in bytes
     */
    filesize?: number
    /**
     * indicates segment file URL
     */
    url: string
  }>
  /**
   * @see https://github.com/Bilibili/flv.js/blob/master/docs/api.md#config
   */
  config?: object

  ref?: Ref
}

export default class FlvPlayer extends Component<Props> {
  initFlv = ($video: any) => {
    if ($video) {
      if (flvjs.isSupported()) {
        let flvPlayer = flvjs.createPlayer({ ...this.props }, this.props.config)
        flvPlayer.attachMediaElement($video)
        flvPlayer.load()
        flvPlayer.play()
        this.flvPlayer = flvPlayer
      }
      if (this.props.ref) {
        this.props.ref.current = $video
      }
    }
  }

  componentWillUnmount() {
    if (this.flvPlayer) {
      this.flvPlayer.unload()
      this.flvPlayer.detachMediaElement()
      this.flvPlayer.destroy()
    }
  }

  render() {
    const { className, style } = this.props
    return (
      <video
        loop={this.props.loop}
        className={className}
        style={Object.assign(
          {
            width: "100%"
          },
          style
        )}
        muted
        autoPlay
        ref={this.initFlv}
      />
    )
  }
}
