import React from "react"
import { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Button, Card, message, Col, Row, Typography } from "antd"
import moment from "moment"
import useNotification from "../hooks/useNotification"
import { processNotification } from "../redux/Notification/actions"
import HandOverModal from "./HandOverModal"
import { notificationDisplayDateFormat } from "../config"
import { getCardIcon, getCardBgColor, getCardDescription } from "../util"

export default function NotificationAlertCard({ item }: any) {
  const { notifications } = useSelector((state: any) => state.Notification)
  const [recordId, setRecordId] = useState<string>("")
  const [visible, setVisible] = useState<boolean>(false)
  const { setOnMyWay, setIsFalseAlarm, handOver } = useNotification()
  const [isNotificationResolved, setIsNotificationResolved] = useState<boolean>(false)
  const backgroundColor = getCardBgColor(item)
  const notificationIcon = getCardIcon(item)
  const dispatch = useDispatch()
  return (
    <Card
      style={{
        borderRadius: 10,
        marginTop: 0,
        display: "flex",
        flex: 1,
        minWidth: 300,
        backgroundColor: backgroundColor,
      }}
      hoverable
      bodyStyle={{
        padding: 0,
        margin: 0,
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      <Col
        span={2}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 10,
          marginRight: 10
        }}
      >
        <img
          src={notificationIcon}
          style={{ width: 40, height: 40, marginLeft: 10 }}
        />
      </Col>
      <Col
        span={10}
        sm={{span: 20}}
        lg={{span: 20}}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "left",
          alignItems: "flex-start"
        }}
      >
        <Typography style={{ fontWeight: "bold", color: "white" }}>
          {getCardDescription(item)}
        </Typography>
        <Typography style={{ fontWeight: "bold", color: "white" }}>
          {item.position ? item.position : "Room:"}
        </Typography>
        <Typography style={{ fontWeight: "bold", color: "white" }}>
          Patient: {item.patient?.name}
        </Typography>
      </Col>
      <Col
        span={12}
        sm={{span: 24}}
        lg={{span: 24}}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-end",
          marginRight: 10
        }}
      >
        <Row
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            flexWrap: "nowrap",
            flex: 1,
          }}
        >
          <Button
            size="small"
            type="link"
            onClick={async () => {
              await setOnMyWay(item.id)
              dispatch(processNotification(item.id))
              setTimeout(() => {
                setIsNotificationResolved(true)
              }, 5000)
            }}
            style={{
              backgroundColor:
                item.handoverNurseId !== "null" ||
                item.isFalseAlarm ||
                item.status === "Processing"
                  ? "grey"
                  : "#1991eb",
              color: "#fff",
              borderRadius: 5,
              fontWeight: "bold",
              marginRight: 10,
              marginBottom: 2,
              fontSize: 14,
            }}
            disabled={
              item.handoverNurseId !== "null" ||
              item.isFalseAlarm ||
              item.status === "Processing"
            }
          >
            On my way
          </Button>
          <Button
            size="small"
            type="link"
            onClick={() => {
              // pop up choose nurse
              setRecordId(item.id)
              setVisible(true)
              console.log(item)
            }}
            style={{
              backgroundColor:
                item.handoverNurseId !== "null" ||
                item.isFalseAlarm ||
                item.status === "Processing"
                  ? "grey"
                  : "#6b47db",
              color: "#fff",
              borderRadius: 5,
              fontWeight: "bold",
              marginBottom: 2,
              marginRight: 10,
              fontSize: 14,
            }}
            disabled={
              item.handoverNurseId !== "null" ||
              item.isFalseAlarm ||
              item.status === "Processing"
            }
          >
            Hand over
          </Button>
          <Button
            size="small"
            type="link"
            onClick={async () => {
              await setIsFalseAlarm(item.id)
              dispatch(processNotification(item.id))
              setTimeout(() => {
                setIsNotificationResolved(true)
              }, 5000)
            }}
            style={{
              backgroundColor:
                item.handoverNurseId !== "null" ||
                item.isFalseAlarm ||
                item.status === "Processing"
                  ? "grey"
                  : "#7e7e7e",
              color: "#fff",
              borderRadius: 5,
              fontWeight: "bold",
              marginRight: 10,
              fontSize: 14,
              marginBottom: 2,
            }}
            disabled={
              item.handoverNurseId !== "null" ||
              item.isFalseAlarm ||
              item.status === "Processing"
            }
          >
            Report
          </Button>
        </Row>
        <Typography
          style={{ fontWeight: "bold", color: "white", marginRight: 10 }}
        >
          {moment(item.createdAt).format(notificationDisplayDateFormat)} SENT
        </Typography>
      </Col>
      <HandOverModal
        visible={visible}
        setVisible={setVisible}
        onHandOver={async (nurseId: string) => {
          console.log(nurseId)
          try {
            await handOver(recordId, nurseId)
            setVisible(false)
            dispatch(processNotification(item.id))
            setTimeout(() => {
              setIsNotificationResolved(true)
            }, 5000)
          } catch (error) {
            message.warning(error.message)
          }
        }}
      />
    </Card>
  )
}
