import { Storage } from "aws-amplify"
import { palette } from "../config"
import sosIcon from "../assets/sos.png"
import helpIcon from "../assets/help.png"
import bellIcon from "../assets/bell.png"

export const getFilePath = (path: string) =>
  `https://capturefallbucket113838-dev.s3-ap-southeast-2.amazonaws.com/public/${path}`

export async function uploadImage(prefix: string, file: any, id: string) {
  try {
    // const response = await fetch(pathToImageFile)
    // const blob = await file.blob()
    await Storage.put(prefix + "/" + id + "/selfie.jpeg", file, {
      contentType: "image/jpeg"
    })

    return prefix + "/" + id + "/selfie.jpeg"
  } catch (err) {
    console.log("upload image error:", err)
  }
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function getNumId(string: string) {
  const pattern: any = /\d+/g
  if (!string) return ""
  return string?.match(pattern)?.join("").slice(0, 6)
}

export function getDeviceId(item: any) {
  return item.deviceId
  // if(item?.position?.includes("Room 412")) {
  //   return "412"
  // } else if(item?.position?.includes("Room 413")) {
  //   return "413"
  // }
  // return "413"
}

// Todo: add device id to record in schema so the monitor in notification page knows which to connect
export function getDeviceIdByPosition(item: any) {
  if(item?.position?.includes("412")) {
    return "412-1"
  } else if(item?.position?.includes("413")) {
    return "413-1"
  } else if(item?.position?.includes("416")) {
    return "416-1"
  }
  return "412-1"
}

export function getCardIcon(item: any) {
  const result = item?.type
  if (result === "fall") return sosIcon
  if (result === "getup") return helpIcon
  if (result === "fallpredict") return bellIcon
  if (result === "askforhelp") return bellIcon
  return bellIcon
}

export function getCardBgColor(item: any) {
  const result = item?.type
  if (result === "fall") return palette.red
  if (result === "getup") return palette.orange
  if (result === "fallpredict") return palette.yellow
  if (result === "askforhelp") return palette.yellow
  return palette.green
}

export function getCardDescription(item: any) {
  return item.isFalseAlarm
    ? "Continual learning, notification not required."
    : item.handoverNurseId !== "null"
    ? "Handed over to " + (item?.handoverNurse?.name || "unknown")
    : item.type === "fall"
    ? "Fall Detected"
    : item.status === "Processing"
    ? "Processing"
    : item.type === "getup"
    ? "Patient On The Go"
    : item.type === "askforhelp"
    ? "Ask for help"
    : `Processing`
}

export function getTagColor(item: any) {
  const result = item?.type
  if (result === "fall") return "#F84F5D" // red
  if (result === "getup") return "#F99A00" // orange
  if (result === "fallpredict") return "#F9D337" // yellow
  return "#52C41A" // green
}

export function getTagDescription(item: any) {
  const result = item?.type
  if (result === "fall") return "High"
  if (result === "getup") return "Mid"
  if (result === "fallpredict") return "Abnormal"
  return "Low"
}

export function getBadgeColor(item: any) {
  const result = item?.riskCoefficient || 0
  if (result >= 15) return "#FFA39E"
  if (result >= 8) return "#FFA39E"
  if (result >= 4) return "#FFE58F"
  return "#91D5FF"
}

export function getBadgeBgColor(item: any) {
  const result = item?.riskCoefficient || 0
  if (result >= 15) return "#FFF1F0"
  if (result >= 8) return "#FFF1F0"
  if (result >= 4) return "#FFFBE6"
  return "#E6F7FF"
}

export function getBadgeTextColor(item: any) {
  const result = item?.riskCoefficient || 0
  if (result >= 15) return "#FF4D4F"
  if (result >= 8) return "#FF4D4F"
  if (result >= 4) return "#FAAD14"
  return "#1890FF"
}

export function getBadgeDescription(item: any) {
  const result = item?.riskCoefficient || 0
  if (result >= 15) return "Very High"
  if (result >= 8) return "High"
  if (result >= 4) return "Mid"
  return "Low"
}
