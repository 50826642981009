import React, { useState, useEffect, useRef } from "react"
import { Form, Modal, Button, Input, Select, Typography, Checkbox } from "antd"
import useStaff from "../hooks/useStaff"
import moment from "moment"
import Uploader from "./ImageUploader"
import { capitalizeFirstLetter, uploadImage } from "../util"
import useFamilyMembers from "../hooks/useFamilyMembers"
import usePatients from "../hooks/usePatients"
import { Auth } from "aws-amplify"

const { Item } = Form
const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 }
}

interface StaffFormProp {
  mode: "create" | "edit"
  type: "doctors" | "nurses" | "family"
  visible: boolean
  onSave: (props: any) => void
  onCancel: () => void
}

const StaffDetailForm: React.FC<StaffFormProp> = ({
  mode,
  type,
  visible,
  onCancel,
  onSave
}) => {
  const formRef = useRef<any>()
  const { currentStaff, createNewStaff, updateCurrentStaff } = useStaff()
  const { currentMember, createNewFamilyMember, updateCurrentFamilyMember} = useFamilyMembers()
  const { patients } = usePatients()
  const [imageUrl, setImageUrl] = useState<string>(
    currentStaff.data.avatar || ""
  )
  const [file, setFile] = useState<File>()
  const [createUserType, setCreateUserType] = useState<string>()
  const [enableUserLogin, setEnableUserLogin] = useState<boolean>(false)

  useEffect(() => {
    setCreateUserType("")
  }, [mode])

  useEffect(() => {
    formRef.current && formRef.current.resetFields()
    if(type === "family") {
      if (currentMember.data.avatar) {
        setImageUrl(currentMember.data.avatar)
      }
    } else {
      if (currentStaff.data.avatar) {
        setImageUrl(currentStaff.data.avatar)
      }
    }
  }, [mode, type, currentStaff, currentMember])

  function onCancelClicked() {
    onCancel()
    setImageUrl("")
    setFile(undefined)
    setEnableUserLogin(false)
    setCreateUserType("")
  }

  async function onSaveClicked(props: any) {
    if (enableUserLogin) {
      try {
        const { user } = await Auth.signUp({
            username: props.email,
            password: props.password,
            attributes: {
              // other custom attributes 
              name: props.name,
              email: props.email,
              "custom:userType": props.type
            }
        })
        console.log(user)
        onCancelClicked()
      } catch (error) {
        console.log('error signing up:', error)
      }
    } else {
      if (mode === "create" && createUserType !== "Family") {
        //To do: create new user in cognito when addding a new staff
        const id = await createNewStaff({
          detail: props,
          callback: () => {
            console.log("Staff created, uploading avatar...")
            !file && onCancelClicked()
          }
        })
        console.log("New staff id:", id)
        if (file) {
          const path = (await uploadImage(props.type, file, id)) || ""
          await updateCurrentStaff({
            id,
            staffType: props.type,
            details: { avatar: path },
            callback: onCancelClicked
          })
        }
      } else if (mode === "create" && createUserType === "Family") {
        const result = await createNewFamilyMember(
          props,
          () => {
            console.log("User created, uploading avatar...")
            !file && onCancelClicked()
          }
        )
        const id = result?.id
        if (file && id) {
          const path = (await uploadImage("family", file, id)) || ""
          await updateCurrentFamilyMember(id, { avatar: path }, () => {
            onCancelClicked()
          })
        }
      } else if (mode ==="edit" && type !== "family"){
        const { type, data } = currentStaff
        const staffType = capitalizeFirstLetter(type)
        const newDetails: any = {
          staffType,
          details: props,
          id: data.id,
          callback: () => {
            onCancelClicked()
          }
        }
        if (file) {
          const path = (await uploadImage(staffType, file, data.id)) || ""
          newDetails.details.avatar = path
        }
        await updateCurrentStaff(newDetails)
      } else if (mode === "edit" && type === "family"){
        console.log("update current family member")
        const id = currentMember.data.id
        if (file) {
          const path = (await uploadImage("family", file, id)) || ""
          props.avatar = path
        }
        await updateCurrentFamilyMember(id, props, () => {
          onCancelClicked()
        })
      }
    }
  }

  return (
    <Modal
      visible={visible}
      onCancel={onCancelClicked}
      width={"70%"}
      footer={[
        <Button key="save" htmlType="submit" form="detailForm" type="primary">
          Save
        </Button>,
        <Button key="cancel" onClick={onCancelClicked}>
          Cancel
        </Button>
      ]}
    >
      <Form
        {...layout}
        id="detailForm"
        ref={formRef}
        initialValues={mode === "edit" ? type === "family" ? currentMember.data : currentStaff.data : null}
        onFinish={onSaveClicked}
        style={{ padding: 20 }}
      >
        {mode === "create" && (
          <Item
            name="type"
            label="Type"
            rules={[{ required: true, message: "User type is required!" }]}
          >
            <Select onChange={(value: string) => setCreateUserType(value)}>
              <Select.Option key="Doctor" value="Doctor">
                Doctor
              </Select.Option>
              <Select.Option key="Nurse" value="Nurse">
                Nurse
              </Select.Option>
              <Select.Option key="Family" value="Family">
                Family Member
              </Select.Option>
            </Select>
          </Item>
        )}
        <Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Name is required!" }]}
        >
          <Input />
        </Item>
        <Item
          name="email"
          label="Email"
          rules={[{ required: true, message: "Email is required!" }]}
        >
          <Input disabled={mode === "edit"} />
        </Item>
        <Item
          name="phone"
          label="Phone"
          // rules={[{ required: true, message: "Name is required!" }]}
        >
          <Input />
        </Item>
        {
          ((mode === "edit" && type === "family") || (mode === "create" && createUserType === "Family")) && (
            <>
              <Item
                name="familyMemberPatientId"
                label="Patient"
                rules={[{ required: true, message: "The patient ID is required!" }]}
              >
                <Select>
                  {
                    patients.items.map((patient: any) => {
                      return (
                        <Select.Option key={patient.id} value={patient.id}>
                          {patient.name}
                        </Select.Option>
                      )
                    })
                  }
                </Select>
              </Item>
              <Item
                name="relationship"
                label="Relationship"
                rules={[{ required: true, message: "Relationship to the patient is required!" }]}
              >
                <Input />
              </Item>
            </>
          )
        }
        <Item label="Avatar">
          <Uploader
            imageUrl={imageUrl}
            onFileChange={setFile}
            onUrlChange={setImageUrl}
          />
        </Item>
        {
          mode === "create" && 
          <Checkbox onChange={(e: any) => setEnableUserLogin(e.target.checked)}>Enable user to login dashboard</Checkbox>
        }
        {
          enableUserLogin && 
          <Item
            name="password"
            label="Login Password"
            rules={[{ required: true, message: "Login Password is required!" }]}
          >
            <Input type="password" />
          </Item>
        }
      </Form>
    </Modal>
  )
}

export default StaffDetailForm
