import { Tag } from "antd"
import { getTagColor, getTagDescription } from "../util"

type props = {
  item: Partial<{ type: string }>
}

const AlertTypeTag = ({ item }: props) => {
  return <Tag color={getTagColor(item)}>{getTagDescription(item)}</Tag>
}

export default AlertTypeTag
