import React, { useState } from "react"
import { Upload, message } from "antd"
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons"
import { getFilePath } from "../util"

function getBase64(img: any, callback: (props: any) => void) {
  const reader = new FileReader()
  reader.addEventListener("load", () => callback(reader.result))
  reader.readAsDataURL(img)
}

function beforeUpload(file: any) {
  const isJpgOrPng =
    file.type === "image/jpeg" /* || file.type === "image/png" */
  if (!isJpgOrPng) {
    message.error("You can only upload JPG file!")
  }
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!")
  }
  return isJpgOrPng && isLt2M
}
interface UploaderProps {
  imageUrl?: string
  onFileChange: (file: File) => void
  onUrlChange: (url: string) => void
}
const Uploader: React.FC<UploaderProps> = ({
  imageUrl,
  onFileChange,
  onUrlChange
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const handleChange = (info: any) => {
    if (info.file.status === "uploading") {
      return setLoading(true)
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) => {
        setLoading(false)
        onUrlChange(imageUrl)
      })
    }
  }

  const uploadButton: React.ReactElement = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )

  const customRequest = async (props: any) => {
    try {
      const { file, onSuccess } = props
      console.log(file)
      onFileChange(file)
      onSuccess()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Upload
      name="avatar"
      listType="picture-card"
      style={{ width: "128px", height: "128px" }}
      showUploadList={false}
      customRequest={customRequest}
      beforeUpload={beforeUpload}
      onChange={handleChange}
    >
      {imageUrl ? (
        <img
          src={imageUrl.includes("data:image/jp") ? imageUrl : getFilePath(imageUrl)}
          alt="avatar"
          style={{ width: "100%", objectFit: "contain", overflow: "hidden" }}
        />
      ) : (
        uploadButton
      )}
    </Upload>
  )
}

export default Uploader
