import FallReducer from "./Falls/reducer"
import UserReducer from "./User/reducer"
import Patients from "./Patients/reducer"
import Staff from "./Staff/reducer"
import Device from "./Device/reducer"
import Notification from "./Notification/reducer"
import FamilyMembers from "./FamilyMembers/reducer"

const reducers = {
  Falls: FallReducer,
  User: UserReducer,
  Patients,
  Staff,
  Device,
  Notification,
  FamilyMembers
}
export default reducers
