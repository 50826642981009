import { useSelector, useDispatch } from "react-redux"
import {
  getAllPatients,
  addPatient,
  modifyPatient
} from "../redux/Patients/actions"
import { API, graphqlOperation, Auth } from "aws-amplify"
import { listPatients } from "../graphql/queries.js"
import { createPatient, updatePatient } from "../graphql/mutations.js"
import useCurrentUser from "./useCurrentUser"
import { User } from "../models/User"
import { isAdmin, isDoctor, isNurse } from "../util/authHelper"

const usePatients = () => {
  const { patients, singlePatient } = useSelector(
    (state: any) => state.Patients
  )
  const { currentUser } = useCurrentUser()
  const dispatch = useDispatch()

  async function fetchPatients({
    limit = 10,
    nextToken,
    isExtend = false,
    callback
  }: {
    limit?: number
    nextToken?: string
    isExtend?: boolean
    callback?: () => void
  }) {
    const getFilterByUserType = (user: User) => {
      if (!user?.type) {
        return undefined
      }
      if (isAdmin(user)) {
        return undefined
      }
      if (isDoctor(user)) {
        return { patientDoctorId: { eq: user.username } }
      }
      if (isNurse(user)) {
        return { patientNurseId: { eq: user.username } }
      }
    }

    try {
      const response: any = await API.graphql(
        graphqlOperation(listPatients, {
          filter: getFilterByUserType(currentUser),
          limit,
          nextToken: isExtend && nextToken ? nextToken : null
        })
      )
      dispatch(getAllPatients(response.data.listPatients, isExtend))
      return response.data.listPatients
    } catch (error) {
      console.error(error)
    } finally {
      callback && callback()
    }
  }

  async function createNewPatient({
    name,
    gender
  }: {
    name: string
    gender: "Female" | "Male"
  }) {
    try {
      const response: any = await API.graphql(
        graphqlOperation(createPatient, {
          input: {
            name,
            gender,
            patientDoctorId: currentUser.username,
            organisationId: "null",
            patientNurseId: "null"
          }
        })
      )
      console.log(response)
      dispatch(addPatient(response.data.createPatient))
      const patiendId = response.data.createPatient.id
      return patiendId
    } catch (error) {
      console.error(error)
    }
  }

  async function updateCurrentPatient(props: any, callback?: () => void) {
    try {
      const response: any = await API.graphql(
        graphqlOperation(updatePatient, {
          input: props
        })
      )
      dispatch(modifyPatient(response.data.updatePatient))
    } catch (error) {
      console.log(error)
    } finally {
      callback && callback()
    }
  }

  return {
    patients,
    singlePatient,
    fetchPatients,
    createNewPatient,
    updateCurrentPatient
  }
}

export default usePatients
