import React, { useState, useEffect } from "react"
import {
  Button,
  PageHeader,
  Tabs,
  List,
  Avatar,
  Typography,
  Popconfirm
} from "antd"
import useStaff from "../hooks/useStaff"
import { PlusCircleFilled, ReloadOutlined } from "@ant-design/icons"
import { getFilePath } from "../util"
import StaffDetailForm from "../components/StaffDetailForm"
import useFamilyMembers from "../hooks/useFamilyMembers"

const { TabPane } = Tabs

const StaffManagementContainer: React.FC = () => {
  const {
    fetchStaffList,
    fetchSingleStaff,
    resetSingleStaff,
    deleteStaff,
    ...data
  } = useStaff()
  const { fetchFamilyMemberList, fetchSingleFamilyMember, familyMembers, currentMember  } = useFamilyMembers()
  const [currentTab, setCurrentTab] = useState<"nurses" | "doctors" | "family">("doctors")
  const [loading, setLoading] = useState<boolean>(false)
  const [mode, setMode] = useState<"create" | "edit">("create")
  const [modalVisibility, setModalVisibility] = useState<boolean>(false)

  const onLoadMore = async (isExtend = false) => {
    setLoading(true)
    if(currentTab === "family") {
      fetchFamilyMemberList({
        limit: 100,
        callback: () => {
          setLoading(false)
        }
      })
    } else {
      fetchStaffList({
        type: currentTab,
        isExtend,
        callback: () => {
          setLoading(false)
        }
      })
    }
  }

  useEffect(() => {
    onLoadMore(false)
    //eslint-disable-next-line
  }, [currentTab])

  const loadMore = !loading ? (
    <div
      style={{
        textAlign: "center",
        marginTop: 12,
        height: 32,
        lineHeight: "32px"
      }}
    >
      <Button
        onClick={() => onLoadMore(true)}
        disabled={currentTab === "family" ? !familyMembers.nextToken : !data[currentTab].nextToken}
      >
        Load More
      </Button>
    </div>
  ) : null

  const StaffList = (
    <List
      loading={loading}
      itemLayout="horizontal"
      loadMore={loadMore}
      dataSource={currentTab === "family" ? familyMembers.items : data[currentTab].items}
      renderItem={(item: any) => {
        return (
          <List.Item
            actions={[
              <Button
                key="edit"
                type="primary"
                onClick={() => {
                  if(currentTab !== "family") {
                    fetchSingleStaff({
                      // @ts-ignore
                      staffType: currentTab.substring(0, currentTab.length - 1),
                      id: item.id
                    })
                  } else {
                    fetchSingleFamilyMember(item.id)
                  }
                  setMode("edit")
                  setModalVisibility(true)
                }}
              >
                Edit
              </Button>,
              (currentTab === "nurses" && 
              <Popconfirm
                key="delete"
                onConfirm={() => {
                  deleteStaff({ staffType: "nurse", id: item.id })
                }}
                title="Are you sure to delete this nurse?"
              >
                <Button danger={currentTab === "nurses"}>Delete</Button>
              </Popconfirm>)
            ]}
          >
            <List.Item.Meta
              avatar={
                <Avatar src={getFilePath(item.avatar)} key={item.updatedAt} />
              }
              title={item.name}
              description={
                <Typography.Text
                  copyable
                >{`Email: ${item.email}`}</Typography.Text>
              }
            />
          </List.Item>
        )
      }}
    />
  )

  return (
    <div style={{ backgroundColor: "white", borderRadius: "10px" }}>
      <PageHeader
        ghost={false}
        //onBack={() => window.history.back()}
        style={{ borderRadius: "10px" }}
        title="Users"
        extra={[
          <Button
            key="add"
            onClick={() => {
              setModalVisibility(true)
              setMode("create")
              resetSingleStaff()
            }}
            shape="round"
          >
            <PlusCircleFilled />
            Add New Staff
          </Button>,
          <Button
            style={{ float: "right" }}
            key="refresh"
            type="primary"
            onClick={() => {
              onLoadMore(false)
            }}
            shape="round"
          >
            <ReloadOutlined />
            Refresh
          </Button>
        ]}
      />
      <StaffDetailForm
        mode={mode}
        type={currentTab}
        onCancel={() => {
          setModalVisibility(false)
          resetSingleStaff()
          setMode("create")
        }}
        onSave={() => {}}
        visible={modalVisibility}
      />
      <Tabs
        type="card"
        onChange={(key) => {
          //@ts-ignore
          setCurrentTab(key)
        }}
        style={{ marginLeft: 10, marginRight: 10 }}
      >
        <TabPane tab="Doctors" key="doctors">
          {StaffList}
        </TabPane>
        <TabPane tab="Nurses" key="nurses">
          {StaffList}
        </TabPane>
        <TabPane tab="Family Members" key="family">
          {StaffList}
        </TabPane>
      </Tabs>
    </div>
  )
}

export default StaffManagementContainer
