import React from "react"
import { Menu } from "antd"
import Logo from "../assets/CaptureLive2.png"
import cctvIcon from "../assets/cctv.svg"
import notificationIcon from "../assets/notification.svg"
import recordsIcon from "../assets/records.svg"
import patientIcon from "../assets/patient.svg"
import { NavLink, useRouteMatch } from "react-router-dom"
import {
  UserOutlined,
  DashboardOutlined,
  MedicineBoxOutlined,
  NotificationOutlined,
  VideoCameraOutlined,
  SettingOutlined
} from "@ant-design/icons"
import useCurrentUser from "../hooks/useCurrentUser"

const NavSidebar = () => {
  let match = useRouteMatch()
  const { currentUser } = useCurrentUser()

  // Automatically highlight the chosen page
  const pageMatch: any = useRouteMatch("/dashboard/:page")
  let page = null
  if (pageMatch !== null) {
    page = pageMatch.params.page
  }

  return (
    <>
      <div className="logo" style={{ margin: "30px", textAlign: "center" }}>
        <img
          src={Logo}
          alt="logo"
          width="100%"
          style={{ maxWidth: "50px", borderRadius: "10%" }}
        />
      </div>
      <Menu
        theme="dark"
        defaultSelectedKeys={["customers"]}
        selectedKeys={[page]}
        mode="inline"
        style={{ background: "transparent" }}
      >
        <Menu.Item
          key="cctv"
          style={styles.menuItem}
          icon={
            <img
              src={cctvIcon}
              width="20"
              height="18"
              style={{ marginRight: 20 }}
            />
          }
        >
          <span style={{ fontSize: 18, fontWeight: "bold" }}>CCTV</span>
          <NavLink
            to={`${match.url}/cctv`}
            style={styles.navlink}
            activeStyle={styles.navlinkActiveStyle}
          />
        </Menu.Item>
        <Menu.Item
          key="notifications"
          style={styles.menuItem}
          icon={
            <img
              src={notificationIcon}
              width="20"
              height="18"
              style={{ marginRight: 20 }}
            />
          }
        >
          <span style={{ fontSize: 18, fontWeight: "bold" }}>
            Notifications
          </span>
          <NavLink
            to={`${match.url}/notifications`}
            style={styles.navlink}
            activeStyle={styles.navlinkActiveStyle}
          />
        </Menu.Item>
        <Menu.Item
          key="records"
          style={styles.menuItem}
          icon={
            <img
              src={recordsIcon}
              width="20"
              height="18"
              style={{ marginRight: 20 }}
            />
          }
        >
          <span style={{ fontSize: 18, fontWeight: "bold" }}>Records</span>
          <NavLink
            to={`${match.url}/records`}
            style={styles.navlink}
            activeStyle={styles.navlinkActiveStyle}
          />
        </Menu.Item>
        <Menu.Item
          key="patients"
          style={styles.menuItem}
          icon={
            <img
              src={patientIcon}
              width="20"
              height="18"
              style={{ marginRight: 20 }}
            />
          }
        >
          <span style={{ fontSize: 18, fontWeight: "bold" }}>Patients</span>
          <NavLink
            to={`${match.url}/patients`}
            style={styles.navlink}
            activeStyle={styles.navlinkActiveStyle}
          />
        </Menu.Item>
        {currentUser.type === "Admin" && (
          <Menu.Item key="users" style={styles.menuItem}>
            <UserOutlined
              width={18}
              style={{ marginRight: 20, fontSize: 18 }}
            />
            <span style={{ fontSize: 18, fontWeight: "bold" }}>Users</span>
            <NavLink
              to={`${match.url}/users`}
              style={styles.navlink}
              activeStyle={styles.navlinkActiveStyle}
            />
          </Menu.Item>
        )}
        {currentUser.type === "Admin" && (
          <Menu.Item key="device" style={styles.menuItem}>
            <VideoCameraOutlined
              width={18}
              style={{ marginRight: 20, fontSize: 18 }}
            />
            <span style={{ fontSize: 18, fontWeight: "bold" }}>Devices</span>
            <NavLink
              to={`${match.url}/device`}
              style={styles.navlink}
              activeStyle={styles.navlinkActiveStyle}
            />
          </Menu.Item>
        )}
        {(currentUser.type === "Admin" || currentUser.type === "Family") && (
          <Menu.Item key="settings" style={styles.menuItem}>
            <SettingOutlined
              width={18}
              style={{ marginRight: 20, fontSize: 18 }}
            />
            <span style={{ fontSize: 18, fontWeight: "bold" }}>Settings</span>
            <NavLink
              to={`${match.url}/settings`}
              style={styles.navlink}
              activeStyle={styles.navlinkActiveStyle}
            />
          </Menu.Item>
        )}
      </Menu>
    </>
  )
}

const styles = {
  navlinkActiveStyle: {
    fontFamily: "Lato-Regular",
    fontSize: "17px",
    color: "#FFFFFF"
  },
  navlink: {
    fontFamily: "Lato-Regular",
    fontSize: "17px",
    color: "#FFFFFF"
  },
  menuItem: {
    flex: 1,
    alignItems: "center",
    marginTop: "35px",
    marginBottom: "25px"
  }
}

export default NavSidebar
