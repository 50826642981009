import { Action } from "../../models/General"
import actions from "./actions"

const initState = {
  devices: {
    items: [],
    nextToken: null
  },
  singleDevice: {},
  regions: {
    items: [],
  },
  currentRegion: {},
  images: {
    items: [],
  },
  currentImage: {}
}

export default function DeviceReducer(
  state = initState,
  { type, ...action }: Action
) {
  switch (type) {
    case actions.FETCH_DEVICES:
      return {
        ...state,
        devices: action.payload.isExtend
          ? {
              ...state.devices,
              items: [...state.devices.items, ...action.payload.props.items],
              nextToken: action.payload.props.nextToken
            }
          : action.payload.props
      }
    case actions.GET_SINGLE_DEVICE:
      return {
        ...state,
        singleDevice: action.payload
      }
    case actions.UPDATE_DEVICE:
      return {
        ...state,
        devices: {
          ...state.devices,
          items: state.devices.items.map((device: any) =>
            device.id === action.payload.id
              ? { ...device, ...action.payload }
              : device
          )
        }
      }
    case actions.CREATE_DEVICE:
      return {
        ...state,
        devices: {
          ...state.devices,
          items: [action.payload, ...state.devices.items]
        }
      }
    case actions.DELETE_DEVICE:
      return {
        ...state,
        devices: {
          ...state.devices,
          items: state.devices.items.filter((d: any) => d.id !== action.payload)
        }
      }
    case actions.FETCH_REGIONS:
      return {
        ...state,
        regions: {
          ...state.regions,
          items: action.payload.items
        }
      }
    case actions.FETCH_IMAGES:
      return {
        ...state,
        images: {
          ...state.images,
          items: action.payload.items
        }
      }
    case actions.CREATE_REGION:
      return {
        ...state,
        regions: {
          ...state.regions,
          regions: [action.payload, ...state.regions.items]
        }
      }
    case actions.CREATE_IMAGE_REQUEST:
      return {
        ...state,
        images: {
          ...state.images,
          images: [action.payload, ...state.images.items]
        }
      }
    case actions.DELETE_REGION:
      return {
        ...state,
        regions: {
          ...state.regions,
          regions: state.regions.items.filter((r: any) => r.id !== action.payload)
        }
      }
    case actions.UPDATE_REGION:
      return {
        ...state,
        regions: {
          ...state.regions,
          regions: state.regions.items.map((region: any) =>
            region.id === action.payload.id
              ? { ...region, ...action.payload }
              : region
          )
        }
      }
    case actions.SET_CURRENT_REGION:
      return {
        ...state,
        currentRegion: action.payload
      }
    case actions.SET_CUREENT_IMAGE:
      return {
        ...state,
        currentImage: action.payload
      }
    default:
      return state
  }
}
